import { Box, Divider, Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { match } from 'react-router-dom';
import { modelQueryUrl } from '../../api/http';
import { ExpectedOutbound } from '../../api/models';
import browserHistory from '../../browserHistory';
import ExpectedOutboundStateInline from '../../desktop/controls/ExpectedOutboundStateInline';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import { ExpectedOutboundIncompleteStates } from '../../services/expectedOutboundService';
import RemoteItems from '../../shared/RemoteItems';

const OutboundQueueDetailPage: React.FC<{
	match: match<{ queueId: string }>
}> = ({ match }) => {
	const tableCellStyle = {
		padding: '1px',
		fontSize: '11px',
		borderBottom: '1px solid rgba(224, 224, 224, 0.27)'
	};

	return <List component="nav">
		<RemoteItems
			endpoint={modelQueryUrl('/expected-outbounds', {
				state: {
					in: ExpectedOutboundIncompleteStates
				},
				type: {
					id: { equals: match.params.queueId }
				}
			})}
			divider={<Divider />}
			each={(expectedOutbound: ExpectedOutbound) => (
				<ListItem
					button
					key={expectedOutbound.id}
					onClick={() => browserHistory.push(`/expected-outbounds/${expectedOutbound.id}`)}>
					<ListItemText>
						<Grid container>
							<Grid item xs={6}>
								<div>
									<small>{expectedOutbound.documentNumber}</small>
								</div>
								<div>
									<b>{expectedOutbound.customer.name}</b>
								</div>
							</Grid>
							<Grid item xs={6}>
								<Box display="flex" justifyContent="flex-end">
									<ExpectedOutboundStateInline state={expectedOutbound.state} />
								</Box>
							</Grid>
						</Grid>
						<Table>
							<TableBody>
								{expectedOutbound.items
									.map(item => (
										<TableRow>
											<TableCell style={{
												...tableCellStyle
											}}>{item.remainingQuantity}x</TableCell>
											<TableCell style={{
												...tableCellStyle
											}}>{item.sku.masterCode}</TableCell>
											<TableCell style={{
												...tableCellStyle
											}}>{item.palette.number}</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</ListItemText>
					{/* <Grid container>
						<Grid item xs={6}>
							<ListItemText primary={expectedOutbound.documentNumber}></ListItemText>
							{expectedOutbound.items
								.map(item => <Typography variant="subtitle2" color="textSecondary" key={item.id}>{item.remainingQuantity}x {item.sku.name}/{item.palette?.number}</Typography>)}
						</Grid>
						<Grid item xs={6}>
							{translate(expectedOutbound.state)}
						</Grid>
					</Grid> */}
				</ListItem>
			)}
		/>
	</List>;
}

export default withRouteTitle(OutboundQueueDetailPage, 'Očekávané výdeje');
