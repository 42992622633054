export const UISocketMessage = 'UISocketMessage';

export enum ControlType {
	Stack = 'Stack',
	Row = 'Row',
	Column = 'Column',
	Grid = 'Grid',
	Form = 'Form',
	Textbox = 'Textbox',
	ContentAnchor = 'ContentAnchor',
	Box = 'Box',
	Select = 'Select',
	Checkbox = 'Checkbox',
	Button = 'Button',
	BottomMenu = 'BottomMenu',
	Tabs = 'Tabs',
	Tab = 'Tab',
	MenuBar = 'MenuBar',
	SummaryList = 'SummaryList',
	BorderBox = 'BorderBox',
	MultiStepInput = 'MultiStepInput',
	GenericSwitch = 'GenericSwitch',
	Component = 'Component',
	ButtonGroup = 'ButtonGroup',
	Upload = 'Upload',
	ItemList = 'ItemList',
	CheckList = 'CheckList',
	Foreach = 'Foreach',
	DataGrid = 'DataGrid',
	DateTime = 'DateTime',
	Date = 'Date',
	Time = 'Time',
	Editor = 'Editor',
	Expander = 'Expander',
	Label = 'Label',
	Numeric = 'Numeric',
	Scan = 'Scan',
	YesNoButton = 'YesNoButton',
	List = 'List',
	Detail = 'Detail',
	FullCalendar = 'FullCalendar',
	Page = 'Page',
	Fieldset = 'Fieldset',
	Textarea = 'Textarea',
	Tree = 'Tree',
	Dialog = 'Dialog',
	Table = 'Table',
	TableColumn = 'TableColumn',
	TableRow = 'TableRow',
	Text = 'Text',
	Spacer = 'Spacer',
	SelectGrid = 'SelectGrid',
	Secret = 'Secret',
	Permissions = 'Permissions',
	Spreadsheet = 'Spreadsheet'
}

export enum ControlTheme {
	Native = 'Native',
	Spreadsheet = 'Spreadsheet'
}

export interface RoleTreeItem {
	id: number;
	hasRole: boolean;
	label: string;
	children: RoleTreeItem[];
}

export interface IDetailTab {
	label: string;
}

export enum PageType {
	Default = 'Default',
	SplitScreen = 'SplitScreen',
	MobileScreen = 'MobileScreen'
}

export interface IPageControl {
	kind: ControlType.Page;
	label: string;
	url: string;
	type: PageType;
	modelName?: string;
	canActivateEndpoint?: string;
	canDeactivateEndpoint?: string;
	resolve?: boolean | string | { [key: string]: string };
	children: IControl[];
	pageMenu?: IButtonControl[];
}

export interface UIServerResponse {
	refresh?: string[];
	navigateTo?: string;
	message?: string;
	notification?: IPushNotification;
	openDialog?: IDialogControl;
	contentPatches?: {
		name: string,
		layout: IControl[]
	}[];
	dataState?: any;
	dataPatch?: {
		operations?: any[];
		merge?: any;
	};
}

export enum PushNotificationType {
	Push = 'Push',
	Overlay = 'Overlay'
}

export interface IPushNotification {
	type: PushNotificationType;
	title?: string;
	icon?: string;
	description?: string;
	click?: MenuItem;
	options?: MenuItem[];
	layout?: IDialogControl;
}

export interface IAction {
	label?: string;
	endpoint?: string;
	data?: any;
	openPage?: any;
	openDialog?: any;
}

export enum SpecificAction {
	Close = 'Close',
	Submit = 'Submit'
}

export interface ConfirmQuestion {
	message?: string;
}

export interface MenuItem {
	label?: string;
	icon?: string;
	openDialog?: IDialogControl;
	navigateTo?: string;
	actionUrl?: string;
	deleteUrl?: string;
	actionData?: any;
	contextSpecific?: string;
	badgeEndpoint?: string;
	badge?: string;
	children?: MenuItem[];
	enableWhen?: string;
	confirm?: ConfirmQuestion;
	disableWhen?: string;
	modelPatch?: ModelPatch;
	serverDataPatch?: ServerDataPatch;
	data?: any;
	options?: {
		batch?: boolean | string
	};
}

export interface ServerDataPatch {
	endpoint?: string;
	whenChanges?: string[];
}

export interface ModelPatch {
	endpoint: string;
	patch: any[];
}

export enum ApplicationDevice {
	Desktop = 'Desktop',
	Mobile = 'Mobile',
}

export interface IApplicationLayout {
	// defaultLayout: any;
	applicationTitle?: string;
	palette: MenuItem[];
	menus: MenuItem[];
	personalization: any;
	pages: IPageControl[];
	device: ApplicationDevice;
	theme?: any;
	// lists: any[];
	// details: any[];
}

export interface ModelStructure {
	name: string;
	label: string;
	properties: ModelStructureProperty[];
}

export interface ModelStructureProperty {
	name: string;
	path: string;
	label: string;
	primitiveType: string;
	formControl: IControl;
	defaultFilterCondition?: string;
	children: ModelStructureProperty[];
}

export interface IParametersDefinition {
	list: IParametersDefinitionParameter[];
	layout: IControl;
}

export type ValidatorDefinition = string | { name: string, options?: any, when?: string };

export interface IParametersDefinitionParameter {
	path: string;
	type: string;
	value?: any;
	fromPath?: string;
	validators?: ValidatorDefinition[];
	control?: IControl;
}

export interface ValidationError {
	path?: string;
	value?: any;
	constraints?: { [name: string]: any };
	warnings?: { [name: string]: any };
}

export enum ValueDataType {
	String = 'string',
	Number = 'number',
	Enum = 'enum',
	Unknown = 'unknown'
}

export enum LabelPosition {
	Left = 'Left',
	Top = 'Top',
	Right = 'Right'
}

/**
 * UI Controls
 */
export interface IControl {
	path?: string;
	label?: string;
	kind: ControlType;
	children?: IControl[];
	disabled?: boolean;
	readonly?: boolean;
	mandatory?: boolean;
	inlineMode?: boolean;
	when?: string;
	visibleWhen?: string;
	disableWhen?: string;
	readonlyWhen?: string;
	index?: number;
}

export type TypographyVariant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'subtitle1'
	| 'subtitle2'
	| 'body1'
	| 'body2'
	| 'caption'
	| 'button'
	| 'overline';

export interface IStackControl extends IControl {
	kind: ControlType.Row | ControlType.Column;
	grow?: boolean;
	size?: number;
}

export interface IFormItemControl extends IControl {
	enableWhen?: string;
	disableWhen?: string;
}

export interface ISummaryListControl extends IControl {
	kind: ControlType.SummaryList;
	items: ISummaryListControlItem[][];
}

export interface ISummaryListControlItem extends IControl {
	translate?: boolean;
	control?: IControl;
	menu?: MenuItem[];
}

export interface IGridMultiActionDefinition {
	labelPattern?: string;
	items?: MenuItem[]
}

export interface IBoxControl extends IControl {
	styles: CSSStyleDeclaration;
}

export interface IGridControl extends IControl {
	id?: number;
	label?: string;
	kind: ControlType.Grid;
	modelName: string;
	endpoint: string;
	selectMode?: SelectMode;
	options: {
		showMenu?: boolean;
		showLoadingText?: boolean;
		autoRefreshIntervalSeconds?: number;
	};
	columns: IGridControlColumn[];
	rowClick?: MenuItem;
	buttons?: IButtonControl[];
	multiActions?: IGridMultiActionDefinition;
	filter: {
		extended: IComplexFilterItem[]
		data: any;
	};
}

export interface IGridControlColumn {
	path: string;
	label: string;
	width: number;
	control?: IControl;
	dataType: ValueDataType;
	searchPath?: string;
	formControl?: IControl;
	translate?: boolean;
	valuePattern?: string;
	defaultFilterCondition?: string;
}

export interface IComponentControl extends IControl {
	kind: ControlType.Component;
	name: string;
}

export interface ISpreadsheetControlColumn extends IControl {
	label: string;
	width: number;
	control: IControl;
}

export interface ISpreadsheetControl extends IControl {
	columns: ISpreadsheetControlColumn[];
}

export interface ISelectControl extends IFormItemControl {
	multiple?: boolean;
	items?: IKeyValueRecord[];
	pageCount?: number;
	endpoint?: string;
	searchPaths?: string[];
	placeholder?: string;
	canType?: boolean;
	icon?: string;
	itemLabelPattern?: string;
	valueSetPaths?: string[];
	queryFilter?: any;
	defaultValue?: string;
}

export enum ComplexFilterItemType {
	Text = 'Text',
	Number = 'Number',
	Boolean = 'Boolean',
	Date = 'Date',
	Enum = 'Enum'
}

export enum MultiplicationType {
	Agregated = 'Agregated',
	Desintegrated = 'Desintegrated'
}

export interface IComplexFilterItem {
	label: string;
	fullLabel: string;
	path: string;
	type: ComplexFilterItemType;
	children?: IComplexFilterItem[];
	items?: IKeyValueRecord[];
	itemsEndpoint?: string;
	multiplication?: boolean;
}

export interface IForeachControl extends IControl {
}

export interface IItemListControl extends IControl {
	endpoint?: string;
	layout?: IControl;
	click?: MenuItem;
	items?: any[];
	texts?: {
		noItems?: string;
	};
}

export interface IFullCalendarControl extends IControl {
	calendar: {
		endpoint?: string;
		options?: any;
		paths?: any;
	};
	event: {
		endpoint?: string;
		options?: any;
		paths?: any;
		onClick?: MenuItem;
		onEdit?: MenuItem;
		onSelect?: MenuItem;
	}
}

export interface ICheckListControl extends IControl {
	endpoint?: string;
	layout?: IControl;
	click?: {
		confirm?: {
			question?: string;
			recordLabelPattern?: string;
		} | boolean
	};
	items?: IKeyValueRecord[];
	keyPath?: string;
	groupBy?: {
		path?: string;
		labelPattern?: string;
	};
	texts?: {
		noItems?: string;
	}
	labelPattern?: string;
}

export enum ColorType {
	Default = 'Default',
	Primary = 'Primary',
	Secondary = 'Secondary'
}

export enum StyleType {
	Contained = 'Contained',
	Outlined = 'Outlined',
	Text = 'Text',
	Icon = 'Icon',
}

export enum SizeType {
	Small = 'Small',
	Medium = 'Medium',
	Large = 'Large',
	Full = 'Full'
}

export enum DialogSize {
	Auto = 'Auto',
	FullSize = 'FullSize'
}

export interface IMultiStepInputControl extends IControl {
	kind: ControlType.MultiStepInput;
	inputs: IControl[];
}

export interface IUploadControl extends IControl {
	endpoint: string;
}

export interface IButtonControl extends IControl {
	label?: string;
	icon?: string;
	action?: MenuItem;
	fullsize?: boolean;
	confirm?: ConfirmQuestion;
	styleType?: StyleType;
	sizeType?: SizeType;
	colorType?: ColorType;
	chevron?: boolean;
	enableWhen?: string;
	disableWhen?: string;
}

export interface IButtonGroupControl extends IControl {
	label?: any;
	multiple?: boolean;
	items?: IKeyValueRecord[];
	itemLabelPattern?: string;
	modelName?: string;
	endpoint?: string;
}

export interface IGenerciSwitchControl extends IControl {
	layouts: any[];
}

export interface IDialogControl extends IControl {
	title?: IControl[];
	content: IControl;
	size: DialogSize;
	buttons?: IButtonControl[];
}

export interface IFormControl extends IControl {
	endpoint: string;
	children: IControl[];
	enterConfirm?: boolean;
	resolve?: string | { [key: string]: string };
	defaults?: any;
	overrides?: any;
	guard?: boolean;
	validation?: {
		endpoint: string;
		whenChanges?: string[];
	};
}

export interface IDetailControl extends IControl {
	modelName: string;
	endpoint: string;
	header: {
		title: any,
		description: any
	};
	tabs: ITabControl[];
}

export interface IScanControl extends IControl {
	inputVisible: boolean;
	viewportListen: boolean;
	multiple?: boolean;
	endpoint?: string;
	itemLabelPattern?: string;
}

export interface IPermissionsControl extends IControl {
	label?: string;
	identityPath: string;
	roles: IdentityRoleLike[];
}

export interface IdentityRoleLike {
	name: string;
	default?: boolean;
}

export interface ITreeItem {
	itemType: string;
	key: any;
	label: string;
	record?: any;
	isOpen?: boolean;
	isFolder?: boolean;
	children?: ITreeItem[];
}

export interface ITreeControl extends IControl {
	endpoint: string;
	leafClick: MenuItem;
}

export interface ITextControl extends IControl {
	kind: ControlType.Text;
	text?: string;
	variant?: TypographyVariant;
	translate?: boolean;
	endpoint?: string;
	dateTimeFormat?: string;
}

export interface IBorderBoxControl extends IControl {
	kind: ControlType.BorderBox;
	endpoint?: string;
	icon?: string;
	styleType?: StyleType;
	fullsize?: boolean;
	valueMap?: any;
	colorMap?: any;
}

export interface IFieldsetControl extends IControl {
	kind: ControlType.Fieldset;
}

export interface IBottomMenuControl extends IControl {
	kind: ControlType.BottomMenu;
	buttons: IButtonControl[];
}

export interface ICheckboxControl extends IControl {
	kind: ControlType.Checkbox;
	threestate?: boolean;
}

export interface ITabsControl extends IControl {
	children: ITabControl[];
	bottomNavigation?: boolean;
}

export interface ITabControl extends IControl {
	label: string;
	icon?: string;
}

export interface IMenuBarControl extends IControl {
	items: MenuItem[];
}

export interface ITableControl extends IControl {
	columns: ITableColumnControl[];
}

export interface ITableColumnControl extends IControl {
	label: string;
	width: number;
	control: IControl;
}

export enum DateTimeControlType {
	DateTime = 'DateTime',
	Date = 'Date',
	Time = 'Time'
}

export interface IDateTimeControl extends IControl {
	type: DateTimeControlType;
}

export interface ISecretControl extends IControl {
}

export interface IContentAnchor extends IControl {
	name: string;
}

export interface ITextboxControl extends IControl {
	placeholder?: string;
	prefix?: string;
	postfix?: string;
	mask?: string;
}

export interface INumericControl extends IControl {
	type: NumericType;
}

export interface ITextareaControl extends IControl {

}

export interface IKeyValueRecord<T = any> {
	key: any;
	label: string;
	record?: T;
}

export enum SelectMode {
	None = 'None',
	Single = 'Single',
	Multiple = 'Multiple'
}

export enum SelectGridViewMode {
	Inline = 'Inline',
	Dropdown = 'Dropdown',
	Dialog = 'Dialog'
}

export enum NumericType {
	Input = 'Input',
	Numpad = 'Numpad',
}
