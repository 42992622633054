import React from 'react';
import { Order, Customer } from '../../api/models';
import { translate } from '../../providers/locale';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordList from './ModelRecordList';
import ModelRecordSideDetail from './ModelRecordSideDetail';

type ThisModel = Customer;

const CustomerListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/customers"
		title="Zákazníci"
		columns={[
			{ label: 'Obchodní název', get: x => x.name, width: 100 }
		]}
		sideDetailComponent={item => <ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					summaryList: [
						{ label: 'Obchodní název', get: x => x.name }
					]
				}, {
					label: 'Události',
					content: item => <ModelActivityTable modelName="Customer" modelId={item.id} />
				}
			]} />}
	/>;
}

export default CustomerListPage;
