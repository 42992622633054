import { Box, Divider, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { match, useLocation } from 'react-router-dom';
import { modelQueryUrl, httpPost, httpPostBlocking, httpGet } from '../../api/http';
import { Deposit, DepositState } from '../../api/models';
import { getLocationQuery } from '../../browserHistory';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import { PrimaryButton } from '../../shared/presets';
import RemoteItems from '../../shared/RemoteItems';
import { useObservable } from 'react-use-observable';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { groupBy } from '../../utils';
import { translate } from '../../providers/locale';
import browserHistory from '../../browserHistory';

const DepositsDeliveryPickupConfirmationPage: React.FC<{
	match: match<{ officeId: string }>
}> = ({ match }) => {
	const location = useLocation();
	const query = getLocationQuery<{ selectedDepositIds: number[] }>(location);

	const [items, setItems] = useObservable(() => {
		return from(httpGet<Deposit[]>(modelQueryUrl('/deposit-terminal-controller/resolve-missing-deposits', {
			officeId: match.params.officeId,
			depositStates: [DepositState.OutboundProgress, DepositState.InboundRequest],
			selectedDepositIds: query.selectedDepositIds
		})))
			.pipe(map(deposits => groupBy(deposits, d => d.state)))
	}, []);

	const confirm = async () => {
		let result = await httpPostBlocking<any>('/deposit-terminal-controller/confirm/delivery-pickup', {
			selectedDepositIds: query.selectedDepositIds,
			depositStates: [DepositState.OutboundProgress, DepositState.InboundRequest]
		});

		result && browserHistory.push(result.navigateTo);
	};

	return (
		<Box px={2}>
			<List component="nav">
				{items?.map(group => (
					<React.Fragment>
						<ListItem key={group.key}>{translate(group.key)}</ListItem>
						{group.items.map(deposit => (
							<ListItem key={deposit.id}>
								<ListItemText primary={deposit.palette.number} secondary={deposit.documentNumber} />
							</ListItem>
						))}
					</React.Fragment>
				))}
				<PrimaryButton onClick={confirm}>Potvrdit</PrimaryButton>
			</List >
		</Box>
	);
}

export default withRouteTitle(DepositsDeliveryPickupConfirmationPage, 'Vykládka uskladněnek');
