import { Table, TableBody, TableCell, TableHead, TableRow, Box, TableContainer } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import { Deposit } from '../api/models';
import { ItemList } from '../helpers/itemList';
import { httpGet } from '../api/http';

export interface IDynamicTableDefinition<T> {
	endpoint: string;
	columns: {
		label: string;
		width: number;
		background?: (item: T) => any;
		get: (item: T) => any;
	}[]
}

export type DynamicTableProps<T> = {
	definition: IDynamicTableDefinition<T>;
}

export function DynamicTable<T>({ definition }: React.PropsWithChildren<DynamicTableProps<T>>) {
	const [items, setItems] = useState<T[]>([]);

	useEffect(() => {
		refreshItems();
	}, [definition]);

	const refreshItems = async () => {
		let result = await httpGet(definition.endpoint);
		setItems(result);
	};

	return (
		<React.Fragment>
			<TableContainer>
			<Table stickyHeader style={{ tableLayout: 'fixed' }}>
				<TableHead>
					<TableRow>
						{definition.columns.map(column => (
							<TableCell key={column.label} style={{ width: `${column.width}px` }}>{column.label}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{items && items.length > 0 && items.map((item: any, index: number) => (
						<TableRow key={`${index}.${item.id}`} hover>
							{definition.columns.map(column => (
								<TableCell key={`${index}.${item.id}.${column.label}`} style={{
									background: column.background && column.background(item)
								}}>{column.get(item)}</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
			</TableContainer>
			{
				items && items.length == 0 && (
					<Box p={2} style={{ textAlign: 'center' }}>Žádné položky k zobrazení</Box>
				)
			}
		</React.Fragment>
	)
}

export default DynamicTable;
