import React, { useState } from 'react';
import { Customer } from '../../api/models';
import { Toolbar, Grid, Typography } from '@material-ui/core';
import { title } from 'process';
import { DataState } from '../../helpers/dataState';
import { url } from 'inspector';
import { modelQueryUrl, httpPost } from '../../api/http';
import { Select } from '../../controls/Select';
import { useObservable } from 'react-use-observable';
import { TextBox } from '../../controls/base';
import { PrimaryButton } from '../../shared/presets';
import browserHistory from '../../browserHistory';

const DepositLabelsPage: React.FC<{}> = ({ }) => {
	const [dataState, setDataState] = useState(new DataState<{ customer: Customer, labelsCount: number }>());
	const [dataStateRecord] = useObservable(() => dataState.recordChange, []);

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		let result = await httpPost(`/wms/generate/deposits/${dataStateRecord.customer?.id}`, dataStateRecord, {
			targetDataState: dataState
		});

		if (result) {
			browserHistory.push('/deposits');
		}
	};

	return (
		<form style={{ maxWidth: '800px', margin: '0 auto' }} onSubmit={e => handleSubmit(e)}>
			<Grid container>
				<Grid item>
					<Typography
						color="inherit"
						variant="subtitle1"
						style={{
							fontSize: '18px',
							fontWeight: 500,
							lineHeight: '58px'
						}}>Tisk štítků pro uskladněnky</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Select<Customer>
						label="Dealer"
						path="customer"
						dataState={dataState}
						endpoint={term => modelQueryUrl(`/customers`, {
							name: { contains: term }
						})}
						getOptionLabel={x => x.name} />
				</Grid>
				<Grid item>
					Poslední štítek: {dataStateRecord?.customer?.depositNumericSequence?.currentValue}
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<TextBox label="Počet nových štítků"
						path="labelsCount"
						dataState={dataState} />
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<PrimaryButton type="submit">Tisknout</PrimaryButton>
				</Grid>
			</Grid>
		</form>
	);
}

export default DepositLabelsPage;
