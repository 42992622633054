import { Box, CircularProgress, Step, StepContent, StepLabel, Stepper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { match } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import { httpGet, httpPost, modelQueryUrl } from '../../api/http';
import { Activity, ExpectedOutbound, ExpectedOutboundItem, Palette, Sku, WarehouseLocation } from '../../api/models';
import { ScanTextBox, TextBox } from '../../controls/base';
import { DataState } from '../../helpers/dataState';
import { setApplicationTitle } from '../../providers/app';
import { translate } from '../../providers/locale';
import { localeFormatDateTime } from '../../providers/modal/locale';
import RemoteItems from '../../shared/RemoteItems';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';
import browserHistory from '../../browserHistory';

interface IData {
	expectedOutbound: ExpectedOutbound;
	location: WarehouseLocation;
	item: ExpectedOutboundItem;
}

interface IDataState {
	location?: WarehouseLocation;
	sku?: Sku;
	palette?: Palette;
	quantity?: number;
}

const ExpectedOutboundProcessItemPage: React.FC<{ match: match<any> }> = ({ match }) => {
	const { result: data, loading } = useAsync(() => httpGet<IData>(`/wt/expected-outbounds/items/${match.params.itemId}`), []);
	const [dataStateRecord] = useObservable(() => dataState.recordChange, []);
	const [activeStep, setActiveStep] = useState(0);
	const [dataState, setDataState] = useState(new DataState<IDataState>({
		quantity: 0
	}));

	useEffect(() => {
		setApplicationTitle(`Výdej ${data?.item.sku.name || ''}`);
	}, [data]);

	useEffect(() => {
		const sub = dataState.pathChange
			.subscribe(change => {
				if (change.path == 'location' && change.currentValue) {
					setActiveStep(1);
				} else if (change.path == 'sku' && change.currentValue) {
					setActiveStep(2);
				} else if (change.path == 'palette' && change.currentValue) {
					setActiveStep(3);
				}
			});

		return () => sub.unsubscribe();
	}, []);


	const handleConfirm = async () => {
		let result = await httpPost(`/wt/expected-outbounds/items/${data.item.id}/confirm`, dataState.record);
		result && browserHistory.push(result.navigateTo);
	};

	return loading ? (
		<CircularProgress />
	) : (
			<div>
				<SwitchBottomNavigation>
					<SwitchBottomNavigationCase label="Detail" icon="assignment">
						<Box p={1}>
							<SummaryList>
								<SummaryListItem label="Doklad">{data.expectedOutbound.documentNumber}</SummaryListItem>
								<SummaryListItem label="SKU">{data.item.sku.name}</SummaryListItem>
								<SummaryListItem label="Zbývá">{data.item.remainingQuantity}</SummaryListItem>
								<SummaryListItem label="Hotovo">{data.item.completeQuantity}</SummaryListItem>
								<SummaryListItem label="Stav">{translate(data.item.state)}</SummaryListItem>
							</SummaryList>
							<Stepper activeStep={activeStep} orientation="vertical">
								<Step>
									<StepLabel onClick={() => setActiveStep(0)}>Lokace {dataStateRecord.location?.code}</StepLabel>
									<StepContent>
										<ScanTextBox
											path="location"
											dataState={dataState}
											viewportListen={true}
											endpoint={v => modelQueryUrl('/warehouses/locations', {
												code: { equals: v }
											})}></ScanTextBox>
									</StepContent>
								</Step>
								<Step>
									<StepLabel onClick={() => setActiveStep(1)}>SKU {dataState.record.sku?.masterCode}</StepLabel>
									<StepContent>
										<ScanTextBox
											path="sku"
											dataState={dataState}
											viewportListen={true}
											endpoint={v => modelQueryUrl('/skus', {
												masterCode: { equals: v }
											})}></ScanTextBox>
									</StepContent>
								</Step>
								<Step>
									<StepLabel onClick={() => setActiveStep(2)}>Paleta {dataState.record.palette?.number}</StepLabel>
									<StepContent>
										<ScanTextBox path="palette" dataState={dataState} endpoint={v => modelQueryUrl('/palettes', {
											number: { equals: v }
										})}></ScanTextBox>
									</StepContent>
								</Step>
								<Step>
									<StepLabel onClick={() => setActiveStep(3)}>Množství</StepLabel>
									<StepContent>
										<TextBox path="quantity" autoFocus dataState={dataState}></TextBox>
									</StepContent>
								</Step>
							</Stepper>
							<Button variant="contained" color="primary" fullWidth={true} onClick={handleConfirm}>Potvrdit položku</Button>
						</Box>
					</SwitchBottomNavigationCase>

					<SwitchBottomNavigationCase label="Události" icon="list">
						<List>
							<RemoteItems
								endpoint={modelQueryUrl<Activity>('/activities', {
									modelName: { equals: 'ExpectedOutboundItem' },
									modelId: { equals: data.item.id }
								})}
								each={(item: Activity) => (
									<ListItem key={item.id}>
										<ListItemText primary={(
											<div>{localeFormatDateTime(item.createdAt)} | {item.identity.name}</div>
										)} secondary={translate(item.message)} />
									</ListItem>
								)} />
						</List>
					</SwitchBottomNavigationCase>
				</SwitchBottomNavigation>
			</div>
		);
}

export default ExpectedOutboundProcessItemPage;
