import React, { useState, useEffect, useContext } from 'react';
import { ItemList } from '../helpers/itemList';
import { CircularProgress, Box } from '@material-ui/core';

const Preloader: React.FC<{
	itemList?: ItemList;
	loading?: boolean;
	error?: Error;
	content?: any;
}> = ({ itemList, error, loading, content }) => {
	const [loadingValue, setLoadingValue] = useState(false);

	useEffect(() => {
		if (itemList) {
			const sub = itemList.loading
				.subscribe(state => setLoadingValue(state));

			return () => sub.unsubscribe();
		}
	}, [itemList]);

	if (error) {
		return (
			<Box p={2} display="flex" justifyContent="center">
				Načtení se nezdařilo
			</Box>);
	} else {
		return loading == true || loadingValue ? (
			<Box p={2} display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		) : <React.Fragment>{content()}</React.Fragment>;
	}
}

export default Preloader;
