import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ApplicationContext } from '../contexts/application-context';

const PrivatePage: React.FC<RouteProps> = (options) => {
	const applicationContext = useContext(ApplicationContext);

	if (applicationContext.identity != null) {
		return <Route {...options} exact></Route>;
	} else {
		return <Redirect to={{
			pathname: '/login'
		}} />
	}
}

export default PrivatePage;
