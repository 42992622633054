import React, { useState, useEffect, useContext } from 'react';
import DynamicTable from '../../controls/DynamicTable';
import { modelQueryUrl } from '../../api/http';
import { Activity } from '../../api/models';
import { localeFormatDateTime } from '../../providers/modal/locale';

const ModelActivityTable: React.FC<{
	modelName?: string;
	modelId?: number;
	endpoint?: string;
}> = ({ modelName, modelId, endpoint }) => {
	return (
		<DynamicTable<Activity> definition={{
			endpoint: endpoint || modelQueryUrl('/activities', {
				modelName: { equals: modelName },
				modelId: { equals: modelId }
			}),
			columns: [
				{ label: 'Kdy', width: 100, get: x => localeFormatDateTime(x.createdAt) },
				{ label: 'Uživatel', width: 100, get: x => x.identity?.name },
				{ label: 'Zpráva', width: 200, get: x => x.message }
			]
		}}></DynamicTable>
	);
}

export default ModelActivityTable;
