import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useContext, useState } from 'react';
import { httpPost } from '../../api/http';
import { Deposit } from '../../api/models';
import StatedCheckbox from '../../controls/StatedCheckbox';
import { DataState, DataStateContext } from '../../helpers/dataState';
import { emitRefreshModel } from '../../providers/app';
import { ModalContext } from '../../providers/modal';

type ThisModel = Deposit;
const ModelName = 'Deposit';

const DepositDisposeDialog: React.FC<{
	depositId: number
}> = ({ depositId }) => {
	const modalHandler = useContext(ModalContext);

	const [dataState, setDataState] = useState(new DataState<{
		// orderDisposeService: boolean,
		createOutboundRequest: boolean
	}>({
		createOutboundRequest: true,
		// orderDisposeService: true
	}));

	const onCancel = () => {
		modalHandler().close();
	};

	const onConfirm = async () => {
		let result = await httpPost(`/deposit-terminal-controller/${depositId}/dispose`, dataState.record);

		if (result) {
			emitRefreshModel(ModelName, depositId);
			modalHandler().close();
		}
	};

	return <Dialog open={true as any}
		fullWidth={true}
		maxWidth="sm"
		onClose={onCancel}>
		<DialogTitle>{"Likvidace uskladněnky"}</DialogTitle>
		<DialogContent>
			<DataStateContext.Provider value={dataState}>
				{/* <StatedCheckbox path="orderDisposeService" label="Přidat službu Likvidace do objednávky, pokud již neexistuje" /> */}
				<StatedCheckbox path="createOutboundRequest" label="Vytvořit očekávaný výdej" />
			</DataStateContext.Provider>
		</DialogContent>
		<DialogActions>
			<Button variant="text" onClick={onCancel}>Zrušit</Button>
			<Button variant="contained" color="primary" onClick={onConfirm}>Potvrdit likvidaci</Button>
		</DialogActions>
	</Dialog>
}

export default DepositDisposeDialog;
