import DepositsDeliveryPickupConfirmationPage from './pages/DepositsDeliveryPickupConfirmationPage';
import DepositsDeliveryPickupDetailPage from './pages/DepositsDeliveryPickupDetailPage';
import DepositsInboundConfirmationPage from './pages/DepositsInboundConfirmationPage';
import DepositsInboundDetailPage from './pages/DepositsInboundDetailPage';
import DepositsInboundPage from './pages/DepositsInboundPage';
import DepositsOutboundConfirmationPage from './pages/DepositsOutboundConfirmationPage';
import DepositsOutboundDetailPage from './pages/DepositsOutboundDetailPage';
import DepositsOutboundPage from './pages/DepositsOutboundPage';
import DepositsPickupDeliveryPage from './pages/DepositsPickupDeliveryPage';
import ExpectedInboundDetailPage from './pages/ExpectedInboundDetailPage';
import ExpectedInboundProcessItemPage from './pages/ExpectedInboundProcessItemPage';
import ExpectedInboundProcessPage from './pages/ExpectedInboundProcessPage';
import history from '../browserHistory';
import InboundQueueDetailPage from './pages/InboundQueueDetailPage';
import InboundQueuesPage from './pages/InboundQueuesPage';
import InfoPage from './pages/InfoPage';
import LoginPage from '../shared/LoginPage';
import NotFoundPage from '../shared/NotFoundPage';
import PrivatePage from '../shared/PrivatePageWrapper';
import React, { useContext, useEffect, useState } from 'react';
import WarehouseLocationDetailPage from './pages/WarehouseLocationDetailPage';
import {
	AppBar,
	Drawer,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	ThemeProvider,
	Toolbar,
	Typography
} from '@material-ui/core';
import { ApplicationContext } from '../contexts/application-context';
import { ApplicationTitle$, PageMenu$ } from '../providers/app';
import PaletteDetailPage from './pages/PaletteDetailPage';
import { getTheme } from '../theme';
import { Route, Router, Switch } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import SkuDetailPage from './pages/SkuDetailPage';
import { httpPost } from '../api/http';
import OutboundQueuesPage from './pages/OutboundQueuesPage';
import OutboundQueueDetailPage from './pages/OutboundQueueDetailPage';
import ExpectedOutboundDetailPage from './pages/ExpectedOutboundDetailPage';
import ExpectedOutboundProcessPage from './pages/ExpectedOutboundProcessPage';
import ExpectedOutboundProcessItemPage from './pages/ExpectedOutboundProcessItemPage';
import MoveMenuPage from './pages/MoveMenuPage';
import MovePalettePage from './pages/MovePalettePage';
import { checkRolePermissions } from '../providers/auth';
import browserHistory from '../browserHistory';
import TerminalMenu from './TerminalMenu';
import TerminalHomePage from './pages/TerminalHomePage';
import PaletteDeliveryPickupDetailPage from './pages/PaletteDeliveryPickupDetailPage';

const Terminal: React.FC<{

}> = ({ }) => {
	const theme = getTheme();
	const applicationContext = useContext(ApplicationContext);
	const [pageMenu] = useObservable(() => PageMenu$, []);

	const [pageMenuOpen, setPageMenuOpen] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [title, setTitle] = useState(null);

	useEffect(() => {
		const sub = ApplicationTitle$
			.subscribe(title => {
				setTitle(title)
			});

		return () => sub.unsubscribe();
	}, []);

	const handleMenuItemClick = (url: string) => {
		setMenuOpen(false);
		history.push(url);
	};

	const logout = () => {
		localStorage.removeItem('jwt');
		browserHistory.push('/login');
	};

	return <ThemeProvider theme={theme}>
		<Router history={history}>
			{/* <input type="file" accept="image/*" capture onChange={event => {
				const f = event.target.files[0];
				console.log(f);
				const data = new FormData()
				data.append('some', f);
				httpPost('/wt/upload', data);
			}} /> */}
			<Switch>
				<Route path="/login">
					<LoginPage />
				</Route>
				<Route path="/">
					<AppBar position="static">
						<Toolbar variant="dense">
							<IconButton
								color="inherit"
								onClick={() => setMenuOpen(true)}
								edge="start">
								<Icon>menu</Icon>
							</IconButton>
							<Drawer
								variant="temporary"
								anchor="left"
								ModalProps={{
									onBackdropClick: () => setMenuOpen(false)
								}}
								open={menuOpen}>
								<List subheader={<ListSubheader>{applicationContext.identity?.name}</ListSubheader>} style={{ width: '60vw' }}>
								</List>
								<TerminalMenu handleMenuItemClick={handleMenuItemClick} />
								<List subheader={<ListSubheader>Uživatel</ListSubheader>} style={{ width: '60vw' }}>
									<ListItem button onClick={() => logout()}>
										<ListItemText primary="Odhlásit se" />
									</ListItem>
								</List>
							</Drawer>
							<Typography style={{ flexGrow: 1 }}>{title}</Typography>
							{pageMenu && pageMenu.length > 0 ? (
								<React.Fragment>
									<IconButton
										color="inherit"
										onClick={() => setPageMenuOpen(true)}
										edge="start">
										<Icon>more_horiz</Icon>
									</IconButton>
									<Drawer
										variant="temporary"
										anchor="bottom"
										ModalProps={{
											onBackdropClick: () => setPageMenuOpen(false)
										}}
										open={pageMenuOpen}>
										<List style={{ minHeight: '30vh' }}>
											{pageMenu.map(item => (
												<ListItem key={item.label} button onClick={() => {
													setPageMenuOpen(false);
													item.onClick();
												}}>
													{item.icon && (
														<ListItemIcon>
															<Icon>{item.icon}</Icon>
														</ListItemIcon>
													)}
													<ListItemText primary={item.label} />
												</ListItem>
											))}
										</List>
									</Drawer>
								</React.Fragment>
							) : null}
						</Toolbar>
					</AppBar>
					{/* <Switch> */}
					<PrivatePage path="/" exact component={TerminalHomePage} />
					<PrivatePage path="/expected-inbounds/:id" component={ExpectedInboundDetailPage} />
					<PrivatePage path="/expected-inbounds/:id/process" component={ExpectedInboundProcessPage} />
					<PrivatePage path="/expected-inbounds/items/:itemId/process" component={ExpectedInboundProcessItemPage} />

					<PrivatePage path="/inbound-queues/:queueId" component={InboundQueueDetailPage} />
					<PrivatePage path="/inbound-queues" component={InboundQueuesPage} />

					<PrivatePage path="/expected-outbounds/:id" component={ExpectedOutboundDetailPage} />
					<PrivatePage path="/expected-outbounds/:id/process" component={ExpectedOutboundProcessPage} />
					<PrivatePage path="/expected-outbounds/items/:itemId/process" component={ExpectedOutboundProcessItemPage} />

					<PrivatePage path="/outbound-queues/:queueId" component={OutboundQueueDetailPage} />
					<PrivatePage path="/outbound-queues" component={OutboundQueuesPage} />

					<PrivatePage path="/deposits/outbound" component={DepositsOutboundPage} />
					<PrivatePage path="/deposits/outbound/:officeId" component={DepositsOutboundDetailPage} />
					<PrivatePage path="/deposits/outbound/:officeId/confirmation" component={DepositsOutboundConfirmationPage} />

					<PrivatePage path="/move" component={MoveMenuPage} />
					<PrivatePage path="/move/palettes" component={MovePalettePage} />

					<PrivatePage path="/deposits/inbound" component={DepositsInboundPage} />
					<PrivatePage path="/deposits/inbound/:officeId" component={DepositsInboundDetailPage} />
					<PrivatePage path="/deposits/inbound/:officeId/confirmation" component={DepositsInboundConfirmationPage} />

					<PrivatePage path="/deposits/delivery-pickup" component={DepositsPickupDeliveryPage} />
					<PrivatePage path="/deposits/delivery-pickup/:officeId" component={DepositsDeliveryPickupDetailPage} />
					<PrivatePage path="/deposits/delivery-pickup/:officeId/confirmation" component={DepositsDeliveryPickupConfirmationPage} />

					<PrivatePage path="/warehouse-locations/:id" component={WarehouseLocationDetailPage} />
					<PrivatePage path="/skus/:id" component={SkuDetailPage} />

					<PrivatePage path="/palettes/:id" component={PaletteDetailPage} />
					{/* <PrivatePage path="/palettes/delivery-pickups" component={PaletteDeliveryPickupsPage} /> */}
					{/* <PrivatePage path="/palettes/delivery-pickups/:id" component={PaletteDeliveryPickupDetailPage} /> */}
					<PrivatePage path="/palettes/delivery-pickups/new" component={PaletteDeliveryPickupDetailPage} />

					<PrivatePage path="/info" component={InfoPage} />

					{/* <Route component={NotFoundPage} />
					</Switch> */}
				</Route>
				<Route component={NotFoundPage} />
			</Switch>
		</Router>
	</ThemeProvider>;
}

export default Terminal;
