import { green, grey, purple, orange } from '@material-ui/core/colors';
import React from 'react';
import { ServiceItemState } from '../../api/models';
import { translate } from '../../providers/locale';

export function getServiceItemStateColor(state: ServiceItemState, contrast = 900) {
	if (state == ServiceItemState.Done) {
		return (green as any)[contrast];
	} else if (state == ServiceItemState.InProgress) {
		return (purple as any)[contrast];
	} else if (state == ServiceItemState.Created) {
		return (orange as any)[contrast];
	} else if (state == ServiceItemState.Canceled) {
		return (grey as any)[contrast];
	}
}

const ServiceItemStateInline: React.FC<{
	state: ServiceItemState
}> = ({ state }) => {
	let color = getServiceItemStateColor(state);
	return <span style={{ color }}>{translate(state)}</span>;
}

export default ServiceItemStateInline;
