import { httpGet } from '../api/http';

let translations: any = {};

export async function refreshTranslations() {
	return new Promise((resolve) => {
		let cachedText = localStorage.getItem('translations');
		let resolved = false;
		let parsed: any;

		if (cachedText) {
			parsed = JSON.parse(cachedText);

			if (parsed && parsed.hash && parsed.translations) {
				translations = parsed.translations;
				resolved = true;
				resolve();
			}
		}

		httpGet<any>(`/ui/applications/wms/translations/cs`, {
			hash: (parsed ? parsed.hash : null)
		})
			.then((response: { upToDate: boolean, hash: string, translations: any }) => {
				if (response.upToDate) {
					resolve();
				} else {
					translations = response.translations;
					localStorage.setItem('translations', JSON.stringify({
						hash: response.hash,
						translations: response.translations
					}));

					if (resolved == false) {
						resolve();
					}
				}
			});
	});
}

// refreshTranslations();

export function translate(key: string, overrides: any = {}) {
	if (key != null) {
		return overrides[key.toString()] || translations[key.toString()] || key.toString();
	} else {
		return null;
	}
}
