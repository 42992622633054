import { useTheme } from '@material-ui/core';
import React from 'react';
import { Deposit, DepositState } from '../../api/models';
import { localeFormatDate } from '../../providers/modal/locale';
import DepositStateInline, { getDepositStateColor } from '../controls/DepositStateInline';
import DepositDetail from './DepositDetail';
import ModelRecordList from './ModelRecordList';
import { modelQueryUrl, httpGet } from '../../api/http';
import { translate } from '../../providers/locale';

type ThisModel = Deposit;
const ModelName = 'Deposit';

const DepositListPage: React.FC<{}> = (props) => {
	const theme = useTheme();

	return <ModelRecordList<ThisModel>
		endpoint="/deposits"
		// query={{
		// 	$select: [
		// 		'documentNumber',
		// 		'customerDocumentNumber',
		// 		'palette.number',
		// 		'customer.name',
		// 		'office.name',
		// 		'state',
		// 		'storedAt'
		// 	]
		// }}
		modelName={ModelName}
		title="Přehled uskladněnek"
		columns={[
			{ label: 'TL Číslo', get: x => x.documentNumber, width: 100, filterType: 'text', filterPath: 'documentNumber' },
			{ label: 'Číslo dealera', get: x => x.customerDocumentNumber, width: 100, filterType: 'text', filterPath: 'clientDocumentNumber' },
			{ label: 'Paleta', get: x => x.palette?.number, width: 100, filterType: 'text', filterPath: 'palette.number' },
			{
				label: 'Dealer', get: x => x.customer?.name, width: 100, filterType: 'select', filterPath: 'customer.id', filterOptions: term => httpGet(modelQueryUrl(`/customers`, {
					name: {
						contains: term
					}
				}))
			},
			{
				label: 'Pobočka', get: x => x.office?.name, width: 100, filterType: 'select', filterPath: 'office.id', filterOptions: term => httpGet(modelQueryUrl(`/customers/branch-offices`, {
					name: {
						contains: term
					}
				}))
			},
			{
				label: 'Stav', get: x => <DepositStateInline state={x.state} />, width: 100, backgroundColor: x => getDepositStateColor(x.state, 50), filterType: 'enum', filterPath: 'state',
				filterOptions: async term => Object.keys(DepositState).map(key => ({ name: translate(key), id: key }))
			},
			{ label: 'Uskladněno', get: x => localeFormatDate(x.storedAt), width: 100, filterType: 'date', filterPath: 'storedAt' },
			{ label: 'Vozidlo', get: x => x.vehicle?.name, width: 100, filterType: 'text', filterPath: 'vehicle.name' },
			{ label: 'SPZ', get: x => x.vehicle?.licensePlate, width: 100, filterType: 'text', filterPath: 'vehicle.licensePlate' },
			{ label: 'Zákazník', get: x => x.client, width: 100, filterType: 'text', filterPath: 'client' }
		]}
		multiActions={{
			label: x => `${x.documentNumber} ${x.palette.number}`,
			modelName: 'Deposit',
			actions: [
				{ label: 'Likvidace', onClick: async x => { }, batch: true }
			]
		}}
		sideDetailComponent={item => <DepositDetail id={item.id} />}
	/>;
}

export default DepositListPage;
