import * as objectPath from 'object-path';
import { deepClone } from 'fast-json-patch/module/core';

export function groupBy<T>(items: T[], fn: (x: T) => any) {
	let groups: {
		[key: string]: { key: any, items: T[] }
	} = {};

	for (let item of items) {
		let groupKey = fn(item);
		let knownGroup = groups[groupKey];

		if (knownGroup == null) {
			knownGroup = {
				key: groupKey,
				items: []
			};

			groups[groupKey] = knownGroup;
		}

		knownGroup.items.push(item);
	}

	return Object.values(groups);
}

export function toggleArrayValue<T>(array: T[], value: T) {
	let index = array.indexOf(value);

	if (index == -1) {
		array.push(value);
	} else {
		array.splice(index, 1);
	}

	return [].concat(array);
}

export function clone<T>(value: T) {
	return deepClone(value) as T;
}

export type AsOptional<T> = {
	[P in keyof T]?: T[P];
};
