import { Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import MenuBar from '../../shared/MenuBar';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';

export interface ModelRecordSideDetailProps<T> {
	item: T;
	tabs: {
		label: string;
		menuBar?: React.ReactElement[];
		summaryList?: {
			label: string;
			get: (item: T) => React.ReactNode;
		}[];
		content?: (item: T) => React.ReactElement;
	}[];
}

function TabPanel(props: any) {
	const { children, value, index, ...other } = props;
	return value === index && <React.Fragment>{children}</React.Fragment>;
}

function ModelRecordSideDetail<T>({ item, tabs }: ModelRecordSideDetailProps<T>) {
	const [selectedTabIndex, setSelectedTabIndex] = useState(0);

	return item && (
		<React.Fragment>
			<Tabs
				value={selectedTabIndex}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				style={{ background: '#fafafa' }}
				onChange={(event, newValue) => setSelectedTabIndex(newValue)}>
				{tabs.map(tab => <Tab key={tab.label} label={tab.label} />)}
			</Tabs>
			{tabs.map((tab, index) => (
				<TabPanel key={index} value={selectedTabIndex} index={index}>
					{tab.menuBar && (
						<MenuBar>
							{tab.menuBar
								.map((element, index) => <div key={index}>{element}</div>)}
						</MenuBar>
					)}
					{
						tab.summaryList && (
							<SummaryList>
								{tab.summaryList.map(summaryItem => (
									<SummaryListItem key={summaryItem.label} label={summaryItem.label}>{summaryItem.get(item)}</SummaryListItem>
								))}
							</SummaryList>
						)
					}
					{
						tab.content && tab.content(item)
					}
				</TabPanel>
			))}
		</React.Fragment>
	);
}

export default ModelRecordSideDetail;
