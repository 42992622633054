import React, { useState, useEffect, useContext } from 'react';
import { Button, ButtonProps, useTheme } from '@material-ui/core';
import { openConfirm, openModal } from '../providers/modal';
import SimpleDialog, { SimpleDialogProps } from '../desktop/controls/SimpleDialog';

const DynamicButton: React.FC<ButtonProps & {
	label?: string;
	confirm?: string | boolean;
	openFullHeightDialog?: {
		title: string;
		component: () => React.ReactElement;
	}
	openDialog?: {
		title: string;
		options?: SimpleDialogProps;
		component: () => React.ReactElement;
	}
}> = ({ children, confirm, label, openDialog, openFullHeightDialog, ...props }) => {
	const theme = useTheme();

	const handleClick = async (event: any) => {
		if (confirm) {
			let result = await openConfirm(typeof confirm == 'string' ? confirm : 'Potvrdit akci');

			if (result == false) {
				return;
			}
		}

		if (openFullHeightDialog) {
			openModal((
				<SimpleDialog  title={openFullHeightDialog.title} fullHeight={true}>{openFullHeightDialog.component()}</SimpleDialog>
			), theme);
		} else if (openDialog) {
			openModal((
				<SimpleDialog {...openDialog.options} title={openDialog.title}>{openDialog.component()}</SimpleDialog>
			), theme);
		} else if (props.onClick) {
			props.onClick(event);
		}
	};

	return (
		<Button {...props} onClick={handleClick}>{label || children}</Button>
	);
}

export default DynamicButton;
