import React from 'react';
import { ExpectedInbound, PrintReportType, InboundDocument } from '../../api/models';
import ModelRecordList from './ModelRecordList';
import { localeFormatDate } from '../../providers/modal/locale';
import { translate } from '../../providers/locale';
import ModelRecordSideDetail from './ModelRecordSideDetail';
import ModelActivityTable from '../controls/ModelActivityTable';
import Table from '@material-ui/core/Table';
import { TableHead, TableRow, TableCell, TableBody, Paper, Box } from '@material-ui/core';
import DynamicButton from '../../controls/DynamicButton';
import DepositForm from './DepositForm';
import PrintDialog from '../controls/PrintDialog';
import { openDialog2 } from '../../providers/modal';

type ThisModel = InboundDocument;

const InboundDocumentListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/inbound-documents"
		title="Přehled příjmových dokladů"
		columns={[
			{ label: 'Číslo dokladu', get: x => x.documentNumber, width: 100 },
			{ label: 'Stav', get: x => translate(x.state), width: 100 },
			{ label: 'Počet položek', get: x => x.items.length, width: 100 },
			{ label: 'Celkové množství', get: x => x.items.reduce((total, current) => total + current.quantity, 0), width: 100 }
		]}
		sideDetailComponent={item => <ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					menuBar: [
						(<DynamicButton label="Tisk" variant="contained" openDialog={{
							title: 'Tisk',
							options: { maxWidth: 'sm' },
							component: () => <PrintDialog reportType={PrintReportType.ExpectedInbound} itemId={item.id} />
						}} />)
					],
					summaryList: [
						{ label: 'Číslo dokladu', get: x => x.documentNumber },
						{ label: 'Stav', get: x => translate(x.state) }
					],
					content: x => (
						<Box p={2}>
							<Paper>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>SKU</TableCell>
											<TableCell>Paleta</TableCell>
											<TableCell>Množství</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{x.items.map(item => (
											<TableRow key={item.id}>
												<TableCell>{item.sku.name}</TableCell>
												<TableCell>{item.palette?.number}</TableCell>
												<TableCell>{item.quantity}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Paper>
						</Box>
					)
				}, {
					label: 'Události',
					content: item => <ModelActivityTable modelName="ExpectedInbound" modelId={item.id} />
				}
			]} />}
	/>;
}

export default InboundDocumentListPage;
