import browserHistory from '../../browserHistory';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState } from 'react';
import { ApplicationTitle$ } from '../../providers/app';
import { Box } from '@material-ui/core';
import { DataState } from '../../helpers/dataState';
import { openAlert } from '../../providers/modal';
import { httpPost } from '../../api/http';
import { PrimaryButton } from '../../shared/presets';
import { TextBox, ScanTextBox } from '../../controls/base';
import { withRouteTitle } from '../../helpers/withRouteTitle';

const InfoPage: React.FC<{

}> = ({ }) => {
	const [dataState, setDataState] = useState(new DataState({
		code: ''
	}));
	const [textValue, setTextValue] = useState('');

	const handleClick = async (event?: any) => {
		if (event) {
			event.preventDefault();
		}

		try {
			let code = dataState.record.code || textValue;
			let result = await httpPost<{ navigateTo: string }>('/wt/info', { code });
			result && browserHistory.push(result.navigateTo);
		} catch (error) {

		}
	};

	return (
		<Box p={2}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					{/* <form onSubmit={handleClick}> */}
						<ScanTextBox
						path='code'
						onTyped={value => setTextValue(value)}
						dataState={dataState}
						viewportListen={true}
						onConfirm={(item, setText) => {
							setText('');
							handleClick();
						}}
						TextFieldProps={{ label: 'Zadejte kód' }}></ScanTextBox>
						{/* <PrimaryButton onClick={handleClick}>Potvrdit</PrimaryButton> */}
						<PrimaryButton type="submit" onClick={handleClick}>Potvrdit</PrimaryButton>
					{/* </form> */}
				</Grid>
			</Grid>
		</Box>
	);
}

export default withRouteTitle(InfoPage, 'Info');
