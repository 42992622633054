import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect } from 'react';
import RemoteItems from '../../shared/RemoteItems';
import {
	Box,
	Checkbox,
	Divider,
	ListItemIcon,
	Typography,
	Table,
	TableBody,
	TableRow,
	TableCell
} from '@material-ui/core';
import { Deposit, DepositState } from '../../api/models';
import { match } from 'react-router-dom';
import { modelQueryUrl, httpPost, httpGet, httpPostBlocking } from '../../api/http';
import { PrimaryButton } from '../../shared/presets';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import browserHistory from '../../browserHistory';
import { useObservable } from 'react-use-observable';
import { from } from 'rxjs';
import { ScanTextBox } from '../../controls/base';
import { wait } from '@testing-library/react';
import { openConfirm } from '../../providers/modal';

let xxx: any = [];

const DepositsInboundDetailPage: React.FC<{
	match: match<{ officeId: string }>
}> = ({ match }) => {
	const [checked, setChecked] = React.useState([]);

	useEffect(() => {
		xxx = [];
	}, []);

	const handleToggle = (value: number) => {
		const currentIndex = xxx.indexOf(value);
		const newChecked = [...xxx];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
		xxx = newChecked;
	};

	const confirm = async () => {
		let text = (unscannedDeposits().length == 0 ? 'Všechny uskladněnky byly naskenovány' : (
			<React.Fragment>
				Tyto uskladněnky <b>nebyly</b> naskenovány
				<br />
				<Table>
					<TableBody>
						{unscannedDeposits().map(deposit => (
							<TableRow>
								<TableCell>{deposit.documentNumber}</TableCell>
								<TableCell>{deposit.palette.number}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</React.Fragment>
		) as any)

		if (await openConfirm(text)) {
			let result = await httpPostBlocking('/deposit-terminal-controller/confirm/inbound', {
				selectedDepositIds: checked,
				depositStates: [DepositState.InboundProgress],
				officeId: match.params.officeId
			});

			result && browserHistory.push(result.navigateTo);
		}
	};

	const [deposits, setDeposits] = useObservable<Deposit[]>(() => {
		return from(httpGet<Deposit[]>(modelQueryUrl('/deposits', {
			office: {
				id: { equals: match.params.officeId }
			},
			state: { in: [DepositState.InboundProgress] }
		})));
	}, []);

	const unscannedDeposits = () => deposits
		.filter(d => checked.indexOf(d.id) == -1);

	return (
		<Box px={2}>
			<List component="nav">
				<ScanTextBox
					options={deposits}
					hideTextbox={true}
					getOptionSelected={(option, term) => option.palette?.number == term}
					viewportListen={true}
					onConfirm={(item, setText) => {
						setText('');
						handleToggle(item.id);
					}}></ScanTextBox>

				{deposits?.map(item => (
					<ListItem button key={item.id} onClick={() => handleToggle(item.id)}>
						<ListItemIcon>
							<Checkbox
								edge="start"
								checked={checked.indexOf(item.id) !== -1}
								disableRipple
							/>
						</ListItemIcon>
						<ListItemText primary={item.palette.number} secondary={item.documentNumber} />
					</ListItem>
				))}
				<Box p={2}>
					<Typography>Naskenováno {checked?.length} z celkových {deposits?.length}</Typography>
				</Box>
				<PrimaryButton disabled={checked.length == 0} onClick={confirm}>Potvrdit</PrimaryButton>
			</List >
		</Box>
	);
}

export default withRouteTitle(DepositsInboundDetailPage, 'Vykládka uskladněnek');
