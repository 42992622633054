import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { httpPost } from './api/http';
import { APP } from './providers/app';
import { refreshTranslations } from './providers/locale';

let lastGlobalError: Error;
window.addEventListener('error', async event => {
	if (lastGlobalError == event.error) {
		return;
	}

	lastGlobalError = event.error;

	try {
		httpPost(`/ui/client/logs`, {
			source: APP,
			message: event.error.message,
			level: 'error',
			stack: event.error.stack
		})
	} catch (error) {
		console.log('Cannot log errro to server', error);
	}
})

refreshTranslations().then(() => {
	ReactDOM.render(<App />, document.getElementById('root'));
}, () => {
	// TODO
	ReactDOM.render(<App />, document.getElementById('root'));
})
