import { Button, ButtonProps, Menu, makeStyles, Theme, createStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		menu: {
			minWidth: '280px'
		}
	}),
);

const ComponentMenu: React.FC<{ component: React.ReactElement }> = ({ component, children }) => {
	const [el, setEl] = useState(null);
	const classes = useStyles();

	return <React.Fragment>
		<div onClick={event => setEl(event.currentTarget)}>
			{component}
		</div>
		<Menu
			onClick={() => setEl(null)}
			open={Boolean(el)}
			anchorEl={el}
			getContentAnchorEl={null}
			onClose={() => setEl(null)}
			classes={{
				paper: classes.menu
			}}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}>
			{children}
		</Menu>
	</React.Fragment>
}

export default ComponentMenu;
