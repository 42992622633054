import React from 'react';
import { PaletteType } from '../../api/models';
import ModelRecordList from './ModelRecordList';

type ThisModel = PaletteType;

const PaletteTypeListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/palettes/types"
		title="Typy palet"
		columns={[
			{ label: 'Název', get: x => x.name, width: 100 }
		]}
	/>;
}

export default PaletteTypeListPage;
