import { useAsync } from "react-async-hook";
import { CircularProgress, Box } from '@material-ui/core';
import React from "react";
import { httpGet } from "../api/http";

const RemoteItems: React.FC<{
	endpoint: string;
	each: (item: any, index?: number) => React.ReactNode;
	divider?: React.ReactNode;
	noItemsText?: string | React.ReactNode;
}> = ({ endpoint, divider, each, noItemsText }) => {
	const { result: items, loading } = useAsync(() => httpGet<any[]>(endpoint), [endpoint]);

	return loading ? (
		<Box p={2} display="flex" justifyContent="center">
			<CircularProgress />
		</Box>
	) : (
			<React.Fragment>
				{items?.length > 0 ? items
					.map((item, index) => (
						<React.Fragment key={index}>
							{each(item, index)}
							{divider != null && divider}
						</React.Fragment>
					))
					: (
						null
						// <Box p={2} style={{ textAlign: 'center' }}>
						// 	{noItemsText}
						// </Box>
					)}
			</React.Fragment>
		);
}

RemoteItems.defaultProps = {
	noItemsText: "Žádné položky k zobrazení"
};

export default RemoteItems;
