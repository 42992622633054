import React, { useCallback } from 'react';

const HeightToBottomArea: React.FC<{}> = ({ children }) => {
	const initialize = useCallback((node: HTMLElement) => {
		if (node) {
			let top = node.getBoundingClientRect().top;
			let toBottom = window.innerHeight - top;
			node.style.height = `${toBottom}px`;
		}
	}, []);

	return <div style={{
		display: 'flex',
		flexDirection: 'column'
	}} ref={initialize}>{children}</div>;
}

export default HeightToBottomArea;
