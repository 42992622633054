import React, { useState, useEffect, useContext } from 'react';
import { Drawer, AppBar, Toolbar, Button, createStyles, makeStyles, Theme, Box, Table, TableBody, TableRow, TableCell, CircularProgress, Typography } from '@material-ui/core';
import { httpPost } from '../../api/http';
import { clone } from '../../utils';
import Loading from './Loading';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			flexGrow: 1
		}
	}),
);

export interface ISideActionDefinition {
	label: string;
	onClick: (ids: number[]) => Promise<void>;
	batch?: boolean;
}

export function SideMultiActions<T>({ modelName, actions, ids: [ids, setIds], items, itemLabel }: React.PropsWithChildren<{
	modelName?: string;
	actions: ISideActionDefinition[];
	label: (item: T) => React.ReactNode;
	ids: [number[], (state: number[]) => void];
	items?: any[];
	itemLabel?: (item: any) => string;
}>) {
	const classes = useStyles();
	const [labels, setLabels] = useState([]);
	const [loadingRecords, setLoadingRecords] = useState<any>({});
	const [anyLoadingRecords, setAnyLoadingRecords] = useState<boolean>(false);

	useEffect(() => {
		if (items) {
			setLabels(items
				.filter(item => ids.indexOf(item.id) > -1)
				.map(x => {
					return { $label: itemLabel(x) };
				}));
		} else if (ids && ids.length > 0) {
			httpPost(`/ui/models/${modelName}/labels`, {
				recordIds: ids
			})
				.then(labels => setLabels(labels));
		}
	}, [ids]);

	const handleClick = async (action: ISideActionDefinition) => {
		setAnyLoadingRecords(true);

		try {
			if (action.batch) {
				await action.onClick(ids);
				setAnyLoadingRecords(false);

			} else {
				let loadingRecords: any = {};
				ids.forEach(id => loadingRecords[id] = true);
				setLoadingRecords(loadingRecords);

				await Promise.all(ids.map(async recordId => {
					await action.onClick([recordId]);
					delete loadingRecords[recordId];

					setAnyLoadingRecords(Object.keys(loadingRecords).length > 0);
					setLoadingRecords(clone(loadingRecords));
				}));
			}

			setIds([]);
		} catch (error) {
			throw error;
		}
	};

	return <Drawer
		style={{ position: 'relative' }}
		ModalProps={{
			hideBackdrop: true,
			keepMounted: true,
			disablePortal: true
		}}
		anchor="right"
		open={ids.length > 0}
		onClose={() => setIds([])}>{
			<div style={{ width: '40vw' }}>
				<AppBar position="static">
					<Toolbar>
						<Box className={classes.box}>Vybraných položek: {ids.length}</Box>
						<Button onClick={() => setIds([])} color="inherit">Zavřít</Button>
					</Toolbar>
				</AppBar>
				<div style={{ height: '400px', maxHeight: '35vh', overflow: 'auto' }}>
					<Table>
						<TableBody>
							{labels.map((label, index) => <TableRow hover key={index}>
								<TableCell>{label.$label}</TableCell>
								<TableCell>
									{loadingRecords[label.id] && <CircularProgress size={14} />}
								</TableCell>
							</TableRow>)}
						</TableBody>
					</Table>
				</div>
				<Box p={1}>
					<Typography>Dostupné akce</Typography>
				</Box>
				<Box p={1}>
					<Loading
						loading={anyLoadingRecords}
						content={() => (
							actions?.length > 0 ? (
								<React.Fragment>
									{actions
										.map((action, index) => (
											<Button
												variant="contained"
												color="primary"
												fullWidth
												key={index}
												onClick={() => handleClick(action)}>{action.label}</Button>
										))}
								</React.Fragment>
							) : (
									<Box p={2} style={{ textAlign: 'center' }}>
										Žádné dustpné akce
									</Box>
								)
						)}
					/>
				</Box>
			</div>
		}
	</Drawer>;
}

export default SideMultiActions;
