import React, { useEffect } from "react";
import { ApplicationTitle$ } from '../providers/app';

interface WithRouteTitleProps { }

export function withRouteTitle<T extends WithRouteTitleProps = WithRouteTitleProps>(
	WrappedComponent: React.ComponentType<T> & { pageTitle?: string },
	pageTitle?: string
) {
	return class ComponentWithTheme extends React.Component<T, WithRouteTitleProps> {
		componentDidMount() {
			setTimeout(() => {
				if (pageTitle || WrappedComponent.pageTitle) {
					ApplicationTitle$.next(pageTitle || WrappedComponent.pageTitle);
				} else {
					ApplicationTitle$.next(null);
				}
			}, 2);
		}

		public render() {
			return <WrappedComponent {...(this.props as T)} />;
		}
	};
}
