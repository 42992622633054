import React, { useState, useEffect, useContext } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outlined: {
			background: '#f9f9f9',
			borderRadius: '4px',
			fontSize: '0.875rem',
			paddingLeft: '4px',
			paddingRight: '4px'
		},
		label: {
			fontWeight: 500,
			color: '#000',
			fontSize: '14px'
		},
		fieldset: {
			border: 0
		}
	})
);

const ListHeaderCell: React.FC<{
	label: string;
	options?: any[];
}> = ({ label, options }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	return <span>{label}</span>
	// if (options) {
	// 	return (
	// 		<Autocomplete<any>
	// 			open={open}
	// 			onOpen={() => setOpen(true)}
	// 			onClose={() => setOpen(false)}
	// 			// getOptionSelected={getOptionSelected}
	// 			// getOptionLabel={getOptionLabel}
	// 			// loading={loading}
	// 			options={options}
	// 			// value={value}
	// 			autoHighlight={true}
	// 			// onInputChange={(event: any, value: string) => setInputValue(value)}
	// 			// onChange={(event: any, value: T) => handleChange(value)}
	// 			renderInput={params => (
	// 				<TextField
	// 					{...params}
	// 					label={label}
	// 					variant="outlined"
	// 					margin="dense"
	// 					className="ListHeaderCell"
	// 					InputLabelProps={{
	// 						classes: {
	// 							root: classes.label,
	// 							outlined: classes.label
	// 						}
	// 					}}
	// 					InputProps={{
	// 						classes: {
	// 							input: classes.outlined,
	// 							notchedOutline: classes.fieldset
	// 						}
	// 					}}
	// 				/>
	// 			)} />
	// 	);
	// } else {
	// 	return (
	// 		<TextField
	// 			variant="outlined"
	// 			label={label}
	// 			margin="dense"
	// 			size="small"
	// 			className="ListHeaderCell"
	// 			fullWidth={true}
	// 			InputLabelProps={{
	// 				classes: {
	// 					root: classes.label,
	// 					outlined: classes.label
	// 				}
	// 			}}
	// 			InputProps={{
	// 				classes: {
	// 					input: classes.outlined,
	// 					notchedOutline: classes.fieldset
	// 				}
	// 			}}></TextField>
	// 	);
	// }
}

export default ListHeaderCell;
