import React, { useState, useEffect } from 'react';
import { match } from 'react-router-dom';
import { setApplicationTitle } from '../../providers/app';
import { Box, List, ListItem, ListItemIcon, Checkbox, ListItemText, Typography, Divider } from '@material-ui/core';
import { ScanTextBox } from '../../controls/base';
import { PrimaryButton } from '../../shared/presets';
import { Palette, PaletteDeliveryPickup, PaletteLocation, CustomerBranchOffice, PaletteDeliveryPickupState } from '../../api/models';
import { queryUrl, modelQueryUrl, httpPostBlocking } from '../../api/http';
import { DataState } from '../../helpers/dataState';
import { useObservable } from 'react-use-observable';
import { Select } from '../../controls/Select';
import browserHistory from '../../browserHistory';

const PaletteDeliveryPickupDetailPage: React.FC<{ match: match<any> }> = ({ match }) => {
	// const { result: palette, error } = useAsync(() => httpGet<Palette>(`/palettes/${match.params.id}`), []);
	const [dataState, setDataState] = useState(new DataState<PaletteDeliveryPickup>({
		paletteLocationDirection: PaletteLocation.Dealer,
		palettes: [],
		state: PaletteDeliveryPickupState.Completed
	}));
	const [dataStateRecord] = useObservable(() => dataState.recordChange, [dataState]);
	const [scanned, setScanned] = useState(false);

	useEffect(() => {
		setApplicationTitle(`Rozvoz palet`);
	}, []);

	const handleToggle = (value: Palette) => {
		dataStateRecord.palettes.push(value);
		dataState.set('palettes', dataStateRecord.palettes);
	};

	const confirm = async () => {
		let result = await httpPostBlocking('/palettes/delivery-pickups', dataStateRecord);

		result && browserHistory.push('/');
	};

	return dataStateRecord && (
		<Box px={2}>
			<List component="nav">
				{
					!scanned && (
						<React.Fragment>
							<ScanTextBox
								hideTextbox={true}
								getOptionSelected={(option, term) => option.palette?.number == term}
								viewportListen={true}
								endpoint={v => modelQueryUrl(`/palettes`, {
									number: {
										equals: v
									}
								})}
								onConfirm={(item, setText) => {
									setText('');
									handleToggle(item);
								}}></ScanTextBox>
							<Typography>Naskenujte palety</Typography>
							{dataStateRecord.palettes?.map(item => (
								<ListItem button key={item.id}>
									<ListItemText primary={item.number} />
								</ListItem>
							))}
							<PrimaryButton disabled={dataStateRecord.palettes.length == 0} onClick={() => setScanned(true)}>Pokračovat</PrimaryButton>
						</React.Fragment>
					)
				}
				{
					scanned && (
						<React.Fragment>
							<Select<CustomerBranchOffice>
								label="Pobočka"
								path="customerBranchOffice"
								dataState={dataState}
								endpoint={term => modelQueryUrl(`/customers/branch-offices`, {
									number: { contains: term }
								})}
								getOptionLabel={x => x.name}></Select>
							<PrimaryButton disabled={dataStateRecord.palettes.length == 0} onClick={confirm}>Potvrdit</PrimaryButton>
						</React.Fragment>
					)
				}
			</List >
		</Box>
	);
}

export default PaletteDeliveryPickupDetailPage;
