import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Icon, useTheme, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@material-ui/core';
import { openModal, ModalContext, openDialog, openFullHeightDialog } from '../../providers/modal';
import SimpleDialog from '../../desktop/controls/SimpleDialog';
import { useDebounce } from '@react-hook/debounce';
import TextField from '@material-ui/core/TextField';
import { httpGet } from '../../api/http';
import { translate } from '../../providers/locale';
import ContentSwitch, { ContentSwitchCase } from '../../shared/ContentSwitch';
import browserHistory from '../../browserHistory';
import DepositStateInline from '../../desktop/controls/DepositStateInline';
import DepositDetail from '../../desktop/pages/DepositDetail';
import { WarehouseLocation } from '../../api/models';

const TerminalLocationSelectDialog: React.FC<{
	onConfirm(location: WarehouseLocation): void;
}> = ({ onConfirm }) => {
	const modal = useContext(ModalContext);
	const theme = useTheme();

	const [searchValue, setSearchValue] = useDebounce('', 300);
	const [foundItems, setFoundItems] = useState<WarehouseLocation[]>([]);

	useEffect(() => {
		console.log(searchValue);
		if (searchValue) {
			onSearch(searchValue);
		} else {
			setFoundItems([]);
		}
	}, [searchValue]);

	const onSearch = async (value: string) => {
		let results = await httpGet(`/warehouses/locations`, {
			code: { starts: value }
		});

		setFoundItems(results);
	}

	const refreshDefaultLocations = async () => {
		let results = await httpGet(`/warehouses/locations`, {

		});

		setFoundItems(results);
	};

	useEffect(() => {
		refreshDefaultLocations();
	}, []);

	return (
		<SimpleDialog
			title="Vyhledávání"
			DialogProps={{
				fullScreen: true
			}}>
			<TextField
				InputLabelProps={{
					shrink: true
				}}
				placeholder="Název lokace"
				variant="outlined"
				autoFocus={true}
				fullWidth={true}
				onKeyUp={event => setSearchValue((event.target as any).value)}
			/>
			<Table style={{ tableLayout: 'fixed' }}>
				<TableHead>
					<TableRow>
						<TableCell>Lokace</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{foundItems.map(location => (
						<TableRow hover key={location.id} onClick={() => {
							modal().close();
							onConfirm(location);
						}}>
							<TableCell>{location.code} ({location.name})</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</SimpleDialog>
	);
}

const TerminalLocationSelectIcon: React.FC<{
	onConfirm(location: WarehouseLocation): void;
}> = ({ onConfirm }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<IconButton
				color="inherit"
				onClick={() => {
					openModal((<TerminalLocationSelectDialog onConfirm={onConfirm} />), theme)
				}}
				edge="start">
				<Icon>assignment</Icon>
			</IconButton>
		</React.Fragment>
	)
}

export default TerminalLocationSelectIcon;
