import React from 'react';
import { PaletteMovement, PaletteDeliveryPickup } from '../../api/models';
import ModelRecordList from './ModelRecordList';
import { localeFormatDateTime } from '../../providers/modal/locale';
import { translate } from '../../providers/locale';

type ThisModel = PaletteDeliveryPickup;

const PaletteDeliveryPickupListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/palettes/delivery-pickups"
		title="Doklady paletového konta"
		columns={[
			{ label: 'Číslo dokladu', get: x => x.documentNumber, width: 100 },
			{ label: 'Stav', get: x => translate(x.state), width: 100 },
			{ label: 'Cílové umístění', get: x => translate(x.paletteLocationDirection), width: 100 },
			{ label: 'Počet palet', get: x => x.palettes.length, width: 100 },
			{ label: 'Pobočka', get: x => x.customerBranchOffice?.name, width: 100 },
			{ label: 'Kdo', get: x => x.createdBy.name, width: 100 },
			{ label: 'Kdy', get: x => localeFormatDateTime(x.createdAt), width: 100 }
		]}
	/>;
}

export default PaletteDeliveryPickupListPage;
