import React, { useState, useEffect, useContext } from 'react';
import { Drawer, AppBar, Toolbar, Button, createStyles, makeStyles, Theme, Box } from '@material-ui/core';
import Loading from './Loading';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			flexGrow: 1
		}
	}),
);

export function SideDetail<T>({
	title,
	open: [open, setOpen],
	children,
	content
}: React.PropsWithChildren<{
	title: React.ReactNode,
	open: [any, (state: any) => void],
	content: () => React.ReactElement
}>) {
	const classes = useStyles();

	return <Drawer
		style={{ position: 'relative' }}
		ModalProps={{
			hideBackdrop: true,
			keepMounted: true,
			disablePortal: true
		}}
		anchor="right"
		open={Boolean(open)}
		onClose={() => setOpen(null)}>{
			<div style={{ width: '50vw' }}>
				<AppBar position="static">
					<Toolbar>
						<Box className={classes.box}>{title}</Box>
						<Button onClick={() => setOpen(null)} color="inherit">Zavřít</Button>
					</Toolbar>
				</AppBar>
				<Loading
					loading={!Boolean(open)}
					content={() => content()} />
			</div>
		}
	</Drawer>;
}

export default SideDetail;
