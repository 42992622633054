import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useState, useRef, useEffect } from 'react';
import { Box, Checkbox, ListItemIcon, Dialog, Badge, Typography, IconButton, Icon, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Deposit, DepositState, InboundRequest } from '../../api/models';
import { from } from 'rxjs';
import { httpGet, modelQueryUrl, httpPost, httpUpload, httpPostBlocking } from '../../api/http';
import { groupBy } from '../../utils';
import { map, tap } from 'rxjs/operators';
import { match } from 'react-router-dom';
import { PrimaryButton } from '../../shared/presets';
import { translate } from '../../providers/locale';
import { useObservable } from 'react-use-observable';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import browserHistory from '../../browserHistory';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { ScanTextBox } from '../../controls/base';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { openConfirm } from '../../providers/modal';

let xxx: any = [];

const DepositsDeliveryPickupDetailPage: React.FC<{
	match: match<{ officeId: string }>
}> = ({ match }) => {
	const [checked, setChecked] = useState([]);
	const [depositPhoto, setDepositPhoto] = useState<Deposit>(null);

	useEffect(() => {
		xxx = [];
	}, []);

	const handleToggle = (value: number) => {
		const currentIndex = xxx.indexOf(value);
		const newChecked = [...xxx];
		const isChecked = currentIndex == -1;

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		let deposit = deposits.find(d => d.id == value);
		setChecked(newChecked);
		xxx = newChecked;

		if (isChecked && deposit.state == DepositState.InboundRequest) {
			setDepositPhoto(deposit);
		}
	};

	const confirm = async () => {
		let text = (unscannedDeposits().length == 0 ? 'Všechny uskladněnky byly naskenovány' : (
			<React.Fragment>
				Tyto uskladněnky <b>nebyly</b> naskenovány
				<br /><br />
				{[DepositState.InboundRequest, DepositState.OutboundProgress]
					.filter(state => unscannedDeposits(state).length > 0)
					.map(state => (
						<React.Fragment key={state}>
							<Box>
								<Typography variant="caption">{translate(state)}</Typography>
								<Table>
									<TableBody>
										{unscannedDeposits(state).map(deposit => (
											<TableRow>
												<TableCell>{deposit.documentNumber}</TableCell>
												<TableCell>{deposit.palette.number}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Box>
						</React.Fragment>
					))}
			</React.Fragment>
		) as any)

		if (await openConfirm(text)) {
			let result = await httpPostBlocking<any>('/deposit-terminal-controller/confirm/delivery-pickup', {
				selectedDepositIds: checked,
				officeId: match.params.officeId,
				depositStates: [DepositState.OutboundProgress, DepositState.InboundRequest]
			});

			result && browserHistory.push(result.navigateTo);
		}
	};

	const [deposits, setDeposits] = useState<Deposit[]>([]);
	const [items, setItems] = useObservable(() => {
		return from(httpGet<Deposit[]>(modelQueryUrl('/deposits', {
			office: {
				id: { equals: match.params.officeId }
			},
			state: { in: [DepositState.OutboundProgress, DepositState.InboundRequest] }
		})))
			.pipe(tap(deposits => setDeposits(deposits)))
			.pipe(map(deposits => groupBy(deposits, d => d.state)));
	}, []);

	const filterDepositsByState = (state: DepositState) => (deposits || []).filter(d => d.state == state);
	const filterCheckedByState = (state: DepositState) => (checked || []).filter(id => {
		let d = deposits.find(d => d.id == id);
		return d?.state == state;
	});

	const unscannedDeposits = (state?: DepositState) => deposits
		.filter(d => state == null || d.state == state)
		.filter(d => checked.indexOf(d.id) == -1);

	return (
		<Box px={2}>
			<List component="nav">
				<ScanTextBox
					options={deposits}
					hideTextbox={true}
					getOptionSelected={(option, term) => option.palette?.number == term}
					viewportListen={true}
					onConfirm={(item, setText) => {
						setText('');
						handleToggle(item.id);
					}}></ScanTextBox>
				{items?.map(group => (
					<React.Fragment>
						<ListItem key={group.key}>{translate(group.key)}</ListItem>
						{group.items.map(deposit => (
							<ListItem button key={deposit.id} onClick={() => handleToggle(deposit.id)}>
								<ListItemIcon>
									<Checkbox
										edge="start"
										checked={checked.indexOf(deposit.id) !== -1}
										disableRipple
									/>
								</ListItemIcon>
								<ListItemText primary={deposit.palette.number} secondary={deposit.documentNumber} />
								{
									deposit.state == DepositState.InboundRequest && (
										<ListItemSecondaryAction>
											<IconButton disabled={checked.indexOf(deposit.id) == -1} onClick={() => setDepositPhoto(deposit)}>
												<Icon>photo_camera</Icon>
											</IconButton>
										</ListItemSecondaryAction>
									)
								}
							</ListItem>
						))}
					</React.Fragment>
				))}
				<Dialog open={depositPhoto != null} fullScreen={true}>
					<TakeDepositPhotoDialog deposit={depositPhoto} onClose={() => setDepositPhoto(null)} />
				</Dialog>
				<Box p={2}>
					<Typography>Svoz z pobočky: Naskenováno {filterCheckedByState(DepositState.InboundRequest)?.length} z celkových {filterDepositsByState(DepositState.InboundRequest)?.length}</Typography>
					<Typography>Odvoz na pobočku: Naskenováno {filterCheckedByState(DepositState.OutboundProgress)?.length} z celkových {filterDepositsByState(DepositState.OutboundProgress)?.length}</Typography>
				</Box>
				<PrimaryButton disabled={checked.length == 0} onClick={confirm}>Potvrdit</PrimaryButton>
			</List >
		</Box>
	);
}

const TakeDepositPhotoDialog: React.FC<{
	deposit: Deposit;
	onClose: () => void;
}> = ({ deposit, onClose }) => {
	const [tick, setTick] = useState(0);
	const [files, setFiles] = useState<{ src: string }[]>([]);
	const inputRef = useRef<HTMLInputElement>();
	const handleCapture = () => {
		let input = inputRef.current;
		const file = input.files[0];

		if (file == null) {
			return;
		}

		let formData = new FormData();
		formData.append('photo', file);

		let fileState: any = {
			src: null,
			uploaded: false
		};

		setFiles(x => {
			return [].concat(x).concat([fileState]);
		});

		const reader = new FileReader()
		reader.onload = event => {
			fileState.src = reader.result;
			setTick(x => x + 1);
		};
		// reader.onerror = error => reject(error)
		reader.readAsDataURL(file);

		httpUpload(`/deposit-terminal-controller/${deposit.id}/photos/upload`, formData)
			.then(() => {
				fileState.uploaded = true;
				setTick(x => x + 1);
			});
	};

	return deposit && (
		<Box p={2}>
			<Typography variant="h6">Vyfocení uskladněnky</Typography>
			<Typography variant="h6">{deposit.documentNumber} / {deposit.palette?.number}</Typography>
			<List>
				{files.map((file, index) => (
					<ListItem key={index}>
						<img src={file.src} style={{ width: '100%' }} />
					</ListItem>
				))}
			</List>
			<input ref={inputRef} style={{ visibility: 'hidden' }} type="file" accept="image/*" capture onChange={() => handleCapture()} />
			<Grid container>
				<Grid item xs={12}>
					<Button size="large" color="primary" variant="contained" fullWidth={true} onClick={() => inputRef.current.click()}>Vyfotit</Button>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<Button size="large" color="default" variant="contained" fullWidth={true} onClick={() => onClose()}>Zavřít</Button>
				</Grid>
			</Grid>
		</Box>
	);
}

export default withRouteTitle(DepositsDeliveryPickupDetailPage, 'Rozvoz/Svoz');
