type Identity = any;

export class Acl {
    id?: number;
    modelName?: string;
    entityId?: number;
    identityId?: number;
    roleId?: number;
    permission?: string;
}

export class Log {
    id?: number;
    source?: string;
    level?: string;
    identityId?: number;
    message?: string;
    tags?: string[];
    timestamp?: Date;
}

export class Deposit {
    id?: number;
    documentNumber?: string;
    customerDocumentNumber?: string;
    palette?: Palette;
    office?: CustomerBranchOffice;
    sku?: Sku;
    state?: DepositState;
    customer?: Customer;
    client?: string;
    vehicle?: DepositVehicle;
    items?: DepositItem[];
    treadreaderRenderUrl?: string;
    storedAt?: Date;
    removedAt?: Date;
    storedDays?: number;
    outboundDate?: Date;
    clientTireChangeDate?: Date;
    note?: string;
    photos?: Photo[];
    createdAt?: Date;
    updatedAt?: Date;
}

export class DepositItem {
    id?: number;
    name?: string;
    note?: string;
    season?: TireSeason;
    depth?: number;
    tireWidth?: number;
    vehiclePosition?: TireVehiclePosition;
    wheelType?: WheelType;
    wheelDescription?: string;
    disposed?: boolean;
}

export class DepositVehicle {
    id?: number;
    name?: string;
    licensePlate?: string;
    vin010?: string;
    vin7?: string;
    searchTerm?: string;
}

export class TreadReaderToken {
    id?: number;
    token?: string;
    dealerId?: number;
    officeId?: number;
}

export class OutsourcingDocument {
    id?: number;
    documentNumber?: string;
    totals?: number;
    state?: OutsourcingDocumentState;
    lmstate?: string;
    items?: OutsourcingDocumentItem[];
    createdAt?: Date;
}

export class OutsourcingDocumentItem {
    id?: number;
    destinationOrder?: number;
    destinationTotals?: number;
    documentOrder?: string;
    foreignItemNumber?: string;
    data?: any;
    positionIndex?: number;
}

export class OutsourcingItem {
    id?: number;
    due?: Date;
    orderId?: string;
    destinationRegion?: string;
    destinationCode?: string;
    labelTexts?: string[];
    itemName?: string;
    itemCode?: string;
    itemQuantity?: number;
    finishedAt?: Date;
    state?: OutsourcingItemState;
    data?: any;
}

export class PrintQueueItem {
    id?: number;
    content?: string;
    printed?: boolean;
    printedAt?: Date;
}

export class Task {
    id?: number;
    handlerName?: string;
    systemName?: string;
    description?: string;
    cron?: string;
    scheduledAt?: Date;
    timezone?: string;
    paused?: boolean;
    targetUrl?: string;
    targetJobName?: string;
    sqlQuery?: string;
    executionLog?: TaskExecutionLog;
    data?: any;
}

export class TaskExecution {
    id?: number;
    task?: Task;
    scheduleTime?: Date;
    duration?: number;
    state?: TaskExecutionState;
    responseData?: any;
}

export class BarcodeFormat {
    id?: number;
    name?: string;
    pattern?: string;
    labelTemplate?: string;
    widthMm?: number;
    heightMm?: number;
}

export class CustomerBranchOffice {
    id?: number;
    name?: string;
    customer?: Customer;
    depositDocumentNumberPattern?: string;
    address?: string;
}

export class Customer {
    id?: number;
    name?: string;
    depositNumericSequence?: NumericSequence;
    magisId?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export class CustomerUser {
    id?: number;
    customer?: Customer;
    name?: string;
    email?: string;
    identity?: Identity;
}

export class ExpectedInbound {
    id?: number;
    documentNumber?: string;
    inventoryMove?: InventoryMove;
    queueType?: InboundQueueType;
    state?: ExpectedInboundState;
    requiredDate?: Date;
    note?: string;
    items?: ExpectedInboundItem[];
    customer?: Customer;
    createdAt?: Date;
    updatedAt?: Date;
}

export class ExpectedInboundItem {
    id?: number;
    sku?: Sku;
    state?: ExpectedInboundState;
    expectedQuantity?: number;
    completeQuantity?: number;
    remainingQuantity?: number;
    palette?: Palette;
    pickups?: ExpectedInboundPickup[];
    destinationLocation?: WarehouseLocation;
    assignedTo?: User;
}

export class ExpectedInboundPickup {
    id?: number;
    skuLocation?: SkuLocation;
    quantity?: number;
}

export class InboundQueueType {
    id?: number;
    name?: string;
}

export class Photo {
    id?: number;
    filename?: string;
    createdAt?: Date;
}

export class ExpectedOutbound {
    id?: number;
    documentNumber?: string;
    inventoryMove?: InventoryMove;
    queueType?: OutboundQueueType;
    state?: ExpectedOutboundState;
    requiredDate?: Date;
    note?: string;
    items?: ExpectedOutboundItem[];
    customer?: Customer;
    branchOffice?: CustomerBranchOffice;
    createdBy?: Identity;
    createdAt?: Date;
    updatedAt?: Date;
}

export class ExpectedOutboundItem {
    id?: number;
    sku?: Sku;
    state?: ExpectedOutboundItemState;
    expectedQuantity?: number;
    completeQuantity?: number;
    remainingQuantity?: number;
    pickups?: ExpectedOutboundPickup[];
    palette?: Palette;
    sourceLocation?: WarehouseLocation;
    loadedAt?: Date;
    assignedTo?: User;
}

export class ExpectedOutboundPickup {
    id?: number;
    skuLocation?: SkuLocation;
    quantity?: number;
    state?: ExpectedOutboundPickupState;
    destinationLocation?: WarehouseLocation;
    completedBy?: Identity;
}

export class OutboundQueueType {
    id?: number;
    name?: string;
}

export class OrderItem {
    id?: number;
    sku?: Sku;
    palette?: Palette;
    services?: ServiceItem[];
}

export class Order {
    id?: number;
    documentNumber?: string;
    customer?: Customer;
    items?: OrderItem[];
    state?: OrderState;
    createdAt?: Date;
    updatedAt?: Date;
}

export class PaletteType {
    id?: number;
    name?: string;
    namePattern?: string;
}

export class Palette {
    id?: number;
    number?: string;
    state?: PaletteState;
    location?: PaletteLocation;
    warehouseLocation?: WarehouseLocation;
    customerBranchOffice?: CustomerBranchOffice;
    createdAt?: Date;
    updatedAt?: Date;
}

export class ItemCodeType {
    id?: number;
    name?: string;
    systemName?: string;
}

export class ItemCode {
    id?: number;
    code?: string;
    type?: ItemCodeType;
}

export class ItemType {
    id?: number;
    name?: string;
    systemIdentificator?: string;
}

export class Item {
    id?: number;
    name?: string;
    codes?: ItemCode[];
    type?: ItemType;
    systemIdentificator?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export class OutboundRule {
    id?: number;
    name?: string;
    inventoryMoves?: InventoryMove[];
    priority?: number;
    cron?: string;
    runImmediately?: boolean;
    serviceTypes?: ServiceType[];
    services?: Service[];
    daysBeforeOutboundRequestDate?: number;
    scheduledTaskId?: number;
    createdAt?: Date;
    updatedAt?: Date;
}

export class ServiceItemBasic {
    id?: number;
}

export class ServiceItemInbound {
    id?: number;
    requestedDate?: Date;
    office?: CustomerBranchOffice;
}

export class ServiceItemOutbound {
    id?: number;
    requestedDate?: Date;
    office?: CustomerBranchOffice;
}

export class ServiceItemLoad {
    id?: number;
    requestedDate?: Date;
    quantity?: number;
}

export class ServiceItemUnload {
    id?: number;
    requestedDate?: Date;
    quantity?: number;
    branchOffice?: CustomerBranchOffice;
}

export class ServiceItem {
    id?: number;
    service?: Service;
    state?: ServiceItemState;
    doneAt?: Date;
    internalCode?: string;
    note?: string;
    data?: ServiceItemInbound | ServiceItemOutbound | ServiceItemLoad | ServiceItemUnload | ServiceItemBasic;
    createdAt?: Date;
    updatedAt?: Date;
    dataType?: ServiceItemDataGeneric;
}

export class Service {
    id?: number;
    name?: string;
    systemName?: string;
    code?: string;
    type?: ServiceType;
    onlyOneInActiveState?: boolean;
}

export class SkuLocation {
    id?: number;
    sku?: Sku;
    quantity?: number;
    warehouseLocation?: WarehouseLocation;
    palette?: Palette;
    createdAt?: Date;
    updatedAt?: Date;
}

export class Sku {
    id?: number;
    name?: string;
    product?: Item;
    masterCode?: string;
    codes?: ItemCode[];
    quantity?: number;
    owner?: Customer;
    supplier?: Customer;
    createdAt?: Date;
    updatedAt?: Date;
}

export class User {
    id?: number;
    name?: string;
    email?: string;
    warehouseLocationAlias?: WarehouseLocation;
    identity?: Identity;
}

export class WarehouseLocation {
    id?: number;
    name?: string;
    code?: string;
}

export class Activity {
    id?: number;
    type?: string;
    identity?: Identity;
    message?: string;
    modelName?: string;
    modelId?: number;
    createdAt?: Date;
}

export class WmsSettings {
    id?: number;
    preselectedInboundWarehouseLocation?: WarehouseLocation;
    preselectedOutboundWarehouseLocation?: WarehouseLocation;
}

export class InboundRequest {
    id?: number;
    canCancel?: boolean;
    isDone?: boolean;
    orderItemId?: number;
    serviceItemId?: number;
    expectedItemId?: number;
    requestedDate?: Date;
    createdAt?: Date;
}

export class OutboundRequest {
    id?: number;
    canCancel?: boolean;
    isDone?: boolean;
    orderItemId?: number;
    serviceItemId?: number;
    expectedItemId?: number;
    requestedDate?: Date;
    createdAt?: Date;
}

export class NumericSequence {
    id?: number;
    name?: string;
    prefix?: string;
    currentValue?: number;
    requestedLength?: number;
}

export class SkuMovement {
    id?: number;
    sku?: Sku;
    quantity?: number;
    skuDirectionType?: SkuDirectionType;
    documentNumber?: string;
    inventoryMove?: InventoryMove;
    expectedDocumentNumber?: string;
    sourceWarehouseLocation?: WarehouseLocation;
    destinationWarehouseLocation?: WarehouseLocation;
    paletteMoveId?: number;
    createdBy?: Identity;
    createdAt?: Date;
}

export class OutboundDocument {
    id?: number;
    documentNumber?: string;
    customer?: Customer;
    branchOffice?: CustomerBranchOffice;
    state?: OutboundDocumentState;
    items?: OutboundDocumentItem[];
    requestedDate?: Date;
    expectedOutboundDocumentNumber?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export class OutboundDocumentItem {
    id?: number;
    sku?: Sku;
    palette?: Palette;
    quantity?: number;
    warehouseLocation?: WarehouseLocation;
}

export class InboundDocument {
    id?: number;
    documentNumber?: string;
    customer?: Customer;
    state?: InboundDocumentState;
    items?: InboundDocumentItem[];
    createdAt?: Date;
    updatedAt?: Date;
}

export class InboundDocumentItem {
    id?: number;
    sku?: Sku;
    palette?: Palette;
    quantity?: number;
    warehouseLocation?: WarehouseLocation;
}

export class PrintReport {
    id?: number;
    label?: string;
    systemName?: string;
    type?: PrintReportType;
    template?: string;
}

export class InStoreMoveItem {
    id?: number;
    sku?: Sku;
    palette?: Palette;
    quantity?: number;
    state?: InStoreMoveItemState;
}

export class InStoreMove {
    id?: number;
    items?: InStoreMoveItem[];
    createdBy?: Identity;
    createdAt?: Date;
    updatedAt?: Date;
}

export class InventoryMove {
    id?: number;
    label?: string;
    systemName?: string;
    direction?: SkuDirectionType;
    sequence?: NumericSequence;
    defaultQuantity?: number;
    defaultLocation?: WarehouseLocation;
}

export class PaletteMovement {
    id?: number;
    palette?: Palette;
    sourceWarehouseLocation?: WarehouseLocation;
    destinationWarehouseLocation?: WarehouseLocation;
    createdBy?: Identity;
    createdAt?: Date;
}

export class PaletteDeliveryPickup {
    id?: number;
    documentNumber?: string;
    state?: PaletteDeliveryPickupState;
    paletteLocationDirection?: PaletteLocation;
    customerBranchOffice?: CustomerBranchOffice;
    palettes?: Palette[];
    createdBy?: Identity;
    createdAt?: Date;
    updatedAt?: Date;
}

export class Reservation {
    id?: number;
    clientName?: string;
    clientEmail?: string;
    clientPhone?: string;
    licencePlate?: string;
    note?: string;
}

export class Calendar {
    id?: number;
    name?: string;
    startTime?: string;
    endTime?: string;
    slotInterval?: string;
}

export class CalendarEvent {
    id?: number;
    calendar?: Calendar;
    title?: string;
    description?: string;
    dateTimeFrom?: Date;
    dateTimeTo?: Date;
    data?: Reservation;
    dataType?: CalendarEventDataGeneric;
}

export class ReservationRequest {
    id?: number;
    reservation?: Reservation;
    hasSets?: ReservationRequestHasSets;
    dimensions?: ReservationRequestDimensionSize;
    runflat?: boolean;
    selectedDate?: Date;
    selectedTime?: string;
}

export enum DepositState {
    Draft = "Draft",
    Created = "Created",
    InboundRequest = "InboundRequest",
    InboundProgress = "InboundProgress",
    Stored = "Stored",
    OutboundRequest = "OutboundRequest",
    OutboundReady = "OutboundReady",
    OutboundProgress = "OutboundProgress",
    Completed = "Completed",
    Canceled = "Canceled",
    Disposal = "Disposal"
}

export enum PaletteState {
    Free = "Free",
    Loaded = "Loaded"
}

export enum PaletteLocation {
    Warehouse = "Warehouse",
    Dealer = "Dealer"
}

export enum TireSeason {
    Summer = "Summer",
    Winter = "Winter",
    Allyear = "Allyear"
}

export enum TireVehiclePosition {
    FrontLeft = "FrontLeft",
    RearLeft = "RearLeft",
    FrontRight = "FrontRight",
    RearRight = "RearRight"
}

export enum WheelType {
    Alloy = "Alloy",
    Steel = "Steel"
}

export enum OutsourcingDocumentState {
    New = "New",
    Printed = "Printed",
    Done = "Done"
}

export enum OutsourcingItemState {
    New = "New",
    Resolved = "Resolved"
}

export enum TaskExecutionLog {
    All = "All",
    FailureOnly = "FailureOnly"
}

export enum TaskExecutionState {
    Waiting = "Waiting",
    Failed = "Failed",
    OK = "OK"
}

export enum OrderItemState {
    Created = "Created",
    InProgress = "InProgress",
    Complete = "Complete",
    Canceled = "Canceled"
}

export enum OrderState {
    Created = "Created",
    InProgress = "InProgress",
    Complete = "Complete",
    Canceled = "Canceled"
}

export enum ServiceItemState {
    Created = "Created",
    InProgress = "InProgress",
    Done = "Done",
    Canceled = "Canceled"
}

export enum ServiceType {
    Basic = "Basic",
    Outbound = "Outbound",
    Inbound = "Inbound",
    Load = "Load",
    Unload = "Unload",
    Delivery = "Delivery",
    Disposal = "Disposal"
}

export enum ExpectedInboundState {
    Created = "Created",
    InProgress = "InProgress",
    Completed = "Completed",
    CompletedWithError = "CompletedWithError",
    Canceled = "Canceled"
}

export enum ExpectedOutboundState {
    Created = "Created",
    InProgress = "InProgress",
    Completed = "Completed",
    CompletedWithError = "CompletedWithError",
    Canceled = "Canceled"
}

export enum SkuDirectionType {
    Inbound = "Inbound",
    Outbound = "Outbound",
    Move = "Move"
}

export enum PrintReportType {
    ExpectedInbound = "ExpectedInbound",
    InboundDocument = "InboundDocument",
    ExpectedOutbound = "ExpectedOutbound",
    OutboundDocument = "OutboundDocument",
    DepositLabel = "DepositLabel"
}

export enum OutboundDocumentState {
    Confirmed = "Confirmed",
    Canceled = "Canceled"
}

export enum InboundDocumentState {
    Confirmed = "Confirmed",
    Canceled = "Canceled"
}

export enum ExpectedOutboundItemState {
    Created = "Created",
    InProgress = "InProgress",
    Completed = "Completed",
    Canceled = "Canceled"
}

export enum ExpectedOutboundPickupState {
    Created = "Created",
    Loaded = "Loaded",
    Completed = "Completed"
}

export enum InStoreMoveItemState {
    Created = "Created",
    Loaded = "Loaded",
    Completed = "Completed"
}

export enum PaletteDeliveryPickupState {
    Created = "Created",
    InProgress = "InProgress",
    Completed = "Completed",
    Canceled = "Canceled"
}

export enum ReservationRequestHasSets {
    TireChange = "TireChange",
    SetChange = "SetChange"
}

export enum ReservationRequestDimensionSize {
    Tire18AndLower = "Tire18AndLower",
    Tire19AndGreater = "Tire19AndGreater"
}

export enum ServiceItemDataGeneric {
    Inbound = "Inbound",
    Outbound = "Outbound",
    Load = "Load",
    Unload = "Unload",
    Basic = "Basic"
}

export enum CalendarEventDataGeneric {
    Reservation = "Reservation"
}

export class GetDepositSummaryReturn {
    states?: {  name: string, count: number }[];
    seasons?: {  name: string, count: number }[];
}

export class GetDepositsInvoiceReturn {
    dealer?: Customer;
    rows?: {  document_number: string, palette_number: string, client_document_number: string, vehicle_license_plate: string, loaded_quantity: number, unloaded_quantity: number, stored_from_date: Date, inbound_quantity: number, outbound_quantity: number, stored_to_date: Date, day_count: number, quantity_to_195: number, quantity_from205_to255: number, quantity_from255: number, washing: number, balancing: number, total: number }[];
    total?: number;
}

export class GetDealerDepositGroupReturn {
    id?: number;
    name?: string;
    address?: string;
    states?: {  state: DepositState, count: number }[];
}

export class GetDepositServicesReturn {
    serviceItem?: ServiceItem;
    orderId?: number;
    skuId?: number;
}

export class GetDepositActivitiesReturn {
    id?: number;
    type?: string;
    identity?: Identity;
    message?: string;
    modelName?: string;
    modelId?: number;
    createdAt?: Date;
}

export class GetSkuServicesReturn {
    serviceItem?: ServiceItem;
    orderId?: number;
    skuId?: number;
}

export class GetInboundRequestsToResolveReturn {
    customerId?: number;
    requests?: {  requestId: number, skuId: number, paletteId: number, orderDocumentNumber: string, quantity: number, requestedDate: Date }[];
}

export class GetOutboundRequestsToResolveReturn {
    customerId?: number;
    branchOfficeId?: number;
    requestedDate?: Date;
    requests?: {  requestId: number, skuId: number, paletteId: number, orderDocumentNumber: string, quantity: number }[];
}

export class GetOrdersWithItemsAndServicesByExpectedInboundReturn {
    orderId?: number;
    inboundRequestId?: number;
    orderItemId?: number;
    serviceItemId?: number;
}

export class GetOrdersWithItemsAndServicesByExpectedOutboundReturn {
    orderId?: number;
    outboundRequestId?: number;
    orderItemId?: number;
    serviceItemId?: number;
}

export class DoFulltextSearchReturn {
    modelName?: string;
    modelId?: number;
    label?: string;
}

export class GetOutboundRequestsReturn {
    requestId?: number;
    customerId?: number;
    officeId?: number;
    serviceIds?: number[];
    serviceTypes?: ServiceType[];
    skuId?: number;
    skuName?: string;
    paletteId?: number;
    paletteNumber?: string;
    requestedDate?: Date;
    orderDocumentNumber?: string;
    quantity?: number;
    createdAt?: Date;
}

export class GetCalendarFreeSlotsProcedureReturn {
    slot?: Date;
}

export class GetCalendarAvailableSlotsProcedureReturn {
    slot?: Date;
    canReserve?: boolean;
}
