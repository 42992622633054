import { Box, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, IconButton, Icon } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { match } from 'react-router-dom';
import { httpGet, modelQueryUrl, httpPost, httpPostBlocking } from '../../api/http';
import { Activity, ExpectedInbound, ExpectedInboundItem, ExpectedInboundState } from '../../api/models';
import { localeFormatDateTime } from '../../providers/modal/locale';
import Preloader from '../../shared/Preloader';
import RemoteItems from '../../shared/RemoteItems';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';
import { setApplicationTitle, setPageMenu } from '../../providers/app';
import { translate } from '../../providers/locale';
import { green, orange, red } from '@material-ui/core/colors';
import browserHistory from '../../browserHistory';
import { openSnackbar } from '../../providers/modal';

const useStyles = makeStyles(theme => ({
	completedItem: {
		color: green[800]
	},
	uncompletedItem: {
		color: red[400]
	}
}));

const ExpectedInboundProcessPage: React.FC<{ match: match<any> }> = ({ match }) => {
	const classes = useStyles();
	const { result: expectedInbound } = useAsync(() => httpGet<ExpectedInbound>(`/expected-inbounds/${match.params.id}`), []);

	useEffect(() => {
		setApplicationTitle(expectedInbound?.documentNumber);

		setPageMenu([
			{
				label: 'Uzavřít doklad s chybou',
				icon: 'done_all',
				onClick: () => handleConfirm()
			}
		]);
	}, [expectedInbound])

	const handleConfirm = async () => {
		let result = await httpPostBlocking(`/wt/expected-inbounds/${expectedInbound.id}/confirm`);
		result && openSnackbar('success', 'Doklad byl uzavřen');
		result && browserHistory.push(result.navigateTo);
	};

	const openItem = async (item: ExpectedInboundItem) => {
		let result = await httpPost(`/wt/expected-inbounds/items/${item.id}/open`);
		result && browserHistory.push(result.navigateTo);
	};

	const hasCompletedAllItems = () => {
		return expectedInbound.items
			.every(item => item.state == ExpectedInboundState.Completed);
	};

	return (
		<Preloader loading={expectedInbound == null} content={() => (
			<SwitchBottomNavigation>
				<SwitchBottomNavigationCase label="Detail" icon="assignment">
					<Box p={1}>
						<Box mt={2}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>SKU</TableCell>
										<TableCell>Paleta</TableCell>
										{/* <TableCell>H</TableCell> */}
										<TableCell>Z</TableCell>
										<TableCell>Stav</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{expectedInbound.items
										.map(item => (
											<TableRow
												key={item.id}
												onClick={() => openItem(item)}>
												<TableCell classes={{
													root: (item.state == ExpectedInboundState.Completed ? classes.completedItem : classes.uncompletedItem)
												}}>{item.sku.name}</TableCell>
												<TableCell>{item.palette?.number}</TableCell>
												{/* <TableCell>{item.completeQuantity}</TableCell> */}
												<TableCell>{item.remainingQuantity}</TableCell>
												<TableCell>{translate(`${item.state}Short`)}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</Box>
						{hasCompletedAllItems() && (
							<Button variant="contained" color="primary" fullWidth={true} onClick={handleConfirm}>Uzavřít doklad</Button>
						)}
					</Box>
				</SwitchBottomNavigationCase>

				<SwitchBottomNavigationCase label="Události" icon="list">
					<List>
						<RemoteItems
							endpoint={modelQueryUrl<Activity>('/activities', {
								modelName: { equals: 'ExpectedInbound' },
								modelId: { equals: expectedInbound.id }
							})}
							each={(item: Activity) => (
								<ListItem button key={item.id}>
									<ListItemText primary={(
										<div>{localeFormatDateTime(item.createdAt)} | {item.identity.name}</div>
									)} secondary={item.message} />
								</ListItem>
							)} />
					</List>
				</SwitchBottomNavigationCase>
			</SwitchBottomNavigation>
		)}>
		</Preloader>
	);
}

export default ExpectedInboundProcessPage;
