import React from "react";
import { List, ListSubheader, ListItem, ListItemText } from "@material-ui/core";
import { checkRolePermissions } from "../providers/auth";

const TerminalMenu: React.FC<{
	handleMenuItemClick: any;
}> = ({ handleMenuItemClick }) => {
	return (
		<React.Fragment>
			<List subheader={<ListSubheader>Sklad</ListSubheader>} style={{ width: '60vw' }}>
				<ListItem button onClick={() => handleMenuItemClick('/info')}>
					<ListItemText primary="Info" />
				</ListItem>
				<ListItem button onClick={() => handleMenuItemClick('/inbound-queues')}>
					<ListItemText primary="Příjem" />
				</ListItem>
				<ListItem button onClick={() => handleMenuItemClick('/outbound-queues')}>
					<ListItemText primary="Výdej" />
				</ListItem>
				<ListItem button onClick={() => handleMenuItemClick('/move')}>
					<ListItemText primary="Přesun" />
				</ListItem>
			</List>
			{
				checkRolePermissions('deposits:driver') && (
					<List subheader={<ListSubheader>Uskladněnky</ListSubheader>} style={{ width: '60vw' }}>
						<ListItem button onClick={() => handleMenuItemClick('/deposits/outbound')}>
							{/* <ListItemIcon>
											<Icon>menu</Icon>
										</ListItemIcon> */}
							<ListItemText primary="Nakládka" />
						</ListItem>
						<ListItem button onClick={() => handleMenuItemClick('/deposits/delivery-pickup')}>
							<ListItemText primary="Svoz / Rozvoz" />
						</ListItem>
						<ListItem button onClick={() => handleMenuItemClick('/deposits/inbound')}>
							<ListItemText primary="Vykládka" />
						</ListItem>
						<ListItem button onClick={() => handleMenuItemClick('/palettes/delivery-pickups/new')}>
							<ListItemText primary="Rozvoz palet" />
						</ListItem>
					</List>
				)
			}
		</React.Fragment>
	)
};

export default TerminalMenu;
