import React, { useState, useEffect, useContext } from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading: React.FC<{
	loading: boolean;
	content: () => React.ReactElement;
}> = ({ loading, content }) => {
	return (
		loading ? (
			<CircularProgress></CircularProgress>
		) : (
				content()
			)
	);
}

export default Loading;
