import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { Link, match } from 'react-router-dom';
import { httpGet, modelQueryUrl } from '../../api/http';
import { SkuLocation, WarehouseLocation, Sku } from '../../api/models';
import { setApplicationTitle } from '../../providers/app';
import Preloader from '../../shared/Preloader';
import RemoteItems from '../../shared/RemoteItems';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';

const SkuDetailPage: React.FC<{ match: match<any> }> = ({ match }) => {
	const { result: sku, error } = useAsync(() => httpGet<Sku>(`/skus/${match.params.id}`), []);

	useEffect(() => {
		setApplicationTitle(`SKU ${sku?.name || ''}`);
	}, [sku]);

	return (
		<Preloader loading={sku == null} error={error} content={() => (
			<SwitchBottomNavigation>
				<SwitchBottomNavigationCase label="Detail" icon="assignment">
					<Box p={1}>
						<SummaryList>
							<SummaryListItem label="Kód">{sku.masterCode}</SummaryListItem>
							<SummaryListItem label="Název">{sku.name}</SummaryListItem>
							<SummaryListItem label="Produkt">{sku.product.name}</SummaryListItem>
							<SummaryListItem label="Množství">{sku.quantity}</SummaryListItem>
							<SummaryListItem label="Ukladatel">{sku.owner?.name}</SummaryListItem>
						</SummaryList>
					</Box>
				</SwitchBottomNavigationCase>

				<SwitchBottomNavigationCase label="Seznam umístění" icon="list">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Lokace</TableCell>
								<TableCell>Počet</TableCell>
								<TableCell>Paleta</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<RemoteItems
								endpoint={modelQueryUrl<SkuLocation>('/skus/locations', {
									sku: {
										id: { equals: sku.id }
									}
								})}
								each={(item: SkuLocation) => (
									<TableRow key={item.id}>
										<TableCell>
											<Link to={`/warehouse-locations/${item.warehouseLocation.id}`}>{item.warehouseLocation.code}</Link>
										</TableCell>
										<TableCell>{item.quantity}</TableCell>
										<TableCell>
											<Link to={`/palettes/${item.palette.id}`}>{item.palette?.number}</Link>
										</TableCell>
									</TableRow>
								)} />
						</TableBody>
					</Table>
				</SwitchBottomNavigationCase>
			</SwitchBottomNavigation >
		)}>
		</Preloader >
	);
}

export default SkuDetailPage;
