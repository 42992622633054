import { Checkbox, CheckboxProps, FormControl, FormGroup, FormControlLabel } from '@material-ui/core';
import React, { useContext } from 'react';
import { DataStateContext, DataState } from '../helpers/dataState';
import { useDataState } from '../helpers/dataStateHelper';

const StatedCheckbox: React.FC<CheckboxProps & {
	path: string;
	label: string;
	dataState?: DataState;
}> = ({ path, label, dataState, ...props }) => {
	dataState = useContext(DataStateContext) || dataState;
	const { value, invalid, help, setValue } = useDataState({ path: path, dataState: dataState });

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.checked);
	};

	return (
		<FormControl>
			<FormGroup>
				<FormControlLabel
					control={<Checkbox checked={value} {...props} onChange={handleChange} />}
					label={label} />
			</FormGroup>
		</FormControl>
	);
}

export default StatedCheckbox;
