import React from 'react';
import { SkuMovement } from '../../api/models';
import { translate } from '../../providers/locale';
import ModelRecordList from './ModelRecordList';

type ThisModel = SkuMovement;

const SkuMovementListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/skus/movements"
		title="Přehled pohybů skladových karet"
		columns={[
			{ label: 'SKU', get: x => x.sku.masterCode, width: 100 },
			{ label: 'Položka', get: x => x.sku.name, width: 100 },
			{ label: 'Množství', get: x => x.quantity, width: 100 },
			{ label: 'Směr', get: x => translate(x.skuDirectionType), width: 100 },
			{ label: 'Typ pohybu', get: x => x.inventoryMove?.label, width: 100 },
			{ label: 'Očekávaný doklad', get: x => x.expectedDocumentNumber, width: 100 },
			{ label: 'Doklad', get: x => x.documentNumber, width: 100 },
			{ label: 'Zdrojová lokace', get: x => x.sourceWarehouseLocation?.name, width: 100 },
			{ label: 'Cílová lokace', get: x => x.destinationWarehouseLocation?.name, width: 100 }
		]}
	/>;
}

export default SkuMovementListPage;
