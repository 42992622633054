import { Box, Checkbox, createStyles, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Toolbar, Typography, useTheme, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import VisibilitySensor from 'react-visibility-sensor';
import { filter as filterOp, map } from 'rxjs/operators';
import { httpGet } from '../../api/http';
import { getLocationQuery } from '../../browserHistory';
import { ItemList } from '../../helpers/itemList';
import { RefreshModel$ } from '../../providers/app';
import { toggleArrayValue, clone } from '../../utils';
import HeightToBottomArea from '../controls/HighToBottomArea';
import ListHeaderCheckbox from '../controls/ListHeaderCheckbox';
import SideDetail from '../controls/SideDetail';
import SideMultiActions, { ISideActionDefinition } from '../controls/SideMultiActions';
import ModelRecordListColumn, { IModelRecordListColumn } from './ModelRecordListColumn';
import * as objectPath from 'object-path';
import deepEqual from 'deep-equal';
import cleanDeep from 'clean-deep';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		filterLabel: {
			color: 'rgba(0, 0, 0, 0.87)',
			fontWeight: 500,
			lineHeight: '1.5rem',
			fontSize: '0.875rem'
		}
	}),
);

function handleFilteredValue(column: IModelRecordListColumn<any>, value: any) {
	console.log('filter change', column, value)
	let filterType = column.filterType || 'text';
	if (filterType == 'text') {
		return {
			contains: value
		};
	} else if (filterType == 'number') {
		return {
			equals: value
		};
	} else if (filterType == 'select') {
		return {
			equals: value
		};
	} else if (filterType == 'enum') {
		return {
			equals: value
		};
	}
}

function filterValueToText(column: IModelRecordListColumn<any>, filter: any) {
	if (column.filterPath) {
		let value = objectPath.get(filter, column.filterPath);

		if (value) {
			if (value.contains) {
				return value.contains;
			}
		}
	}

	return '';
}

export interface ModelRecordListProps<T extends any> {
	endpoint?: string;
	itemMap?: (item: T) => any;
	query?: any;
	title?: string;
	modelName?: string;
	buttons?: React.ReactElement[];
	multiActions?: {
		useLocalRecords?: boolean;
		itemLabel?(item: T): string;
		label(item: T): string;
		modelName?: string;
		actions: ISideActionDefinition[];
	};
	columns: IModelRecordListColumn<T>[];
	sideDetailComponent?(item: T): React.ReactElement;
}

function ModelRecordList<T extends any>({ endpoint, query, buttons, modelName, title, multiActions, columns, itemMap, sideDetailComponent }: ModelRecordListProps<T>) {
	const [itemList, setItemList] = useState(new ItemList(async (offset, payload) => {
		let items = await httpGet(endpoint, {
			...(query || {}),
			...payload,
			$offset: offset
		});

		if (itemMap) {
			items = items.map(itemMap);
		}

		return items;
	}));

	const theme = useTheme();
	const classes = useStyles();
	const location = useLocation();
	// const filter = useMemo(() => getLocationQuery(location), [location]);
	const [filter, setFilter] = useState(getLocationQuery(location));
	const [activeItem, setActiveItem] = useState<any>(null);
	const [firstLoading, setFirstLoading] = useState(false);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [refresh] = useObservable(() => {
		return RefreshModel$
			.pipe(filterOp(x => x.name == modelName));
	}, [], null);

	const isFiltered = useMemo(() => {
		let locationFilter = getLocationQuery(location);
		return deepEqual(locationFilter, filter) == false;
	}, [filter]);

	// const [items, setItems] = useObservable<T[]>(() => itemList.items, [location, refresh], []);
	// const [loading, setLoading] = useObservable<boolean>(() => itemList.loading, [location, refresh], true);
	const [items, setItems] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setFilter(getLocationQuery(location));
	}, [location]);

	useEffect(() => {
		itemList.items
			.subscribe(value => setItems(value));

		itemList.loading
			.subscribe(value => setLoading(value));
	}, [itemList]);

	useEffect(() => {
		console.log('refresh', filter)
		itemList.refresh(filter);
	}, [itemList, refresh, filter]);

	const onItemClick = async (itemId: number) => {
		setActiveItem(await httpGet(`${endpoint}/${itemId}`));
	};

	const onFilterChange = (column: IModelRecordListColumn<any>, value: any) => {
		let clonedFilter = clone(filter);

		if (value) {
			if (column.filterRaw) {
				objectPath.set(clonedFilter, column.filterPath, value);
			} else {
				objectPath.set(clonedFilter, column.filterPath, handleFilteredValue(column, value));
			}
		} else {
			objectPath.del(clonedFilter, column.filterPath);
		}

		clonedFilter = cleanDeep(clonedFilter) as any;
		setFilter(clonedFilter);
	};

	return (
		<React.Fragment>
			<Toolbar style={{ background: '#fafafa' }}>
				<Grid container>
					<Grid item>
						<Typography
							color="inherit"
							variant="subtitle1"
							style={{
								fontSize: '18px',
								fontWeight: 500,
								lineHeight: '58px'
							}}>{title}</Typography>
					</Grid>
					{buttons && buttons.map((button, index) => (
						<Grid key={index} style={{
							display: 'flex',
							alignItems: 'center'
						}} item>{button}</Grid>
					))}
					{
						isFiltered && (
							<Grid item style={{
								display: 'flex',
								alignItems: 'center',
								margin: '0 0 0 auto'
							}}>
								<Button variant="outlined" color="default" onClick={() => {
									setFilter(getLocationQuery(location));
								}}>Zrušit filtr</Button>
							</Grid>
						)
					}
				</Grid>
			</Toolbar>
			{
				firstLoading ? (
					<Box p={2}>
						<Skeleton variant="text" />
						<Skeleton variant="text" />
						<Skeleton variant="text" />
					</Box>
				) : (
						<HeightToBottomArea>
							<TableContainer>
								<Table stickyHeader={true} style={{ tableLayout: 'fixed' }}>
									<TableHead>
										<TableRow>
											{multiActions && (
												<TableCell padding="checkbox">
													<ListHeaderCheckbox
														loadedItems={items}
														endpoint={endpoint}
														filter={filter}
														selectedIds={[selectedIds, setSelectedIds]}
													/>
												</TableCell>
											)}
											{columns.map(column => (
												<ModelRecordListColumn filterValue={filterValueToText(column, filter)} onFilterChange={value => onFilterChange(column, value)} column={column} />
											))}
										</TableRow>
									</TableHead>
									<TableBody>
										{items && items.map(item => (
											<TableRow
												selected={activeItem?.id == item.id}
												key={item.id}
												hover
												onClick={(event) => {
													event.stopPropagation();
													onItemClick(item.id);
												}}>
												{multiActions && (
													<TableCell padding="checkbox">
														<Checkbox
															checked={selectedIds.indexOf(item.id) > -1}
															onClick={e => e.stopPropagation()}
															onChange={() => {
																setActiveItem(null);
																setSelectedIds(x => toggleArrayValue(x, item.id));
															}}
														/>
													</TableCell>
												)}
												{columns.map(column => (
													<TableCell
														key={`${item.id}.${column.label}`}
														style={{
															width: column.width,
															backgroundColor: column.backgroundColor && column.backgroundColor(item)
														}}>
														{column.get(item)}
													</TableCell>
												))}
											</TableRow>
										))}
									</TableBody>
								</Table>
								<VisibilitySensor
									active={loading == false}
									minTopValue={100}
									offset={{ bottom: -400 }}
									onChange={(state: boolean) => {
										if (state && itemList.getAllItems().length > 0) {
											itemList.next();
										}
									}}>
									<div style={{ height: '1px' }}></div>
								</VisibilitySensor>
							</TableContainer>
						</HeightToBottomArea>
					)
			}
			{
				sideDetailComponent && (
					<SideDetail
						title={activeItem?.documentNumber}
						open={[activeItem, setActiveItem]}
						content={() => sideDetailComponent(activeItem)}>
					</SideDetail>
				)
			}
			{
				multiActions && (
					<SideMultiActions<T>
						actions={multiActions.actions}
						label={multiActions.label}
						modelName={multiActions.modelName}
						ids={[selectedIds, setSelectedIds]}
						items={multiActions.useLocalRecords ? items : null}
						itemLabel={multiActions.itemLabel}
					></SideMultiActions>
				)
			}
		</React.Fragment>
	)
}

export default ModelRecordList;
