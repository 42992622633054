import MomentUtils from '@date-io/moment';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import 'moment/locale/cs';
import React, { useContext, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DateTimeControlType } from '../api/interfaces';
import { DataState, DataStateContext } from '../helpers/dataState';

export interface DateTimeProps {
	dataState?: DataState;
	path?: string;
	label?: string;
	type?: DateTimeControlType;
}

export function DateTime<T>({ dataState, label, path, type }: React.PropsWithChildren<DateTimeProps>) {
	const ref = Symbol();

	let [value, setValue] = useState<moment.Moment>(null);
	let [help, setHelp] = useState(null as any[] | null);
	let [invalid, setInvalid] = useState(false);

	type = type || DateTimeControlType.Date;
	dataState = useContext(DataStateContext) || dataState;

	useEffect(() => {
		const until = new Subject<void>();

		if (dataState) {
			dataState.pathChange
				.pipe(takeUntil(until), filter(event => event.sender != ref && event.path.startsWith(path)))
				.subscribe(event => {
					if (event.previousValue != event.currentValue) {
						setValue(dataState.get(path) || null);
					}
				});

			dataState.validationReset
				.pipe(takeUntil(until))
				.subscribe(() => {
					setInvalid(false);
					setHelp(null);
				});

			dataState.validationError
				.pipe(takeUntil(until), filter(event => event.path == path))
				.subscribe(error => {
					setInvalid(true);

					if (error.constraints) {
						const errors = error.constraints;
						setHelp(Object.keys(errors)
							.map((key: string) => <div key={key}>{errors[key]}</div>));
					}
				});

			setValue(dataState.get(path) || null);

			return () => {
				until.next();
				until.complete();
			};
		}
	}, [dataState]);

	const setVale = (value: moment.Moment) => {
		dataState.set(path, value.toDate(), {
			sender: ref
		});
	};

	const datePicker = <KeyboardDatePicker
		// disableToolbar
		variant="inline"
		format="D.M.YYYY"
		margin="normal"
		fullWidth={true}
		label={label}
		value={value}
		onChange={value => setValue(value)}
		inputVariant="outlined"
		InputLabelProps={{
			shrink: true
		}}
	/>;

	const timePicker = <KeyboardTimePicker
		margin="normal"
		variant="inline"
		label=" "
		fullWidth={true}
		value={value}
		onChange={value => setValue(value)}
		InputLabelProps={{
			shrink: true
		}}
	/>;

	let component: any;

	if (type == DateTimeControlType.Date) {
		component = datePicker
	} else if (type == DateTimeControlType.DateTime) {
		component = <Grid container spacing={2}>
			<Grid item xs={6}>
				{datePicker}
			</Grid>
			<Grid item xs={6}>
				{timePicker}
			</Grid>
		</Grid>
	} else if (type == DateTimeControlType.Time) {
		component = timePicker;
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			{component}
		</MuiPickersUtilsProvider>
	);
}

export default DateTime;
