import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Badge, Icon, Card, CardContent, Typography, CardActions, Button, Box, Divider } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

const DesktopNotificationIcon: React.FC<{

}> = ({ }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<React.Fragment>
			<IconButton
				color="inherit"
				onClick={() => setIsOpen(true)}
				edge="start">
				<Badge
					badgeContent={0}
					color="secondary">
					<Icon>notifications</Icon>
				</Badge>
			</IconButton>
			<Drawer
				anchor="right"
				open={isOpen}
				onClose={() => setIsOpen(false)}>{
					<Box p={2} style={{ width: '400px' }}>
						Žádné notifikace
					</Box>
				}
			</Drawer>
		</React.Fragment>
	);
}

export default DesktopNotificationIcon;
