import React, { useEffect, useState } from 'react';
import { httpGet } from '../../api/http';

const CronHuman: React.FC<{
	expression: string
}> = ({ expression }) => {
	const [text, setText] = useState('');

	useEffect(() => {
		if (expression) {
			httpGet(`/util/cron/human`, {
				expression
			}).then(result => setText(result));
		} else {
			setText('');
		}
	}, [expression]);

	return <React.Fragment>{text}</React.Fragment>;
}

export default CronHuman;
