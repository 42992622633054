import React from 'react';
import { Sku } from '../../api/models';
import DynamicButton from '../../controls/DynamicButton';
import ModelRecordList from './ModelRecordList';
import SkuDetail from './SkuDetail';
import SkuForm from './SkuForm';
import { modelQueryUrl, httpGet } from '../../api/http';

type ThisModel = Sku;

const SkuListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/skus"
		modelName="Sku"
		title="Přehled skladových karet"
		buttons={[
			(<DynamicButton variant="outlined" color="primary" label="Nová" openFullHeightDialog={{
				title: 'Nová skladová karta',
				component: () => <SkuForm />
			}} />)
		]}
		columns={[
			{ label: 'Kód', get: x => x.masterCode, width: 100, filterType: 'text', filterPath: 'masterCode' },
			{ label: 'Název', get: x => x.name, width: 100, filterPath: 'name', filterType: 'text' },
			{ label: 'Produkt', get: x => x.product?.name, width: 100, filterPath: 'item.name', filterType: 'text' },
			{ label: 'Množství', get: x => x.quantity, width: 100, filterPath: 'quantity', filterType: 'number' },
			{ label: 'Disponabilní množství', get: x => x.quantity, width: 100, filterPath: 'quantity', filterType: 'number' },
			{
				label: 'Ukladatel', get: x => x.owner?.name, width: 100, filterPath: 'owner.id', filterType: 'select', filterOptions: term => httpGet(modelQueryUrl(`/customers`, {
					name: {
						contains: term
					}
				}))
			}
		]}
		sideDetailComponent={item => <SkuDetail id={item.id} />}
	/>;
}

export default SkuListPage;
