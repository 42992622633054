import React, { useEffect, useState, useContext } from 'react';
import { List, ListItem, ListItemIcon, Icon, ListItemText, CircularProgress, Box } from '@material-ui/core';
import { ModalContext } from '../providers/modal';
import browserHistory from '../browserHistory';
import Alert from '@material-ui/lab/Alert';

const SuccessActionMenu: React.FC<{
	message?: string;
	endpoint?: any;
	redirect?: string;
	items?: any[];
}> = ({ message, endpoint, redirect, items }) => {
	const modal = useContext(ModalContext);

	const [loading, setLoading] = useState(false);

	return (
		<React.Fragment>
			<Alert severity="success">{message}</Alert>
			{
				loading ? (
					<Box p={2} display="flex">
						<CircularProgress style={{ margin: '0 auto'}} />
					</Box>
				) : (
						<List style={{ minHeight: '30vh' }}>
							{items.map(item => (
								<ListItem key={item.label} button onClick={async () => {
									if (item.onClick) {
										setLoading(true);
										try {
											await item.onClick();
										} catch (error) {

										} finally {
											setLoading(false);
										}
									}

									if (item.redirect) {
										browserHistory.push(item.redirect);
									} else {
										browserHistory.push(redirect);
									}

									// setTimeout(() => { // kvuli ripple efektu nad tlacitkem, ab ybylo jasno, na co se kliklo?
										modal().close();
									// }, 200);
								}}>
									{item.icon && (
										<ListItemIcon>
											<Icon>{item.icon}</Icon>
										</ListItemIcon>
									)}
									<ListItemText primary={item.label} />
								</ListItem>
							))}
						</List>
					)
			}
		</React.Fragment>
	);
}

export default SuccessActionMenu;
