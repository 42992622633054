import React, { useState, useEffect, useContext } from 'react';
import { DepositState } from '../../api/models';
import { translate } from '../../providers/locale';
import { green, purple, blue, yellow, orange } from '@material-ui/core/colors';
import { Color } from '@material-ui/lab/Alert';

export function getDepositStateColor(state: DepositState, contrast = 900) {
	if (state == DepositState.Completed) {
		return (green as any)[contrast];
	} else if (state == DepositState.Stored) {
		return (purple as any)[contrast]
	} else if (state == DepositState.InboundRequest || state == DepositState.InboundProgress) {
		return (yellow as any)[contrast]
	} else if (state == DepositState.OutboundReady || state == DepositState.OutboundRequest) {
		return (orange as any)[contrast]
	}
}

const DepositStateInline: React.FC<{
	state: DepositState
}> = ({ state }) => {
	let color = getDepositStateColor(state);
	return <span style={{ color }}>{translate(state)}</span>;
}

export default DepositStateInline;
