import React, { useState, useEffect, useContext } from 'react';
import SimpleDialog from './SimpleDialog';
import { DataState, DataStateContext } from '../../helpers/dataState';
import { DialogContent, DialogActions, Button } from '@material-ui/core';
import { PrimaryButton } from '../../shared/presets';
import Select from '../../controls/Select';
import { modelQueryUrl, httpGet, httpPost } from '../../api/http';
import { PrintReportType, PrintReport } from '../../api/models';
import { ModalContext } from '../../providers/modal';

const PrintDialog: React.FC<{
	reportType: PrintReportType;
	itemId: number;
}> = ({ reportType, itemId }) => {
	const modal = useContext(ModalContext);

	const [dataState, setDataState] = useState(new DataState());
	const [printing, setPrinting] = useState(false);

	useEffect(() => {
		let lastChoiceId = localStorage.getItem(`printDialog.lastChoice.${reportType}`);

		if (lastChoiceId) {
			httpGet(`/prints/reports/${lastChoiceId}`)
				.then(item => dataState.set('report', item));
		}
	}, []);

	const handlePrint = async () => {
		setPrinting(true);
		localStorage.setItem(`printDialog.lastChoice.${reportType}`, dataState.record.report.id.toString());

		await httpPost(`/print/report/${dataState.record.report.id}`, {
			itemId: itemId
		});

		setPrinting(false);
		modal().close();
	};

	return <React.Fragment>
		<DialogContent>
			<Select<PrintReport>
				label="Sestava"
				path="report"
				dataState={dataState}
				endpoint={term => modelQueryUrl(`/prints/reports`, {
					type: { equals: reportType }
				})}
				getOptionLabel={x => x.label} />
		</DialogContent>
		<DialogActions>
			<PrimaryButton disabled={printing} onClick={handlePrint}>Tisknout</PrimaryButton>
		</DialogActions>
	</React.Fragment>;
}

export default PrintDialog;
