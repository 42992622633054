import React from 'react';
import { Sku } from '../../api/models';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordList from './ModelRecordList';
import ModelRecordSideDetail from './ModelRecordSideDetail';

type ThisModel = any;

const TaskListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/tasks"
		title="Časované úlohy"
		columns={[
			{ label: 'Kód', get: x => x.name, width: 100 }
		]}
	/>;
}

export default TaskListPage;
