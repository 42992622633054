import { Box, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Paper, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { green, red } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { match } from 'react-router-dom';
import { httpGet, httpPost, modelQueryUrl, queryUrl, httpGetBlocking } from '../../api/http';
import { Activity, ExpectedOutbound, ExpectedOutboundItem, ExpectedOutboundState, ExpectedOutboundItemState, WarehouseLocation, ExpectedOutboundPickup, ExpectedOutboundPickupState } from '../../api/models';
import { setApplicationTitle, setPageMenu } from '../../providers/app';
import { translate } from '../../providers/locale';
import { localeFormatDateTime } from '../../providers/modal/locale';
import Preloader from '../../shared/Preloader';
import RemoteItems from '../../shared/RemoteItems';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';
import browserHistory from '../../browserHistory';
import { ScanTextBox } from '../../controls/base';
import TerminalLocationSelectIcon from './TerminalLocationSelectIcon';
import { openConfirm, openSnackbar, openSuccessActionMenu } from '../../providers/modal';
import { useObservable } from 'react-use-observable';
import { DataState } from '../../helpers/dataState';
import { D } from '../../debug';

const useStyles = makeStyles(theme => ({
	completedItem: {
		color: green[800]
	},
	uncompletedItem: {
		color: red[400]
	},
	locationSplit: {
		display: 'flex'
	}
}));

const ExpectedOutboundProcessPage: React.FC<{ match: match<any> }> = ({ match }) => {
	const classes = useStyles();
	const theme = useTheme();

	const { result: expectedOutbound, execute: refreshExpectedOutbound } = useAsync(() => httpGet<ExpectedOutbound>(`/expected-outbounds/${match.params.id}`), []);

	const [dataState, setDataState] = useState(new DataState<{ location: WarehouseLocation }>({
		location: null
	}));
	const [dataStateRecord] = useObservable(() => dataState.recordChange, []);

	useEffect(() => {
		setApplicationTitle(expectedOutbound?.documentNumber);

		if (expectedOutbound) {
			dataState.set('location', expectedOutbound.inventoryMove.defaultLocation);
		}

		setPageMenu([
			{
				label: 'Uzavřít doklad s chybou',
				icon: 'done_all',
				onClick: () => handleUnload()
			}
		]);
	}, [expectedOutbound])

	useEffect(() => {
		if (expectedOutbound) {
			let allItemsDone = expectedOutbound.items.every(x => x.state == ExpectedOutboundItemState.Completed);

			if (allItemsDone) {
				openConfirm('Uzavřít doklad?')
					.then(async r => {
						if (r) {
							let result = await httpGetBlocking(`/wt/expected-outbounds/${expectedOutbound.id}/process/step/confirm`);

							result && openSuccessActionMenu({
								message: 'Doklad byl uzavřen',
								redirect: '/',
								items: [
									{ label: 'Přejít na úvod', icon: 'home' },
									{
										label: 'Vytisknout doklad', icon: 'print', onClick: () => {
											return httpGet(`/wt/prints/expected-outbound/${expectedOutbound.id}/outbound-document?token=` + localStorage.getItem('jwt'))
										}
									}
								]
							}, null);
							// openSnackbar('success', 'Doklad byl uzavřen');
							// result && browserHistory.push(result.navigateTo);
						}
					});
			}
		}
	}, [expectedOutbound])

	const handleUnload = async () => {
		let result = await httpGet(`/wt/expected-outbounds/${expectedOutbound.id}/process/step/unload`, {
			locationId: dataStateRecord.location?.id
		});

		refreshExpectedOutbound();
	};

	const openItem = async (item: ExpectedOutboundItem) => {
		let result = await httpPost(`/wt/expected-outbounds/items/${item.id}/open`);
		result && browserHistory.push(result.navigateTo);
	};

	const getLoadedPickups = () => {
		let result: { item: ExpectedOutboundItem, pickup: ExpectedOutboundPickup }[] = [];

		expectedOutbound.items.forEach(item => {
			item.pickups.forEach(pickup => {
				if (pickup.state == ExpectedOutboundPickupState.Loaded) {
					result.push({ item, pickup });
				}
			});
		});

		return result;
	};

	return (
		<Preloader loading={expectedOutbound == null} content={() => (
			<SwitchBottomNavigation>
				<SwitchBottomNavigationCase label="Nezpracované" icon="money">
					<Box p={1} mt={2}>
						<ScanTextBox
							path="sku"
							label="Kód"
							// dataState={dataState}
							onConfirm={async item => {
								let confirmed = await openConfirm('', 'Potvrdit naložení');

								if (confirmed) {
									await httpGet(`/wt/expected-outbounds/${expectedOutbound.id}/process/step/loadItem`, {
										itemId: item.id
									});
									refreshExpectedOutbound();
								}
							}}
							viewportListen={true}
							endpoint={v => queryUrl(`/wt/expected-outbounds/${expectedOutbound.id}/process/step/scan`, {
								code: v
							})}></ScanTextBox>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>SKU</TableCell>
									<TableCell>Paleta</TableCell>
									<TableCell>Lokace</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{expectedOutbound.items
									.filter(item => item.state == ExpectedOutboundItemState.Created)
									.map(item => (
										<TableRow
											key={item.id}>
											<TableCell>{item.sku.name}{D(`(${item.sku?.id})`)}</TableCell>
											<TableCell>{item.palette?.number}{D(`(${item.palette?.id})`)}</TableCell>
											<TableCell>{item.sourceLocation?.code}{D(`(${item.sourceLocation?.id})`)}</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</Box>
				</SwitchBottomNavigationCase>

				<SwitchBottomNavigationCase badgeCount={getLoadedPickups().length} label="Naložené" icon="rv_hookup">
					<Box p={1} mt={2}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>SKU</TableCell>
									<TableCell>Paleta</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{getLoadedPickups()
									.map(loadedPickup => (
										<TableRow
											key={loadedPickup.pickup.id}>
											<TableCell>{loadedPickup.item.sku.name}</TableCell>
									<TableCell>{loadedPickup.item.palette?.number}{D(`(${loadedPickup.item.palette?.id})`)}</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
						<Box my={2}>
							<Paper elevation={0} classes={{ root: classes.locationSplit }}>
								<ScanTextBox
									path="location"
									optionLabel={x => x.code}
									label="Cílová lokace"
									dataState={dataState}
									viewportListen={true}
								// endpoint={v => queryUrl(`/wt/expected-inbounds/items/${data.item.id}/process/step/location`, {
								// 	code: v
								// })}
								></ScanTextBox>
								<TerminalLocationSelectIcon onConfirm={x => { }} />
							</Paper>
						</Box>
						<Button variant="contained" color="primary" fullWidth={true} onClick={handleUnload}>Vyložit</Button>
					</Box>
				</SwitchBottomNavigationCase>

				{/* <SwitchBottomNavigationCase label="Detail" icon="assignment">
					<Box p={1}>
						<Box mt={2}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>SKU</TableCell>
										<TableCell>Paleta</TableCell>
										<TableCell>Lokace</TableCell>
										<TableCell>Stav</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{expectedOutbound.items
										.map(item => (
											<TableRow
												key={item.id}
												onClick={() => openItem(item)}>
												<TableCell>{item.sku.name}</TableCell>
												<TableCell>{item.palette?.number}</TableCell>
												<TableCell>{item.sourceLocation?.name}</TableCell>
												<TableCell>{translate(`${item.state}Short`)}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</Box>
					</Box>
				</SwitchBottomNavigationCase> */}

				{/* <SwitchBottomNavigationCase label="Události" icon="list">
					<List>
						<RemoteItems
							endpoint={modelQueryUrl<Activity>('/activities', {
								modelName: { equals: 'ExpectedOutbound' },
								modelId: { equals: expectedOutbound.id }
							})}
							each={(item: Activity) => (
								<ListItem button key={item.id}>
									<ListItemText primary={(
										<div>{localeFormatDateTime(item.createdAt)} | {item.identity.name}</div>
									)} secondary={item.message} />
								</ListItem>
							)} />
					</List>
				</SwitchBottomNavigationCase> */}
			</SwitchBottomNavigation>
		)}>
		</Preloader>
	);
}

export default ExpectedOutboundProcessPage;
