import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';

const AlertDialog: React.FC<{
	message: string;
	onCancel: () => void;
}> = ({ onCancel, message }) => {
	return <Dialog open={true as any}
		fullWidth={true}
		maxWidth="sm"
		onClose={onCancel}>
		<DialogTitle>{"Informace"}</DialogTitle>
		<DialogContent>
			{message}
		</DialogContent>
		<DialogActions>
			<Button variant="text" onClick={onCancel}>OK</Button>
		</DialogActions>
	</Dialog>
}

export default AlertDialog;
