import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useState } from 'react';
import browserHistory from '../../browserHistory';
import { translate } from '../../providers/locale';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';
import { Box, Table, TableHead, TableRow, TableCell, TableBody, Paper, Button, useTheme, makeStyles, Typography } from '@material-ui/core';
import { ScanTextBox } from '../../controls/base';
import { openConfirm, openSnackbar } from '../../providers/modal';
import { httpGet, queryUrl, modelQueryUrl } from '../../api/http';
import { ExpectedOutboundItemState, Activity, ExpectedOutbound, WarehouseLocation, InStoreMove, Palette } from '../../api/models';
import classes from '*.module.css';
import TerminalLocationSelectIcon from './TerminalLocationSelectIcon';
import RemoteItems from '../../shared/RemoteItems';
import { localeFormatDateTime } from '../../providers/modal/locale';
import { useAsync } from 'react-async-hook';
import { match } from 'react-router-dom';
import { DataState } from '../../helpers/dataState';
import { useObservable } from 'react-use-observable';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
	completedItem: {
		color: green[800]
	},
	uncompletedItem: {
		color: red[400]
	},
	locationSplit: {
		display: 'flex'
	}
}));

const MovePalettePage: React.FC<{

}> = ({ }) => {
	const classes = useStyles();
	const theme = useTheme();

	const [inStoreMoveId, setInStoreMoveId] = useState(null);
	const { result: instoreMove, execute: refreshInstoreMove } = useAsync(() => {
		if (inStoreMoveId) {
			return httpGet<InStoreMove>(`/instore-moves/${inStoreMoveId}`)
		} else {
			return null;
		}
	}, [inStoreMoveId]);

	const [dataState, setDataState] = useState(new DataState<{ palette?: Palette, location?: WarehouseLocation }>({
		palette: null,
		location: null
	}));
	const [dataStateRecord] = useObservable(() => dataState.recordChange, []);

	const handleLoad = async () => {
		if (inStoreMoveId) {
			console.log('x', inStoreMoveId)
			let result = await httpGet(`/wt/instore-moves/process/step/load`, {
				id: inStoreMoveId,
				paletteId: dataStateRecord.palette.id
			});

			refreshInstoreMove();
			dataState.set('palette', null);
		} else {
			let result = await httpGet(`/wt/instore-moves/process/step/start`);
			console.log(result);

			let result2 = await httpGet(`/wt/instore-moves/process/step/load`, {
				id: result.id,
				paletteId: dataStateRecord.palette.id
			});

			setInStoreMoveId(result.id);
			// refreshInstoreMove();
			dataState.set('palette', null);
		}
	};

	const handleUnload = async () => {
		let result = await httpGet(`/wt/instore-moves/process/step/unload`, {
			id: inStoreMoveId,
			locationId: dataStateRecord.location.id
		});

		if (result) {
			openSnackbar('success', 'Přesun byl dokončen');
			browserHistory.push('/move');
		}
	};

	return dataStateRecord && (
		<SwitchBottomNavigation>
			<SwitchBottomNavigationCase label="Nezpracované" icon="money">
				<Box p={1} mt={2}>
					<ScanTextBox
						path="palette"
						label="Kód"
						dataState={dataState}
						onConfirm={async item => {

						}}
						viewportListen={true}
						resetOnConfirm={true}
						endpoint={v => queryUrl(`/wt/instore-moves/process/step/palette`, {
							code: v
						})}></ScanTextBox>

					<Box py={2}>Naskenovaná položka</Box>

					<SummaryList>
						<SummaryListItem label="Číslo palety">{dataStateRecord.palette?.number}</SummaryListItem>
						<SummaryListItem label="Lokace">{dataStateRecord.palette?.warehouseLocation?.code}</SummaryListItem>
					</SummaryList>

					<Box py={2}>
						<Button variant="contained" color="primary" fullWidth={true} onClick={handleLoad}>Naložit</Button>
					</Box>
				</Box>
			</SwitchBottomNavigationCase>

			<SwitchBottomNavigationCase badgeCount={instoreMove?.items.length} label="Naložené" icon="rv_hookup">
				<Box p={1} mt={2}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Paleta</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{instoreMove?.items
								.map(item => (
									<TableRow
										key={item.id}>
										<TableCell>{item.palette?.number}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Cílová lokace</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>{dataStateRecord.location?.code}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Box my={2}>
						<Paper elevation={0} classes={{ root: classes.locationSplit }}>
							<ScanTextBox
								path="location"
								optionLabel={x => x.code}
								label="Cílová lokace"
								endpoint={v => queryUrl(`/warehouses/locations`, {
									code: {
										equals: v
									}
								})}
								onConfirm={(v, setText) => {
									setText('')
								}}
								dataState={dataState}
								viewportListen={true}
							// endpoint={v => queryUrl(`/wt/expected-inbounds/items/${data.item.id}/process/step/location`, {
							// 	code: v
							// })}
							></ScanTextBox>
							<TerminalLocationSelectIcon onConfirm={x => dataState.set('location', x)} />
						</Paper>
					</Box>
					<Button variant="contained" color="primary" fullWidth={true} onClick={handleUnload}>Vyložit</Button>
				</Box>
			</SwitchBottomNavigationCase>
		</SwitchBottomNavigation>
	);
}

export default withRouteTitle(MovePalettePage, 'Přesun LP');
