import React from 'react';
import { PaletteMovement } from '../../api/models';
import ModelRecordList from './ModelRecordList';
import { localeFormatDateTime } from '../../providers/modal/locale';

type ThisModel = PaletteMovement;

const PaletteMovementListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/palettes/movements"
		title="Přehled pohybů palet"
		columns={[
			{ label: 'Paleta', get: x => x.palette.number, width: 100 },
			{ label: 'Zdrojová lokace', get: x => x.sourceWarehouseLocation?.name, width: 100 },
			{ label: 'Cílová lokace', get: x => x.destinationWarehouseLocation?.name, width: 100 },
			{ label: 'Kdy', get: x => localeFormatDateTime(x.createdAt), width: 100 }
		]}
	/>;
}

export default PaletteMovementListPage;
