import React from 'react';
import { OutboundRule } from '../../api/models';
import { translate } from '../../providers/locale';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordList from './ModelRecordList';
import ModelRecordSideDetail from './ModelRecordSideDetail';
import CronHuman from '../controls/CronHuman';

type ThisModel = OutboundRule;
const ModelName = 'OutboundRule';

const OutboundRuleListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/outbound/rules"
		title="Přehled pravidel výdeje"
		columns={[
			{ label: 'Název', get: x => x.name, width: 100 },
			{ label: 'Priorita', get: x => x.priority, width: 100 }
		]}
		sideDetailComponent={item => <ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					menuBar: [
						// (<DynamicButton label="Tisk" variant="contained" openDialog={{
						// 	title: 'Tisk',
						// 	options: { maxWidth: 'sm' },
						// 	component: () => <PrintDialog reportType={PrintReportType.OutboundDocument} itemId={item.id} />
						// }} />)
					],
					summaryList: [
						{ label: 'Priorita', get: x => x.priority },
						{ label: 'Spustit okamžitě', get: x => translate(x.runImmediately as any) },
						{ label: 'Plán', get: x => <CronHuman expression={x.cron} /> },
						{ label: 'Počet prac. dní před vyskladněním', get: x => x.daysBeforeOutboundRequestDate },
						{ label: 'Typy pohybů', get: x => x.inventoryMoves?.map(t => t.label).join(', ') },
						{ label: 'Typy služeb', get: x => x.serviceTypes?.map(st => translate(st)).join(', ') },
						{ label: 'Služby', get: x => x.services?.map(s => s.name).join(', ') }
					]
				}, {
					label: 'Události',
					content: item => <ModelActivityTable modelName={ModelName} modelId={item.id} />
				}
			]} />}
	/>;
}

export default OutboundRuleListPage;
