
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { TextFieldProps, TextField, List, ListItem, ListItemIcon, Radio, ListItemText, useTheme, Typography } from '@material-ui/core';
import { dataStated, useDataState } from '../helpers/dataStateHelper';
import { httpGet } from '../api/http';
import { openAlert, openModal, openFullHeightDialog } from '../providers/modal';
import { DataState } from '../helpers/dataState';

const TextBoxBase: React.FC<TextFieldProps> = (props) => {
	return <TextField
		variant="outlined"
		fullWidth={true}
		InputLabelProps={{
			shrink: true
		}} {...props}>
		{props.children}
	</TextField>
}

export const TextBox = dataStated(TextBoxBase);

const ScanBase: React.FC<{
	viewportListen?: boolean;
	value?: any;
	label?: string;
	endpoint?(value: string): string;
	error?: boolean;
	path?: string;
	options?: any[];
	getOptionSelected?: (option: any, term: string) => boolean;
	hideTextbox?: boolean;
	helperText?: string;
	resetOnConfirm?: boolean;
	dataState?: DataState,
	onTyped?: (value: string) => void;
	optionLabel?(item: any): string;
	overrideValueSet?(item: any, setTextValue: (text: string) => void): void;
	onConfirm?(item: any, setTextValue?: (text: string) => void): void;
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	TextFieldProps?: TextFieldProps;
}> = (props) => {
	const theme = useTheme();
	// const [value, setValue] = useState(null);
	const [textValue, setTextValue] = useState('');
	const { value, invalid, help, setValue } = useDataState({ path: props.path, dataState: props.dataState })

	const handleKeyPress = useCallback((event: KeyboardEvent) => {
		let char = String.fromCharCode(event.which);

		if (event.key != 'Enter') {
			setTextValue(v => v ? v + char : char);
		} else {
			setTextValue(current => {
				confirmScan(current);
				return current;
			});
		}
	}, [textValue, props.endpoint, props.options]);

	useEffect(() => {
		props.onTyped && props.onTyped(textValue);
	}, [textValue]);

	useEffect(() => {
		if (props.viewportListen) {
			window.addEventListener('keypress', handleKeyPress);
			return () => window.removeEventListener('keypress', handleKeyPress);
		}
	}, [props.endpoint, props.options]);

	useEffect(() => {
		if (props.optionLabel) {
			if (value) {
				setTextValue(props.optionLabel(value) || '');
			} else {
				setTextValue('');
			}
		}
	}, [value]);

	const onChange = (value: string) => {
		setTextValue(value);
	};

	const confirmScan = async (confirmValue = textValue) => {
		if (props.options) {
			let item = props.options.find(option => props.getOptionSelected(option, confirmValue));

			if (item) {
				props.onConfirm(item, setTextValue);
			} else {
				setTextValue('');
				openAlert('Položka nenalezena');
			}
		} else if (props.endpoint && confirmValue) {
			let result = await httpGet<any[]>(props.endpoint(confirmValue));

			if (result == null) {
				return;
			}

			if (result.length == 1) {
				let item = result[0];

				if (props.overrideValueSet) {
					props.overrideValueSet(item, setTextValue);
				} else {
					setValue(item);
					props.onConfirm && props.onConfirm(item, setTextValue);
					props.resetOnConfirm && setTextValue('');
				}
			} else if (result.length > 1) {
				const handler = openFullHeightDialog('Vyberte položku', (
					<List>
						{result.map(item => (
							<ListItem key={item.data} button onClick={() => {
								if (props.overrideValueSet) {
									props.overrideValueSet(item, setTextValue);
								} else {
									setValue(item);
									props.onConfirm && props.onConfirm(item, setTextValue);
									props.resetOnConfirm && setTextValue('');
								}

								handler.close();
							}}>
								<ListItemText>
									<small>{item.typeLabel}</small><br />
									{item.label}
								</ListItemText>
							</ListItem>
						))}
					</List>
				), theme);
			} else {
				setTextValue('');
				openAlert('Položka nenalezena');
			}
		} else if (props.dataState && props.path) {
			props.dataState.set(props.path, textValue);
			props.onConfirm && props.onConfirm(textValue, setTextValue);
		}
	};

	const onKeyPress = (key: string) => {
		if (key == 'Enter') {
			confirmScan();
		}
	};

	useEffect(() => {
		if (props.onChange) {
			props.onChange({
				currentTarget: {
					value: value
				}
			} as any);
		}
	}, [value])

	// useEffect(() => {
	// 	if (props.endpoint == null) {
	// 		setValue(textValue);
	// 	}
	// }, [textValue])

	if (props.hideTextbox) {
		return <div></div>;
	} else {
		return (<TextField
			InputLabelProps={{
				shrink: true
			}}
			variant="outlined"
			fullWidth={true}
			label={props.label}
			// autoFocus={true}
			{...props.TextFieldProps}
			error={props.error}
			onChange={event => onChange(event.currentTarget.value)}
			onKeyPress={event => {
				event.stopPropagation();
				onKeyPress(event.key);
			}}
			helperText={props.helperText}
			value={textValue}
		>
			{props.children}
		</TextField>
		);
	}
}

// export const ScanTextBox = dataStated(ScanBase);
export const ScanTextBox = ScanBase;
