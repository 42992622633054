import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { Link, match } from 'react-router-dom';
import { httpGet, modelQueryUrl } from '../../api/http';
import { SkuLocation, WarehouseLocation, Sku, Palette } from '../../api/models';
import { setApplicationTitle } from '../../providers/app';
import Preloader from '../../shared/Preloader';
import RemoteItems from '../../shared/RemoteItems';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';
import { translate } from '../../providers/locale';

const PaletteDetailPage: React.FC<{ match: match<any> }> = ({ match }) => {
	const { result: palette, error } = useAsync(() => httpGet<Palette>(`/palettes/${match.params.id}`), []);

	useEffect(() => {
		setApplicationTitle(`Paleta ${palette?.number || ''}`);
	}, [palette]);

	return (
		<Preloader loading={palette == null} error={error} content={() => (
			<SwitchBottomNavigation>
				<SwitchBottomNavigationCase label="Detail" icon="assignment">
					<Box p={1}>
						<SummaryList>
							<SummaryListItem label="Kód">{palette.number}</SummaryListItem>
							<SummaryListItem label="Stav">{translate(palette.state)}</SummaryListItem>
							<SummaryListItem label="Lokace">{palette.warehouseLocation?.code}</SummaryListItem>
							<SummaryListItem label="Umístění">{translate(palette.location)}</SummaryListItem>
							<SummaryListItem label="Externí umístění">{palette.customerBranchOffice?.name}</SummaryListItem>
						</SummaryList>
					</Box>
				</SwitchBottomNavigationCase>

				<SwitchBottomNavigationCase label="Seznam SKU" icon="list">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Lokace</TableCell>
								<TableCell>Počet</TableCell>
								<TableCell>SKU</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<RemoteItems
								endpoint={modelQueryUrl<SkuLocation>('/skus/locations', {
									palette: {
										id: { equals: palette.id }
									}
								})}
								each={(item: SkuLocation) => (
									<TableRow key={item.id}>
										<TableCell>
											<Link to={`/warehouse-locations/${item.warehouseLocation.id}`}>{item.warehouseLocation?.code}</Link>
										</TableCell>
										<TableCell>{item.quantity}</TableCell>
										<TableCell>
											<Link to={`/skus/${item.sku.id}`}>{item.sku?.masterCode}</Link>
										</TableCell>
									</TableRow>
								)} />
						</TableBody>
					</Table>
				</SwitchBottomNavigationCase>
			</SwitchBottomNavigation >
		)}>
		</Preloader >
	);
}

export default PaletteDetailPage;
