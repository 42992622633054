import Alert from '@material-ui/lab/Alert';
import browserHistory from '../browserHistory';
import React, { useState, useEffect } from 'react';
import { TextBox } from '../controls/base';
import { Button, Grid, Typography } from '@material-ui/core';
import { DataState } from '../helpers/dataState';
import { dataStated } from '../helpers/dataStateHelper';
import { httpPost } from '../api/http';
import { refreshIdentityFromToken } from '../providers/auth';
import { TERMINAL } from '../providers/app';

const LoginPage: React.FC<{

}> = ({ }) => {
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [dataState, setDataState] = useState(new DataState({
		username: '',
		password: '',
		accessToken: ''
	}));

	const handleLogin = async () => {
		setLoading(true);

		try {
			let result = await httpPost(`/identities/login`, dataState.record, {
				targetDataState: dataState
			});

			if (result?.token) {
				localStorage.setItem('jwt', result.token);
				refreshIdentityFromToken();
				browserHistory.push('/');
			}
		} catch (error) {
			setErrorMessage(error.response.data.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }} onSubmit={event => {
			event.preventDefault();
			handleLogin();
		}}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h5">Přihlášení</Typography>
				</Grid>
			</Grid>
			{
				errorMessage &&
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Alert severity="error">{errorMessage}</Alert>
					</Grid>
				</Grid>
			}
			{
				TERMINAL ? (
					<React.Fragment>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<TextBox label="PIN" path="accessToken" dataState={dataState}></TextBox>
							</Grid>
						</Grid>
					</React.Fragment>
				) : (
						<React.Fragment>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<TextBox label="E-mail" path="username" dataState={dataState}></TextBox>
								</Grid>
							</Grid>
							<Grid container spacing={4}>
								<Grid item xs={12}>
									<TextBox label="Heslo" type="password" path="password" dataState={dataState}></TextBox>
								</Grid>
							</Grid>
						</React.Fragment>
					)
			}
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Button variant="contained" color="primary" size="large" type="submit" fullWidth={true}>Přihlásit se</Button>
				</Grid>
			</Grid>
		</form>
	);
}

export default LoginPage;
