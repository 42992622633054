import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { httpGet, httpPost } from '../../api/http';
import { ExpectedInbound, ExpectedInboundState } from '../../api/models';
import DynamicButton from '../../controls/DynamicButton';
import { RefreshModel$ } from '../../providers/app';
import ExpectedInboundStateInline, { getExpectedInboundStateColor } from '../controls/ExpectedInboundStateInline';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordSideDetail from './ModelRecordSideDetail';

type ThisModel = ExpectedInbound;
const ModelName = 'ExpectedInbound';

const ExpectedInboundDetail: React.FC<{
	id: number
}> = ({ id }) => {
	const [item, setItem] = useState(null);

	useEffect(() => {
		refresh();

		const sub = RefreshModel$
			.subscribe(() => refresh());

		return () => sub.unsubscribe();
	}, [id]);

	const refresh = () => {
		httpGet(`/expected-inbounds/${id}`)
			.then(setItem);
	};

	return item && (
		<ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					menuBar: [
						(<DynamicButton
							label="Storno"
							variant="outlined"
							disabled={item.state != ExpectedInboundState.Created}
							confirm
							onClick={async () => {
								await httpPost(`/wt/expected-inbounds/${item.id}/cancel`);
								RefreshModel$.next({ name: ModelName, id: item.id });
							}} />)
					],
					summaryList: [
						{ label: 'Číslo dokladu', get: x => x.documentNumber },
						{ label: 'Stav', get: x => <ExpectedInboundStateInline state={x.state} /> }
					],
					content: x => (
						<Box p={2}>
							<Paper>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>SKU</TableCell>
											<TableCell>Stav</TableCell>
											<TableCell>Zpracováno</TableCell>
											<TableCell>Zbývá</TableCell>
											<TableCell>Očekáváno</TableCell>
											<TableCell>Paleta</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{x.items.map(item => (
											<TableRow hover key={item.id}>
												<TableCell>{item.sku.name}</TableCell>
												<TableCell style={{ background: getExpectedInboundStateColor(item.state, 50) }}>
													<ExpectedInboundStateInline state={item.state} />
												</TableCell>
												<TableCell>{item.completeQuantity}</TableCell>
												<TableCell>{item.remainingQuantity}</TableCell>
												<TableCell>{item.expectedQuantity}</TableCell>
												<TableCell>{item.palette?.number}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Paper>
						</Box>
					)
				}, {
					label: 'Události',
					content: item => <ModelActivityTable modelName="ExpectedInbound" modelId={item.id} />
				}
			]} />
	);
}

export default ExpectedInboundDetail;
