import { useTheme } from '@material-ui/core';
import React from 'react';
import { Palette, PaletteLocation, PaletteState } from '../../api/models';
import { translate } from '../../providers/locale';
import { openFullHeightDialog } from '../../providers/modal';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordList from './ModelRecordList';
import ModelRecordSideDetail from './ModelRecordSideDetail';
import PaletteDeliveryPickupForm from './PaletteDeliveryPickupForm';
import { httpGet, modelQueryUrl } from '../../api/http';
import DynamicButton from '../../controls/DynamicButton';
import DepositForm from './DepositForm';
import PaletteForm from './PaletteForm';
import PaletteDetail from './PaletteDetail';

type ThisModel = Palette;
const ModelName = 'Palette';

const PaletteListPage: React.FC<{}> = ({ }) => {
	const theme = useTheme();

	return <ModelRecordList<ThisModel>
		endpoint="/palettes"
		modelName={ModelName}
		title="Palety"
		columns={[
			{ label: 'Číslo palety', get: x => x.number, width: 100, filterType: 'text', filterPath: 'number' },
			{
				label: 'Lokace', get: x => x.warehouseLocation?.name, width: 100, filterType: 'enum', filterPath: 'warehouseLocation.id',
				filterOptions: term => httpGet(modelQueryUrl(`/warehouses/locations`, {
					name: {
						starts: term
					}
				}))
			},
			{
				label: 'Stav', get: x => translate(x.state), width: 100, filterType: 'enum', filterPath: 'state',
				filterOptions: async term => Object.keys(PaletteState).map(key => ({ name: translate(key), id: key }))
			},
			{
				label: 'Umístění', get: x => translate(x.location), width: 100, filterType: 'enum', filterPath: 'location',
				filterOptions: async term => Object.keys(PaletteLocation).map(key => ({ name: translate(key), id: key }))
			},
			{
				label: 'Externí umístění', get: x => x.customerBranchOffice?.name, width: 100, filterType: 'enum', filterPath: 'customerBranchOffice.id',
				filterOptions: term => httpGet(modelQueryUrl(`/customers/branch-offices`, {
					name: {
						contains: term
					}
				}))
			}
		]}
		multiActions={{
			label: x => `${x.number}`,
			modelName: 'Palette',
			actions: [
				{
					label: 'Vytvořit změnu umístění', onClick: async ids => {
						openFullHeightDialog('Nová změna umístění', <PaletteDeliveryPickupForm paletteIds={ids} />, theme);
					}, batch: true
				}
			]
		}}
		sideDetailComponent={item => <PaletteDetail id={item.id} />}
	/>;
}

export default PaletteListPage;
