import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Icon, useTheme, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@material-ui/core';
import { openModal, ModalContext, openDialog, openFullHeightDialog } from '../providers/modal';
import SimpleDialog from './controls/SimpleDialog';
import { useDebounce } from '@react-hook/debounce';
import TextField from '@material-ui/core/TextField';
import { httpGet } from '../api/http';
import { translate } from '../providers/locale';
import ContentSwitch, { ContentSwitchCase } from '../shared/ContentSwitch';
import browserHistory from '../browserHistory';
import DepositStateInline from './controls/DepositStateInline';
import DepositDetail from './pages/DepositDetail';
import SkuDetail from './pages/SkuDetail';
import SideDetail from './controls/SideDetail';

const DesktopSearchDialog: React.FC<{

}> = ({ }) => {
	const modal = useContext(ModalContext);
	const theme = useTheme();

	const [searchValue, setSearchValue] = useDebounce('', 300);
	const [foundItems, setFoundItems] = useState([]);
	const [sideDetailComponent, setSideDetailComponent] = useState(null);
	const [activeItem, setActiveItem] = useState(null);

	useEffect(() => {
		if (searchValue && searchValue.length > 2) {
			onSearch(searchValue);
		} else {
			setFoundItems([]);
		}
	}, [searchValue]);

	const onSearch = async (value: string) => {
		let results = await httpGet(`/search/do`, {
			term: value
		});

		setFoundItems(results);
	}

	const goTo = async (fi: any) => {
		if (fi.modelName == 'Deposit') {
			// openFullHeightDialog('Uskladněnka', <DepositDetail id={fi.modelId} />, theme);
			setSideDetailComponent(<DepositDetail id={fi.modelId} />);
		} else if (fi.modelName == 'Sku') {
			// openFullHeightDialog('Skladová karta', <SkuDetail id={fi.modelId} />, theme);
			setSideDetailComponent(<SkuDetail id={fi.modelId} />);
		}

		// modal().close();
	}

	return (
		<SimpleDialog
			title="Vyhledávání"
			DialogProps={{
				fullScreen: true
			}}>
			<TextField
				InputLabelProps={{
					shrink: true
				}}
				placeholder="Zadejte hledaný text"
				variant="outlined"
				autoFocus={true}
				fullWidth={true}
				onChange={event => setSearchValue(event.currentTarget.value)}
			/>
			<Table style={{ tableLayout: 'fixed' }}>
				<TableHead>
					<TableRow>
						<TableCell style={{ width: '200px' }}>Typ</TableCell>
						<TableCell>Položka</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{foundItems.map((fi: any) => (
						<TableRow hover key={`${fi.modelName}.${fi.modelId}`} onClick={() => goTo(fi)}>
							<TableCell>{translate(fi.modelName)}</TableCell>
							<TableCell>
								<ContentSwitch condition={fi.modelName}>
									<ContentSwitchCase value="Deposit" content={() => (
										<React.Fragment>
											{fi.label} <DepositStateInline state={fi.data.state} />
										</React.Fragment>
									)}>
									</ContentSwitchCase>
									<ContentSwitchCase value="Sku" content={() => (
										<React.Fragment>
											{fi.label}
										</React.Fragment>
									)}>
									</ContentSwitchCase>
								</ContentSwitch>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{
				sideDetailComponent && (
					<SideDetail
						title={''}
						open={[sideDetailComponent, () => {
							setSideDetailComponent(null);
							modal().close();
						}]}
						content={() => sideDetailComponent}>
					</SideDetail>
				)
			}
		</SimpleDialog>
	);
}

const DesktopSearchIcon: React.FC<{

}> = ({ }) => {
	const theme = useTheme();

	return (
		<React.Fragment>
			<IconButton
				color="inherit"
				onClick={() => {
					openModal((<DesktopSearchDialog />), theme)
				}}
				edge="start">
				<Icon>search</Icon>
			</IconButton>
		</React.Fragment>
	)
}

export default DesktopSearchIcon;
