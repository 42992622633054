import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import React, { Children, useState } from 'react';
import ContentSwitch, { ContentSwitchCase } from './ContentSwitch';
import { Icon, Badge } from '@material-ui/core';

export const SwitchBottomNavigation: React.FC<{
	children: any[] // SwitchBottomNavigationCase[]
}> = ({ children }) => {
	const [index, setIndex] = useState(0);

	return <React.Fragment>
		<div style={{ paddingBottom: '76px' }}>
			<ContentSwitch condition={index}>
				{Children
					.map(children, (child: SwitchBottomNavigationCase, childIndex) => (
						<ContentSwitchCase value={childIndex}>
							{child}
						</ContentSwitchCase>
					))}
			</ContentSwitch>
		</div>
		<BottomNavigation
			showLabels
			value={index}
			style={{ position: 'fixed', bottom: 0, width: '100%' }}
			onChange={(event, value) => setIndex(value)}>
			{Children
				.map(children, (child: SwitchBottomNavigationCase, childIndex) => {
					let content = (
						typeof child.props.icon == 'string' ?
							<Icon>{child.props.icon}</Icon>
							: child.props.icon
					);

					if (child.props.badgeCount > 0) {
						content = <Badge color="secondary" badgeContent={child.props.badgeCount}>{content}</Badge>;
					}

					return (
						<BottomNavigationAction label={child.props.label} icon={content} value={childIndex}></BottomNavigationAction>
					);
				})}
		</BottomNavigation>
	</React.Fragment>
}

export class SwitchBottomNavigationCase extends React.Component<any, any> {
	render() {
		return this.props.children;
	}
}
