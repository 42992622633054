import React from 'react';
import { ExpectedOutbound } from '../../api/models';
import { localeFormatDate } from '../../providers/modal/locale';
import ExpectedOutboundStateInline, { getExpectedOutboundStateColor } from '../controls/ExpectedOutboundStateInline';
import ExpectedOutboundDetail from './ExpectedOutboundDetail';
import ModelRecordList from './ModelRecordList';

type ThisModel = ExpectedOutbound;

const ExpectedOutboundListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/expected-outbounds"
		title="Přehled očekávaných výdejů"
		columns={[
			{ label: 'Číslo dokladu', get: x => x.documentNumber, width: 100 },
			{ label: 'Stav', get: x => <ExpectedOutboundStateInline state={x.state} />, backgroundColor: x => getExpectedOutboundStateColor(x.state, 50), width: 100 },
			{ label: 'Typ pohybu', get: x => x.inventoryMove?.label, width: 100 },
			{ label: 'Požadavaný termín', get: x => localeFormatDate(x.requiredDate), width: 100 },
			{ label: 'Pobočka', get: x => x.branchOffice?.name, width: 100 },
			{ label: 'Počet položek', get: x => x.items.length, width: 100 },
			{ label: 'Celkové množství', get: x => x.items.reduce((total, current) => total + current.expectedQuantity, 0), width: 100 },
			{ label: 'Ukladatel', get: x => x.customer?.name, width: 100 }
		]}
		sideDetailComponent={item => <ExpectedOutboundDetail id={item.id} />}
	/>;
}

export default ExpectedOutboundListPage;
