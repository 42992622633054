import { useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { httpGet } from '../../api/http';
import { Palette } from '../../api/models';
import DynamicButton from '../../controls/DynamicButton';
import { RefreshModel$ } from '../../providers/app';
import { translate } from '../../providers/locale';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordSideDetail from './ModelRecordSideDetail';
import PaletteForm from './PaletteForm';

type ThisModel = Palette;
const ModelName = 'Palette';

const PaletteDetail: React.FC<{
	id: number
}> = ({ id }) => {
	const theme = useTheme();
	const [item, setItem] = useState<ThisModel>(null);

	useEffect(() => {
		refresh();

		const sub = RefreshModel$
			.subscribe(() => refresh());

		return () => sub.unsubscribe();
	}, [id]);

	const refresh = () => {
		httpGet(`/palettes/${id}`)
			.then(setItem);
	};

	return item && (
		<ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					menuBar: [
						(<DynamicButton label="Opravit" color="primary" variant="outlined" openFullHeightDialog={{
							title: item.number,
							component: () => <PaletteForm id={item.id} />
						}} />)
					],
					summaryList: [
						{ label: 'Číslo palety', get: x => x.number },
						{ label: 'Stav', get: x => translate(x.state) },
						{ label: 'Lokace', get: x => x.warehouseLocation?.code },
						{ label: 'Umístění', get: x => translate(x.location) },
						{ label: 'Pobočka', get: x => x.customerBranchOffice?.name },
					]
				}, {
					label: 'Události',
					content: item => <ModelActivityTable modelName="Palette" modelId={item.id} />
				}
			]} />
	);
}

export default PaletteDetail;
