import { useAsync } from "react-async-hook";
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { httpGet } from "../api/http";
import Chip from '@material-ui/core/Chip';

const RemoteChip: React.FC<{
	endpoint: string;
}> = ({ endpoint }) => {
	const { result: value, loading } = useAsync(() => httpGet<any>(endpoint), []);

	return loading ? <CircularProgress /> : <Chip label={value}></Chip>;
}

export default RemoteChip;
