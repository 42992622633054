import { Button, DialogActions, DialogContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState, useContext } from 'react';
import { httpGet, httpPost, modelQueryUrl } from '../../api/http';
import { TextBox } from '../../controls/base';
import { DataState, DataStateContext } from '../../helpers/dataState';
import DateTime from '../../controls/DateTime';
import { Item, Sku } from '../../api/models';
import Select from '../../controls/Select';
import { ModalContext, openSnackbar } from '../../providers/modal';
import { RefreshModel$ } from '../../providers/app';

type ThisModel = Sku;
const ModelName = 'Sku';

const SkuForm: React.FC<{
	id?: number;
	onSuccess?(item: ThisModel): void;
}> = ({ id, onSuccess }) => {
	const modal = useContext(ModalContext);

	const [dataState, setDataState] = useState(new DataState());
	const [item, setItem] = useState(null);

	useEffect(() => {
		if (id) {
			httpGet(`/skus/${id}`)
				.then(data => {
					setItem(data);
					dataState.setRecord(data);
				}, error => {
					// TODO
				});
		}
	}, [id]);

	const handleSubmit = async () => {
		let result = await httpPost<ThisModel>(`/skus${id ? `/${id}` : ''}`, dataState.record, {
			targetDataState: dataState
		});

		if (result) {
			RefreshModel$.next({ name: ModelName, id: result.id });
			openSnackbar('success', 'Skladová karta uložena');
			modal().close();
			onSuccess && onSuccess(result);
		}
	};

	return (
		<DataStateContext.Provider value={dataState}>
			<DialogContent>
				<Grid container>
					<Grid item sm={4}>
						<Select<Item>
							label="Produkt"
							path="product"
							InputProps={{
								autoFocus: true
							}}
							endpoint={term => modelQueryUrl(`/items`, {
								name: { contains: term }
							})}
							getOptionLabel={x => x.name}></Select>
					</Grid>
					<Grid item sm={4}>
						<TextBox label="Kód" path="masterCode"></TextBox>
					</Grid>
					<Grid item sm={4}>
						<TextBox label="Název" path="name"></TextBox>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item sm={4}>
						<Select<Item>
							label="Ukladatel"
							path="owner"
							endpoint={term => modelQueryUrl(`/customers`, {
								name: { contains: term }
							})}
							getOptionLabel={x => x.name}></Select>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button size="large" variant="contained" color="primary" onClick={handleSubmit}>Uložit</Button>
			</DialogActions>
		</DataStateContext.Provider>
	)
}

export default SkuForm;
