import { Box, Paper, Typography, Stepper, Step, StepLabel, StepContent, CircularProgress, IconButton, Icon, makeStyles, Theme, createStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async-hook';
import { match } from 'react-router-dom';
import { httpGet, modelQueryUrl, httpPost, queryUrl } from '../../api/http';
import { Activity, ExpectedInbound, WarehouseLocation, ExpectedInboundItem, Palette, Sku, InventoryMove } from '../../api/models';
import { localeFormatDateTime } from '../../providers/modal/locale';
import Preloader from '../../shared/Preloader';
import RemoteItems from '../../shared/RemoteItems';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';
import { translate } from '../../providers/locale';
import Grid from '@material-ui/core/Grid';
import { ScanTextBox, TextBox } from '../../controls/base';
import { setApplicationTitle } from '../../providers/app';
import { DataState } from '../../helpers/dataState';
import { useObservable, useRetryableObservable } from 'react-use-observable';
import { deepClone } from 'fast-json-patch/module/core';
import ExpectedInboundStateInline from '../../desktop/controls/ExpectedInboundStateInline';
import { getExpectedInboundStateColor } from '../../desktop/controls/ExpectedInboundStateInline';
import TerminalLocationSelectIcon from './TerminalLocationSelectIcon';
import browserHistory from '../../browserHistory';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		locationSplit: {
			display: 'flex'
		}
	}),
);

interface IData {
	expectedInbound: ExpectedInbound;
	location: WarehouseLocation;
	item: ExpectedInboundItem;
}

interface IDataState {
	location?: WarehouseLocation;
	sku?: Sku;
	palette?: Palette;
	quantity?: number;
}

const ExpectedInboundProcessItemPage: React.FC<{ match: match<any> }> = ({ match }) => {
	const classes = useStyles();
	const { result: data, loading } = useAsync(() => httpGet<IData>(`/wt/expected-inbounds/items/${match.params.itemId}`), []);
	const [activeStep, setActiveStep] = useState(0);
	const [dataStateRecord] = useObservable(() => dataState.recordChange, []);
	const [dataState, setDataState] = useState(new DataState<IDataState>({
		quantity: 0
	}));

	useEffect(() => {
		if (data) {
			if (data.expectedInbound.inventoryMove.defaultQuantity) {
				dataState.set('quantity', data.expectedInbound.inventoryMove.defaultQuantity);
			}

			if (data.expectedInbound.inventoryMove.defaultLocation) {
				dataState.set('location', data.expectedInbound.inventoryMove.defaultLocation);
			}
		}
	}, [data]);

	useEffect(() => {
		setApplicationTitle(`Příjem ${data?.item.sku.name || ''}`);
	}, [data]);

	useEffect(() => {
		const sub = dataState.pathChange
			.subscribe(change => {
				// if (change.path == 'sku' && change.currentValue) {
				// 	setActiveStep(1);
				// } else if (change.path == 'palette' && change.currentValue) {
				// 	setActiveStep(2);
				// } else if (change.path == 'quantity' && change.currentValue) {
				// 	setActiveStep(3);
				// } else if (change.path == 'location' && change.currentValue) {

				// }
			});

		return () => sub.unsubscribe();
	}, []);

	const nextStep = (current: number) => {
		let stop = false;
		[dataStateRecord.sku, dataStateRecord.palette, dataStateRecord.quantity, dataStateRecord.location]
			.forEach((value, index) => {
				if (stop) {
					return;
				}

				if (index > current && !value) {
					setActiveStep(index);
					stop = true;
				}
			});

		if (stop == false) {
			setActiveStep(-1);
		}
	};

	const handleConfirm = async () => {
		let result = await httpPost(`/wt/expected-inbounds/items/${data.item.id}/confirm`, dataState.record);
		result && browserHistory.push(result.navigateTo);
	};

	return loading ? (
		<CircularProgress />
	) : (
			<div>
				<SwitchBottomNavigation>
					<SwitchBottomNavigationCase label="Detail" icon="assignment">
						<Box p={1}>
							<SummaryList>
								<SummaryListItem label="Doklad">{data.expectedInbound.documentNumber}</SummaryListItem>
								<SummaryListItem label="SKU">{data.item.sku?.name}</SummaryListItem>
								<SummaryListItem label="Paleta">{data.item.palette?.number}</SummaryListItem>
								<SummaryListItem label="Zbývá">{data.item.remainingQuantity}</SummaryListItem>
								<SummaryListItem label="Hotovo">{data.item.completeQuantity}</SummaryListItem>
							</SummaryList>
							<Stepper activeStep={activeStep} orientation="vertical">
								<Step completed={dataStateRecord.sku != null}>
									<StepLabel onClick={() => setActiveStep(0)}>SKU {dataState.record.sku?.masterCode}</StepLabel>
									<StepContent>
										<ScanTextBox
											path="sku"
											dataState={dataState}
											onConfirm={() => nextStep(0)}
											viewportListen={true}
											endpoint={v => queryUrl(`/wt/expected-inbounds/items/${data.item.id}/process/step/sku`, {
												code: v
											})}></ScanTextBox>
									</StepContent>
								</Step>
								<Step completed={dataStateRecord.palette != null}>
									<StepLabel onClick={() => setActiveStep(1)}>Paleta {dataState.record.palette?.number}</StepLabel>
									<StepContent>
										<ScanTextBox
											path="palette"
											dataState={dataState}
											onConfirm={() => nextStep(1)}
											viewportListen={true}
											endpoint={v => queryUrl(`/wt/expected-inbounds/items/${data.item.id}/process/step/palette`, {
												code: v
											})}></ScanTextBox>
									</StepContent>
								</Step>
								<Step completed={dataStateRecord.quantity != null}>
									<StepLabel onClick={() => setActiveStep(2)}>Množství {dataStateRecord.quantity}</StepLabel>
									<StepContent>
										<TextBox path="quantity" autoFocus dataState={dataState}></TextBox>
									</StepContent>
								</Step>
								<Step completed={dataStateRecord.location != null}>
									<StepLabel onClick={() => setActiveStep(3)}>Lokace {dataStateRecord.location?.code}</StepLabel>
									<StepContent>
										<Paper elevation={0} classes={{ root: classes.locationSplit }}>
											<ScanTextBox
												path="location"
												dataState={dataState}
												viewportListen={true}
												endpoint={v => queryUrl(`/wt/expected-inbounds/items/${data.item.id}/process/step/location`, {
													code: v
												})}></ScanTextBox>
											<TerminalLocationSelectIcon onConfirm={x => dataState.set('location', x)} />
										</Paper>
									</StepContent>
								</Step>
							</Stepper>
							<Button variant="contained" color="primary" fullWidth={true} onClick={handleConfirm}>Potvrdit položku</Button>
						</Box>
					</SwitchBottomNavigationCase>

					<SwitchBottomNavigationCase label="Události" icon="list">
						<List>
							<RemoteItems
								endpoint={modelQueryUrl<Activity>('/activities', {
									modelName: { equals: 'ExpectedInboundItem' },
									modelId: { equals: data.item.id }
								})}
								each={(item: Activity) => (
									<ListItem key={item.id}>
										<ListItemText primary={(
											<div>{localeFormatDateTime(item.createdAt)} | {item.identity.name}</div>
										)} secondary={translate(item.message)} />
									</ListItem>
								)} />
						</List>
					</SwitchBottomNavigationCase>
				</SwitchBottomNavigation>
			</div>
		);
}

export default ExpectedInboundProcessItemPage;
