import React, { useState, useEffect, useContext, SelectHTMLAttributes } from 'react';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { dataStated, StatedComponentProps } from '../helpers/dataStateHelper';
import { DataState, DataStateContext } from '../helpers/dataState';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { filter } from 'rxjs/internal/operators/filter';
import { useDebounce } from '@react-hook/debounce';
import { tap } from 'rxjs/operators';
import { httpGet } from '../api/http';
import { InputProps } from '@material-ui/core/Input/Input';
import { translate } from '../providers/locale';

export function Select<T>({
	items,
	label,
	dataState,
	getOptionSelected,
	getOptionLabel,
	endpoint,
	onValue,
	InputProps,
	path
}: React.PropsWithChildren<{
	getOptionSelected?: (option: T, value: T) => boolean;
	getOptionLabel: (option: T) => string;
	items?: T[];
	path?: string;
	label?: string;
	endpoint?: (term?: string) => string;
	dataState?: DataState;
	InputProps?: InputProps;
	AutocompleteProps?: AutocompleteProps<T>;
	onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	onValue?: (value: T) => void;
}>) {
	const ref = Symbol();
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState(items || []);
	const [loading, setLoading] = useState(false);
	let [value, setValue] = useState<T>(null);
	let [help, setHelp] = useState(null as any[] | null);
	let [invalid, setInvalid] = useState(false);
	let [inputValue, setInputValue] = useDebounce(null, 300);

	dataState = useContext(DataStateContext) || dataState;
	getOptionSelected = getOptionSelected || ((option: any, value: any) => option.id == value.id);

	useEffect(() => {
		refreshItems(inputValue);
	}, [items, inputValue, endpoint]);

	useEffect(() => {
		const until = new Subject<void>();

		if (dataState) {
			dataState.pathChange
				.pipe(takeUntil(until), filter(event => event.sender != ref && event.path.startsWith(path)))
				.subscribe(event => {
					if (event.previousValue != event.currentValue) {
						setValue(dataState.get(path) || null);
					}
				});

			dataState.validationReset
				.pipe(takeUntil(until))
				.subscribe(() => {
					setInvalid(false);
					setHelp(null);
				});

			dataState.validationError
				.pipe(takeUntil(until), filter(event => event.path == path))
				.subscribe(error => {
					setInvalid(true);

					if (error.constraints) {
						const errors = error.constraints;
						setHelp(Object.keys(errors)
							.map((key: string) => <div key={key}>{errors[key]}</div>));
					}
				});

			setValue(dataState.get(path) || null);

			return () => {
				until.next();
				until.complete();
			};
		}
	}, [dataState]);

	const refreshItems = async (inputValue?: string) => {
		if (items) {
			setOptions(items);
		} else {
			let result = await httpGet(endpoint(inputValue || null));
			setOptions(result);
		}
	};

	const handleChange = (value: T) => {
		if (onValue) {
			onValue(value);
		}

		if (dataState) {
			dataState.set(path, value, {
				sender: ref
			});
		} else {
			setValue(value);
		}
	};

	return <Autocomplete<T>
		open={open}
		onOpen={() => setOpen(true)}
		onClose={() => setOpen(false)}
		getOptionSelected={getOptionSelected}
		getOptionLabel={getOptionLabel}
		loading={loading}
		options={options}
		value={value}
		autoHighlight={true}
		onInputChange={(event: any, value: string) => setInputValue(value)}
		onChange={(event: any, value: T) => handleChange(value)}
		renderInput={params => (
			<TextField
				{...params}
				label={label}
				variant="outlined"
				margin="dense"
				error={invalid}
				helperText={help}
				InputLabelProps={{
					shrink: true
				}}
				InputProps={{
					...params.InputProps,
					...InputProps,
					endAdornment: (
						<React.Fragment>
							{loading ? <CircularProgress color="inherit" size={20} /> : null}
							{params.InputProps.endAdornment}
						</React.Fragment>
					),
				}}
			/>
		)} />
}

export default Select;
