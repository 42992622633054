import React from 'react';
import { Order } from '../../api/models';
import { translate } from '../../providers/locale';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordList from './ModelRecordList';
import ModelRecordSideDetail from './ModelRecordSideDetail';

type ThisModel = Order;

const OrderListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/orders"
		title="Objednávky"
		columns={[
			{ label: 'Číslo dokladu', get: x => x.documentNumber, width: 100 },
			{ label: 'Stav', get: x => translate(x.state), width: 100 },
			{ label: 'Zákazník', get: x => x.customer?.name, width: 100 },
			{ label: 'Počet položek', get: x => x.items.length, width: 100 }
		]}
		sideDetailComponent={item => <ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					summaryList: [
						{ label: 'Číslo dokladu', get: x => x.documentNumber },
						{ label: 'Stav', get: x => translate(x.state) }
					]
				}, {
					label: 'Události',
					content: item => <ModelActivityTable modelName="Order" modelId={item.id} />
				}
			]} />}
	/>;
}

export default OrderListPage;
