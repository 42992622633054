import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, GridList, GridListTile, useTheme, Icon, MenuItem, Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { httpGet, serverUrl, httpPost, httpPostBlocking } from '../../api/http';
import { Deposit, GetDepositServicesReturn, ServiceItem, ServiceItemDataGeneric, ServiceItemInbound, ServiceItemOutbound, DepositState, ServiceItemState } from '../../api/models';
import DynamicButton from '../../controls/DynamicButton';
import DynamicTable from '../../controls/DynamicTable';
import { RefreshModel$, emitRefreshModel } from '../../providers/app';
import { translate } from '../../providers/locale';
import { localeFormatDate, localeFormatDateTime } from '../../providers/modal/locale';
import DepositStateInline from '../controls/DepositStateInline';
import ModelActivityTable from '../controls/ModelActivityTable';
import ServiceItemStateInline, { getServiceItemStateColor } from '../controls/ServiceItemStateInline';
import DepositForm from './DepositForm';
import ModelRecordSideDetail from './ModelRecordSideDetail';
import { openModal, openAlert, openSnackbar } from '../../providers/modal';
import DepositDisposeDialog from './DepositDisposeDialog';
import ComponentMenu from '../controls/ComponentMenu';
import { D } from '../../debug';
import OrderForm from './OrderForm';

type ThisModel = Deposit;
const ModelName = 'Deposit';

const DepositDetail: React.FC<{
	id: number
}> = ({ id }) => {
	const theme = useTheme();
	const [item, setItem] = useState<ThisModel>(null);

	useEffect(() => {
		refresh();

		const sub = RefreshModel$
			.subscribe(() => refresh());

		return () => sub.unsubscribe();
	}, [id]);

	const refresh = () => {
		httpGet(`/deposits/${id}`)
			.then(setItem);
	};

	const cancelService = (x: GetDepositServicesReturn) => {
		httpPostBlocking(`/wms/skus/${x.skuId}/services/${x.serviceItem.id}/cancel`)
			.then(() => {
				openSnackbar('success', `Služba byla stornována`);
				refresh();
			});
	};

	return item && (
		<ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					menuBar: [
						(<DynamicButton label="Opravit" color="primary" variant="outlined" openFullHeightDialog={{
							title: item.documentNumber,
							component: () => <DepositForm id={item.id} />
						}} />),
						(<DynamicButton disabled={canDisposeDeposit(item) == false} label="Likvidace" color="primary" variant="outlined" onClick={async () => {
							openModal(<DepositDisposeDialog depositId={item.id} />, theme)
						}} />),
						(<DynamicButton disabled={canCancelDeposit(item) == false} label="Stornovat" color="primary" variant="outlined" confirm onClick={async () => {
							await httpPostBlocking(`/deposit-terminal-controller/${item.id}/cancel`);
							emitRefreshModel(ModelName, item.id);
						}} />)
					],
					summaryList: [
						{ label: 'Číslo uskladněnky', get: x => `${x.documentNumber}${D(`(${x.id}), SKU(${x.sku?.id})`)}` },
						{
							label: 'Stav', get: x => (
								<React.Fragment>
									<DepositStateInline state={x.state} />
									{
										// x.state == DepositState.OutboundRequest && (
										// 	<Button size="small" style={{ marginLeft: '15px' }} onClick={async () => {
										// 		let result = await httpPostBlocking(`/wms/outbound/requests/force`, {
										// 			ids: [x.id]
										// 		});

										// 		result && openSnackbar('success', 'Očekávaný výdej vytvořen');
										// 	}}>Vyskladnit ihned</Button>
										// )
									}
								</React.Fragment>
							)
						},
						{ label: 'Paleta', get: x => x.palette?.number }
					],
					content: x => (
						<Box p={2}>
							<Paper>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Pneumatika</TableCell>
											<TableCell>Sezóna</TableCell>
											<TableCell>Dezén</TableCell>
											<TableCell>Pozice</TableCell>
											<TableCell>Disk</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{x.items.map(item => (
											<TableRow key={item.id}>
												<TableCell>{item.name}</TableCell>
												<TableCell>{translate(item.season)}</TableCell>
												<TableCell>{item.depth}</TableCell>
												<TableCell>{translate(item.vehiclePosition)}</TableCell>
												<TableCell>{translate(item.wheelType)}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Paper>
						</Box>
					)
				}, {
					label: 'Služby',
					// menuBar: [
					// 	(<DynamicButton label="Objednat služby" color="primary" variant="outlined" openFullHeightDialog={{
					// 		title: item.documentNumber,
					// 		component: () => <OrderForm skus={[item.sku]} />
					// 	}} />)
					// ],
					content: item => (
						<DynamicTable<GetDepositServicesReturn> definition={{
							endpoint: `/deposits/${item.id}/services`,
							columns: [
								{
									label: '', width: 10, get: x => (
										<ComponentMenu component={(
											<Icon>menu</Icon>
										)}>
											{D(`ServiceItem(${x.serviceItem.id})`)}
											{x.serviceItem.state == ServiceItemState.Created && (
												<MenuItem onClick={() => cancelService(x)}>Zrušit službu</MenuItem>
											)}
										</ComponentMenu>
									)
								},
								{ label: 'Služba', width: 100, get: x => x.serviceItem.service.name },
								{
									label: 'Stav',
									width: 100,
									get: x => (
										<ServiceItemStateInline state={x.serviceItem.state} />
									),
									background: x => getServiceItemStateColor(x.serviceItem.state, 50)
								},
								{
									label: 'Detail', width: 100, get: x => renderServiceItemDetail(x.serviceItem)
								},
								{ label: 'Dokončeno', width: 100, get: x => localeFormatDateTime(x.serviceItem.doneAt) }
							]
						}}></DynamicTable>
					)
				}, {
					label: 'Události',
					content: item => <ModelActivityTable endpoint={`/deposits/${item.id}/activities`} />
				}, {
					label: 'Foto',
					content: item => (
						<GridList cellHeight={320} cols={2}>
							{item.photos.map(photo => (
								<GridListTile key={photo.id}>
									<img src={serverUrl(`/cdn/${photo.filename}`)} style={{ cursor: 'pointer' }} onClick={() => window.open(serverUrl(`/cdn/${photo.filename}`))} />
								</GridListTile>
							))}
						</GridList>
					)
				}
			]}
		/>
	);
}

export default DepositDetail;

function renderServiceItemDetail(serviceItem: ServiceItem) {
	if (serviceItem.data) {
		if (serviceItem.dataType == ServiceItemDataGeneric.Outbound) {
			return (
				<React.Fragment>
					{localeFormatDate((serviceItem.data as ServiceItemOutbound).requestedDate)}<br />{(serviceItem.data as ServiceItemOutbound).office?.name}
				</React.Fragment>
			);
		} else if (serviceItem.dataType == ServiceItemDataGeneric.Inbound) {
			return (
				<React.Fragment>
					{localeFormatDate((serviceItem.data as ServiceItemInbound).requestedDate)}<br />{(serviceItem.data as ServiceItemInbound).office?.name}
				</React.Fragment>
			);
		}
	}
}

function canDisposeDeposit(deposit: Deposit) {
	return deposit.state != DepositState.Completed &&
		deposit.state != DepositState.Canceled &&
		deposit.state != DepositState.Disposal;
}

function canCancelDeposit(deposit: Deposit) {
	return deposit.state == DepositState.Created ||
		deposit.state == DepositState.InboundRequest;
}
