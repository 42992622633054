import axios from 'axios';
import qs from 'qs';
import { handleServerInvalidResponse, handleServerResponse, WARNING_CONFIRMATION, setGlobalLoading } from '../providers/app';
import { DataState } from '../helpers/dataState';
import { openOverlayLoading } from '../providers/modal';
import { useTheme } from '@material-ui/core';

export const API_URL = process.env.REACT_APP_API_URL;

export interface HttpRequestOptions {
	query?: any;
	targetDataState?: DataState;
	headers?: any;
}

export async function httpGetBlocking<T = any>(url: string, query?: any, options: HttpRequestOptions = {}): Promise<T> {
	let handle = openOverlayLoading(null);

	try {
		let result = await httpGet<T>(url, query, options);
		handle();
		return result;
	} catch (error) {
		handle();
		throw error;
	}
}

export async function httpGet<T = any>(url: string, query?: any, options: HttpRequestOptions = {}) {
	try {
		// setGlobalLoading(true);
		let result = await axios.get(`${API_URL}${url}`, {
			params: query,
			paramsSerializer: q => qs.stringify(q, {
				skipNulls: true,
				indices: true,
				encode: false
			}),
			headers: getDefaultHeaders()
		});

		// setGlobalLoading(false);
		return result.data as T;
	} catch (error) {
		handleHttpError(error, options || {});
	}
}

export async function httpUpload<T = any>(url: string, formData: FormData, options: HttpRequestOptions = {}): Promise<T> {
	return httpPost(url, formData, {
		...options,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}

export async function httpPostBlocking<T = any>(url: string, body?: any, options: HttpRequestOptions = {}): Promise<T> {
	let handle = openOverlayLoading(null);

	try {
		let result = await httpPost<T>(url, body, options);
		handle();
		return result;
	} catch (error) {
		handle();
		throw error;
	}
}

export async function httpPost<T = any>(url: string, body?: any, options: HttpRequestOptions = {}): Promise<T> {
	let headers: any = options.headers || {};
	let token = localStorage.getItem('jwt');

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	try {
		let result = await axios.post(`${API_URL}${url}`, body, {
			params: options.query,
			headers: headers
		});

		let data = result.data as T & { $action?: any };

		if (data.$action) {
			handleServerResponse(data.$action);
		}

		return data;
	} catch (error) {
		let handledResult = await handleHttpError(error, options || {});

		// TODO: Typed
		if (handledResult?.handle) {
			handledResult.handle.close$
				.subscribe(result => {
					if (result == WARNING_CONFIRMATION) {
						httpPost<T>(url, body, {
							query: {
								$confirmation: true
							}
						});
					}
				});
		} else {
			return null;
		}

		// // TODO: Network error
		// throw new HttpError(error.message, error.response.status, error.response.data);
	}
}

export function modelQueryUrl<T>(url: string, query: any) {
	return `${url}?${qs.stringify(query, {
		skipNulls: true,
		indices: true,
		encode: false
	})}`;
}

export function serverUrl(url: string) {
	return `${API_URL}${url}`;
}

export function queryUrl(url: string, query: any) {
	return `${url}?${qs.stringify(query, {
		skipNulls: true,
		indices: true,
		encode: false
	})}`;
}

export class HttpError extends Error {
	constructor(message: string, public status: number, public serverError: any) {
		super(message);
	}
}

function handleHttpError(error: any, options: HttpRequestOptions) {
	return handleServerInvalidResponse(error, options);

	// throw new HttpError(error.message, error.response.status, error.response.data);
}

function getDefaultHeaders() {
	let headers: any = {};
	let token = localStorage.getItem('jwt');

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	return headers;
}
