import { Button, DialogContent, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState, useContext } from 'react';
import { httpGet, httpPost, modelQueryUrl } from '../../api/http';
import { TextBox } from '../../controls/base';
import { DataState, DataStateContext } from '../../helpers/dataState';
import DateTime from '../../controls/DateTime';
import { CustomerBranchOffice, Deposit } from '../../api/models';
import { Select } from '../../controls/Select';
import { RefreshModel$ } from '../../providers/app';
import { openSnackbar, ModalContext } from '../../providers/modal';

type ThisModel = Deposit;
const ModelName = 'Deposit';

const DepositForm: React.FC<{
	id: number
}> = ({ id }) => {
	const modal = useContext(ModalContext);

	const [dataState, setDataState] = useState(new DataState());
	const [item, setItem] = useState(null);

	useEffect(() => {
		httpGet(`/deposits/${id}`)
			.then(data => {
				setItem(data);
				dataState.setRecord(data);
			}, error => {
				// TODO
			});
	}, [id]);

	const handleSubmit = async () => {
		let result = await httpPost(`/deposits/${id}`, dataState.record, {
			targetDataState: dataState
		});

		if (result) {
			RefreshModel$.next({ name: ModelName, id: result.id });
			openSnackbar('success', 'Skladová karta uložena');
			modal().close();
		}
	};

	return (
		<DataStateContext.Provider value={dataState}>
			<DialogContent>

				<Grid container>
					<Grid item sm={6}>
						<TextBox label="Číslo uskladněnky" path="documentNumber" dataState={dataState}></TextBox>
					</Grid>
					<Grid item sm={6}>
						<TextBox label="Číslo dealera" path="customerDocumentNumber" dataState={dataState}></TextBox>
					</Grid>
				</Grid>
				<Grid container>
					{/* <Grid item sm={6}>
					<Select<Palette>
						label="Paleta"
						path="palette"
						endpoint={term => modelQueryUrl(`/palettes`, {
							number: { contains: term }
						})}
						getOptionLabel={x => x.number}></Select>
				</Grid> */}
					<Grid item sm={6}>
						<TextBox label="Vozidlo" path="vehicle.name" dataState={dataState}></TextBox>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item sm={6}>
						<TextBox label="SPZ" path="vehicle.licensePlate" dataState={dataState}></TextBox>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item sm={6}>
						{/* <TextBox label="Pobočka" path="office.id" dataState={dataState}></TextBox> */}
						<Select<CustomerBranchOffice>
							label="Pobočka"
							path="office"
							endpoint={term => modelQueryUrl(`/customers/branch-offices`, {
								number: { contains: term }
							})}
							getOptionLabel={x => x.name}></Select>
					</Grid>
					<Grid item sm={6}>
						<Grid container>
							<Grid item sm={6}>
								<TextBox label="VIN" path="vehicle.vin010" dataState={dataState}></TextBox>
							</Grid>
							<Grid item sm={6}>
								<TextBox label="VIN (posledních 7)" path="vehicle.vin7" dataState={dataState}></TextBox>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item sm={6}>
						{/* <TextBox label="Uskladněno" path="storedAt" dataState={dataState}></TextBox> */}
						<DateTime label="Uskladněno" path="storedAt"></DateTime>
					</Grid>
					<Grid item sm={6}>
						<TextBox label="Zákazník" path="client" dataState={dataState}></TextBox>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="contained" onClick={() => handleSubmit()}>Potvrdit</Button>
			</DialogActions>
		</DataStateContext.Provider>
	)
}

export default DepositForm;
