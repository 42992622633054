import React, { useState, useEffect, useContext } from 'react';
import { Paper, Box, makeStyles, Theme, createStyles, Divider, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		toolbar: {
			// backgroundColor: grey[100]
		}
	}),
);

const MenuBar: React.FC<{}> = ({ children }) => {
	const classes = useStyles();

	return (
		<Paper style={{ background: '#fafafa', borderRadius: 0, boxShadow: 'none' }} classes={{ root: classes.toolbar }}>
			<Box p={2}>
				<Grid container spacing={2}>
					{React.Children.map(children, (child, index) => (
						<Grid key={index} item>{child}</Grid>
					))}
				</Grid>
			</Box>
		</Paper>
	)
}

export default MenuBar;
