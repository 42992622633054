import { Box, Divider, Typography, Grid, makeStyles, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { match } from 'react-router-dom';
import { modelQueryUrl } from '../../api/http';
import { InboundQueueType, ExpectedInbound } from '../../api/models';
import browserHistory from '../../browserHistory';
import RemoteItems from '../../shared/RemoteItems';
import { ExpectedInboundIncompleteStates } from '../../services/expectedInboundService';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import { translate } from '../../providers/locale';
import { green, orange } from '@material-ui/core/colors';
import ExpectedInboundStateInline from '../../desktop/controls/ExpectedInboundStateInline';

const InboundQueueDetailPage: React.FC<{
	match: match<{ queueId: string }>
}> = ({ match }) => {
	const tableCellStyle = {
		padding: '1px',
		fontSize: '11px',
		borderBottom: '1px solid rgba(224, 224, 224, 0.27)'
	};

	return <List component="nav">
		<RemoteItems
			endpoint={modelQueryUrl('/expected-inbounds', {
				state: {
					in: ExpectedInboundIncompleteStates
				},
				type: {
					id: { equals: match.params.queueId }
				}
			})}
			divider={<Divider />}
			each={(expectedInbound: ExpectedInbound) => (
				<ListItem
					button
					key={expectedInbound.id}
					onClick={() => browserHistory.push(`/expected-inbounds/${expectedInbound.id}`)}>
					<ListItemText>
						<Grid container>
							<Grid item xs={6}>
								<div>
									<small>{expectedInbound.documentNumber}</small>
								</div>
								<div>
									<b>{expectedInbound.customer.name}</b>
								</div>
							</Grid>
							<Grid item xs={6}>
								<Box display="flex" justifyContent="flex-end">
									<ExpectedInboundStateInline state={expectedInbound.state} />
								</Box>
							</Grid>
						</Grid>
						<Table>
							<TableBody>
								{expectedInbound.items
									.map(item => (
										<TableRow>
											<TableCell style={{
												...tableCellStyle
											}}>{item.remainingQuantity}x</TableCell>
											<TableCell style={{
												...tableCellStyle
											}}>{item.sku.masterCode}</TableCell>
											<TableCell style={{
												...tableCellStyle
											}}>{item.palette.number}</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</ListItemText>
				</ListItem>
			)}
		/>
	</List>;
}

export default withRouteTitle(InboundQueueDetailPage, 'Očekávané příjmy');
