import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';

const ConfirmDialog: React.FC<{
	onCancel: () => void;
	onConfirm: () => void;
	message?: string;
	confirmText?: string;
}> = ({ onCancel, onConfirm, message, confirmText }) => {
	return <Dialog open={true as any}
		fullWidth={true}
		maxWidth="sm"
		onClose={onCancel}>
		<DialogTitle>{"Potvrzení akce"}</DialogTitle>
		<DialogContent>{message}</DialogContent>
		<DialogActions>
			<Button variant="text" onClick={onCancel}>Zrušit</Button>
			<Button variant="contained" color="primary" onClick={onConfirm}>{confirmText || 'Potvrdit'}</Button>
		</DialogActions>
	</Dialog>
}

export default ConfirmDialog;
