import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import browserHistory from '../../browserHistory';
import { translate } from '../../providers/locale';
import { withRouteTitle } from '../../helpers/withRouteTitle';

const MoveMenuPage: React.FC<{

}> = ({ }) => {
	return <List component="nav">
		<ListItem
			button
			onClick={() => browserHistory.push(`/move/palettes`)}>
			<ListItemText primary="Přesun LP"></ListItemText>
		</ListItem>
	</List>;
}

export default withRouteTitle(MoveMenuPage, 'Přesun');
