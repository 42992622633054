import { createMuiTheme } from '@material-ui/core';
import { TEST_ENVIRONMENT } from './providers/app';

export const getTheme = (type: 'light' | 'dark' = 'light') => createMuiTheme({
	palette: {
		type: type,
		primary: TEST_ENVIRONMENT ? {
			main: '#000'
		} : undefined
	},
	props: {
		MuiButton: {
			size: 'small',
		},
		MuiFilledInput: {
			margin: 'dense',
		},
		MuiFormControl: {
			margin: 'dense',
		},
		MuiFormHelperText: {
			margin: 'dense',
		},
		MuiIconButton: {
			size: 'small',
		},
		MuiInputBase: {
			margin: 'dense',
		},
		MuiGrid: {
			spacing: 4
		},
		MuiInputLabel: {
			margin: 'dense',
		},
		MuiListItem: {
			dense: true,
		},
		MuiOutlinedInput: {
			margin: 'dense',
		},
		MuiFab: {
			size: 'small',
		},
		MuiTable: {
			size: 'small',
		},
		MuiTextField: {
			margin: 'dense',
		},
		MuiToolbar: {
			variant: 'dense',
		}
	},
	overrides: {
		MuiIconButton: {
			sizeSmall: {
				marginLeft: 4,
				marginRight: 4,
				padding: 12,
			},
		},
	}
});
