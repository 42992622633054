import React from 'react';
import { ExpectedInbound } from '../../api/models';
import { localeFormatDate } from '../../providers/modal/locale';
import ExpectedInboundStateInline, { getExpectedInboundStateColor } from '../controls/ExpectedInboundStateInline';
import ExpectedInboundDetail from './ExpectedInboundDetail';
import ModelRecordList from './ModelRecordList';

type ThisModel = ExpectedInbound;
const ModelName = 'ExpectedInbound';

const ExpectedInboundListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/expected-inbounds"
		title="Přehled očekávaných příjmů"
		modelName={ModelName}
		columns={[
			{ label: 'Číslo dokladu', get: x => x.documentNumber, width: 100 },
			{ label: 'Stav', get: x => <ExpectedInboundStateInline state={x.state} />, backgroundColor: x => getExpectedInboundStateColor(x.state, 50), width: 100 },
			{ label: 'Typ pohybu', get: x => x.inventoryMove?.label, width: 100 },
			{ label: 'Požadavaný termín', get: x => localeFormatDate(x.requiredDate), width: 100 },
			{ label: 'Počet položek', get: x => x.items.length, width: 100 },
			{ label: 'Celkové množství', get: x => x.items.reduce((total, current) => total + current.expectedQuantity, 0), width: 100 },
			{ label: 'Ukladatel', get: x => x.customer?.name, width: 100 },
		]}
		sideDetailComponent={item => <ExpectedInboundDetail id={item.id} />}
	/>;
}

export default ExpectedInboundListPage;
