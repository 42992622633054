import React from 'react';
import { PaletteType, OutboundRequest, GetOutboundRequestsReturn } from '../../api/models';
import ModelRecordList from './ModelRecordList';
import { modelQueryUrl, httpPost, httpPostBlocking } from '../../api/http';
import { localeFormatDate, localeFormatDateTime } from '../../providers/modal/locale';
import DynamicButton from '../../controls/DynamicButton';
import SkuForm from './SkuForm';
import { openSnackbar } from '../../providers/modal';
import { emitRefreshModel } from '../../providers/app';

type ThisModel = GetOutboundRequestsReturn & { id: number };

const OutboundRequestListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint={modelQueryUrl(`/outbound/requests/active`, {
			expectedItemId: {
				empty: true
			}
		})}
		itemMap={item => {
			item.id = item.requestId;
			return item;
		}}
		buttons={[
			(<DynamicButton variant="contained" color="primary" label="Spustit" onClick={() => {
				httpPost(`/wms/outbound/requests/resolve`);
			}} />)
		]}
		title="Požadavky na výdej"
		modelName="XActiveOutboundRequest"
		multiActions={{
			label: x => `${x.skuName} ${x.paletteNumber}`,
			useLocalRecords: true,
			itemLabel: x => {
				console.log(x)
				return `${x.skuName} ${x.paletteNumber}`
			},
			// modelName: 'Deposit',
			actions: [
				{
					label: 'Výdej ihned', onClick: async ids => {
						let result = await httpPostBlocking(`/wms/outbound/requests/force`, {
							ids: ids
						});

						result && openSnackbar('success', 'Očekávaný výdej vytvořen');
						emitRefreshModel('XActiveOutboundRequest', null);
					}, batch: true
				}
			]
		}}
		columns={[
			{ label: 'ID položky', get: x => x.id, width: 100 },
			{ label: 'Vytvořeno', get: x => localeFormatDateTime(x.createdAt), width: 100 },
			{ label: 'SKU', get: x => x.skuName, width: 100 },
			{ label: 'Paleta', get: x => x.paletteNumber, width: 100, filterType: 'text', filterPath: 'paletteNumber', filterRaw: true },
			{ label: 'Požadovaný termín', get: x => localeFormatDate(x.requestedDate), width: 100 }
		]}
	/>;
}

export default OutboundRequestListPage;
