import { AppBar, Box, Button, createStyles, Grid, Icon, LinearProgress, ListItemIcon, makeStyles, Paper, Theme, ThemeProvider, Toolbar, Typography, IconButton, Divider } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import React, { useContext, useState } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { useObservable } from 'react-use-observable';
import browserHistory from '../browserHistory';
import { ApplicationContext } from '../contexts/application-context';
import { GlobalLoading$ } from '../providers/app';
import LoginPage from '../shared/LoginPage';
import NotFoundPage from '../shared/NotFoundPage';
import PrivatePage from '../shared/PrivatePageWrapper';
import { getTheme } from '../theme';
import ComponentMenu from './controls/ComponentMenu';
import DesktopNotificationIcon from './DesktopNotificationIcon';
import DesktopSearchIcon from './DesktopSearchIcon';
import DepositListPage from './pages/DepositListPage';
import SkuListPage from './pages/SkuListPage';
import ExpectedInboundListPage from './pages/ExpectedInboundListPage';
import ExpectedOutboundListPage from './pages/ExpectedOutboundListPage';
import OrderListPage from './pages/OrderListPage';
import CustomerListPage from './pages/CustomerPage';
import PaletteListPage from './pages/PaletteListPage';
import ProductListPage from './pages/ProductListPage';
import { queryUrl } from '../api/http';
import { DepositState } from '../api/models';
import TaskListPage from './pages/TaskListPage';
import InboundDocumentListPage from './pages/InboundDocumentListPage';
import SkuMovementListPage from './pages/SkuMovementListPage';
import PaletteTypeListPage from './pages/PaletteTypeListPage';
import OutboundDocumentListPage from './pages/OutboundDocumentListPage';
import DepositLabelsPage from './pages/DepositLabels';
import OutboundRuleListPage from './pages/OutboundRuleListPage';
import PaletteMovementListPage from './pages/PaletteMovementListPage';
import PaletteDeliveryPickupListPage from './pages/PaletteDeliveryPickupListPage';
import OutboundRequestListPage from './pages/OutboundRequestListPage';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		box: {
			flexGrow: 1
		},
		menuItemIcon: {
			minWidth: '36px'
		}
		// avatar: {
		// 	width: theme.spacing(4),
		// 	height: theme.spacing(4),
		// 	fontSize: theme.spacing(2)
		// }
	})
);

const Desktop: React.FC<{

}> = ({ }) => {
	let theme = getTheme();
	const [currentTheme, setCurrentTheme] = useState(theme);
	const classes = useStyles();
	const applicationContext = useContext(ApplicationContext);
	const [globalLoading] = useObservable(() => GlobalLoading$, [], false);

	const changeTheme = () => {
		let newTheme = getTheme('dark');
		setCurrentTheme(newTheme);
	};

	const logout = () => {
		localStorage.removeItem('jwt');
		browserHistory.push('/login');
	};

	return <ThemeProvider theme={currentTheme}>
		<Router history={browserHistory}>
			<Switch>
				<Route path="/login">
					<LoginPage />
				</Route>
				<Route path="/">
					<Paper elevation={0} style={{ borderRadius: 0, position: 'fixed', width: '100vw', height: '100vh' }}>
						{globalLoading && <LinearProgress style={{ position: 'fixed', width: '100%', top: 0, left: 0, zIndex: 1, height: '2px' }} color="secondary" />}
						<div style={{
							position: 'relative',
							// background: '#36469c',
							background: theme.palette.primary.dark,
							boxShadow: '0px 0px 0px',
							zIndex: 1200,
							fontSize: '12px',
							display: 'flex',
							color: '#fff',
							padding: '4px 28px'
						}}>
							<div style={{ flexGrow: 1 }}>SPOT</div>
							<ComponentMenu component={<div>{applicationContext.identity?.name}</div>}>
								<MenuItem onClick={() => logout()}>Odhlásit se</MenuItem>
							</ComponentMenu>
						</div>
						<AppBar position="static" style={{ position: 'relative' }}>
							<Toolbar variant="dense" style={{ minHeight: '58px' }}>
								<Box className={classes.box}>
									<Grid container>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Uskladněnky</Button>
											)}>
												<MenuItem onClick={() => browserHistory.push(queryUrl('/deposits', {
													state: { notIn: [DepositState.Canceled, DepositState.Completed, DepositState.Created, DepositState.Disposal] }
												}))}>Přehled aktivních</MenuItem>
												{/* <MenuItem onClick={() => browserHistory.push('/expected-outbounds')}>Doklady výdeje uskladněnek</MenuItem> */}
												<MenuItem onClick={() => browserHistory.push('/deposits')}>Všechny (i ukončené)</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/deposits/labels')}>
													<ListItemIcon classes={{ root: classes.menuItemIcon }}>
														<Icon fontSize="small">arrow_upward</Icon>
													</ListItemIcon>
													Tisk štítků
												</MenuItem>
											</ComponentMenu>
										</Grid>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Skladové karty</Button>
											)}>
												<MenuItem onClick={() => browserHistory.push('/skus')}>
													<ListItemIcon classes={{ root: classes.menuItemIcon }}>
														<Icon fontSize="small">menu</Icon>
													</ListItemIcon>
													Seznam skladových karet
												</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/skus/movements')}>
													<ListItemIcon classes={{ root: classes.menuItemIcon }}>
														<Icon fontSize="small">import_export</Icon>
													</ListItemIcon>
													Pohyby skladových karet
												</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/palettes/movements')}>
													<ListItemIcon classes={{ root: classes.menuItemIcon }}>
														<Icon fontSize="small">import_export</Icon>
													</ListItemIcon>
													Pohyby palet
												</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/palettes/delivery-pickups')}>
													<ListItemIcon classes={{ root: classes.menuItemIcon }}>
														<Icon fontSize="small">import_export</Icon>
													</ListItemIcon>
													Paletové konto
												</MenuItem>
												{/* <MenuItem onClick={() => browserHistory.push('/deposits')}>
													<ListItemIcon classes={{ root: classes.menuItemIcon }}>
														<Icon fontSize="small">arrow_upward</Icon>
													</ListItemIcon>
													Import karet
												</MenuItem> */}
											</ComponentMenu>
										</Grid>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Příjem</Button>
											)}>
												<MenuItem onClick={() => browserHistory.push('/expected-inbounds')}>Očekávané příjmy</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/deposits')}>Nový očekávaný příjem</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/inbound-documents')}>Příjmové doklady</MenuItem>
											</ComponentMenu>
										</Grid>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Výdej</Button>
											)}>
												<MenuItem onClick={() => browserHistory.push('/expected-outbounds')}>Očekávané výdeje</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/deposits')}>Nový očekávaný výdej</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/outbound-documents')}>Výdajové doklady</MenuItem>
											</ComponentMenu>
										</Grid>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Objednávky</Button>
											)}>
												<MenuItem onClick={() => browserHistory.push('/orders')}>Seznam objednávek</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/deposits')}>Nová objednávka</MenuItem>
											</ComponentMenu>
										</Grid>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Zákazníci</Button>
											)}>
												<MenuItem onClick={() => browserHistory.push('/customers')}>Seznam zákazníků</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/deposits')}>Nový zákazník</MenuItem>
											</ComponentMenu>
										</Grid>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Číselníky</Button>
											)}>
												<MenuItem onClick={() => browserHistory.push('/palettes')}>Palety</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/palettes/types')}>Typy palet</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/outbound-rules')}>Pravidla výdeje</MenuItem>
												{/* <MenuItem onClick={() => browserHistory.push('/inbound-rules')}>Pravidla příjmu</MenuItem> */}
												{/* <MenuItem onClick={() => browserHistory.push('/products')}>Zboží</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/products')}>Typy zboží</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/products')}>Typy poybů</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/products')}>Fronty příjmu</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/products')}>Fronty výdeje</MenuItem> */}
												<MenuItem onClick={() => browserHistory.push('/warehouse-locations')}>Lokace</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/deposits')}>Ceníky služeb</MenuItem>
											</ComponentMenu>
										</Grid>
										<Grid item>
											<ComponentMenu component={(
												<Button color="inherit">Systém</Button>
											)}>
												{/* <MenuItem onClick={() => browserHistory.push('/tasks')}>
													<ListItemIcon classes={{ root: classes.menuItemIcon }}>
														<Icon fontSize="small">timer</Icon>
													</ListItemIcon>
													Časované úlohy</MenuItem> */}
												<MenuItem onClick={() => browserHistory.push('/users')}>Uživatelé</MenuItem>
												<MenuItem onClick={() => browserHistory.push('/outbound-requests')}>Požadavky na výdej</MenuItem>
											</ComponentMenu>
										</Grid>
									</Grid>
								</Box>
								<DesktopSearchIcon />
								<DesktopNotificationIcon />
								{/* <IconButton onClick={() => changeTheme()}>
									<Icon>invert_colors</Icon>
								</IconButton> */}
								{/* <Avatar className={classes.avatar}>RK</Avatar> */}
							</Toolbar>
						</AppBar>
						<PrivatePage path="/deposits" component={DepositListPage} />
						<PrivatePage path="/deposits/labels" component={DepositLabelsPage} />

						<PrivatePage path="/skus" component={SkuListPage} />
						<PrivatePage path="/expected-inbounds" component={ExpectedInboundListPage} />
						<PrivatePage path="/expected-outbounds" component={ExpectedOutboundListPage} />
						<PrivatePage path="/inbound-documents" component={InboundDocumentListPage} />

						<PrivatePage path="/outbound-documents" component={OutboundDocumentListPage} />
						<PrivatePage path="/outbound-rules" component={OutboundRuleListPage} />

						<PrivatePage path="/skus/movements" component={SkuMovementListPage} />

						<PrivatePage path="/palettes/movements" component={PaletteMovementListPage} />
						<PrivatePage path="/palettes/delivery-pickups" component={PaletteDeliveryPickupListPage} />

						<PrivatePage path="/orders" component={OrderListPage} />
						<PrivatePage path="/customers" component={CustomerListPage} />
						<PrivatePage path="/palettes/types" component={PaletteTypeListPage} />
						<PrivatePage path="/palettes" component={PaletteListPage} />
						<PrivatePage path="/products" component={ProductListPage} />

						<PrivatePage path="/tasks" component={TaskListPage} />
						<PrivatePage path="/outbound-requests" component={OutboundRequestListPage} />
					</Paper>
				</Route>
				<Route component={NotFoundPage} />
			</Switch>
		</Router>
	</ThemeProvider>;
}

export default Desktop;
