import { BehaviorSubject, from, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export class ItemList<T = any, P = any> {
	private _payload: P;
	private _items = new BehaviorSubject<T[]>([]);
	private _itemsWhenReset = new ReplaySubject<T[]>(1);
	private _activeIndex = new BehaviorSubject<number>(0);
	private _reset$ = new Subject<void>();
	private _reseted = true;

	loading = new BehaviorSubject<boolean>(false);
	isSilentLoading = false;
	loaded = false;
	reached = false;
	empty = true;
	failed = false;

	reset$ = this._reset$.asObservable();
	items = this._items.asObservable();
	itemsWhenReset = this._itemsWhenReset.asObservable();
	activeIndex = this._activeIndex.asObservable();
	activeItem = this.activeIndex.pipe(map(index => ({
		index,
		item: this._items.value[index]
	})));

	get canMore() {
		return this.reached === false && this.loading.value === false;
	}

	get count() {
		return this._items.value.length;
	}

	constructor(private _callback: (offset: number, payload?: P) => Observable<T[]> | Promise<T[]>, private _options?: any) {
		this._options = {
			count: 20,
			...this._options
		};
	}

	getAllItems() {
		return this._items.value;
	}

	activateIndex(index: number = 0) {
		this._activeIndex.next(index);
	}

	next(offset: number = this._items.value.length, clearItemsAfterLoad = false) {
		if (this.reached || this.loading.value) {
			return false;
		}

		this.loading.next(true);
		this.failed = false;
		from(this._callback(offset, this._payload))
			.subscribe(items => {
				items = items || [];
				this.done(items);
				if (clearItemsAfterLoad) {
					this._items.next(items);
				} else {
					this._items.next([].concat(this._items.value).concat(items));
				}
			}, error => {
				this._items.next([]);
				this.failed = true;
				this.done([]);
			});
	}

	done(items: T[]) {
		if (this._reseted) {
			this._itemsWhenReset.next(items);
		}

		this._reseted = false;
		this.loaded = true;
		this.loading.next(false);
		this.empty = (items.length === 0) && this._items.value.length === 0;

		if (items == null || items.length < this._options.count && this.loaded === true) {
			this.reached = true;
		}
	}

	reset(payload?: P) {
		this._reseted = true;
		this._payload = payload;
		this.loaded = false;
		this.empty = true;
		this.reached = false;
		this._items.next([]);
		this.activateIndex(0);
		this._reset$.next();
	}

	refresh(payload?: P, silent = false) {
		this.isSilentLoading = silent;

		if (silent) {
			this._payload = payload;
			this.loaded = false;
			this.empty = true;
			this.reached = false;
			this.next(0, true);
		} else {
			this.reset(payload);
			this.next();
		}
	}

	updateItemAtIndex(index: number, item: T) {
		const items = [].concat(this._items.value);
		items[index] = item;
		this._items.next(items);
	}
}
