import React, { useState, useEffect, useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ComponentMenu from './ComponentMenu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Chip from '@material-ui/core/Chip';
import { httpGet } from '../../api/http';

const ListHeaderCheckbox: React.FC<{
	selectedIds: [number[], ((value: number[]) => void)];
	endpoint: string;
	loadedItems: any[];
	filter?: any;
}> = ({
	selectedIds: [selectedIds, setSelectedIds],
	endpoint,
	loadedItems,
	filter
}) => {
		const [totalCount, setTotalCount] = useState(null);

		useEffect(() => {
			httpGet<number>(`${endpoint}${''}/count`, filter)
				.then(setTotalCount);
		}, [filter]);

		const cancelSelection = () => {
			setSelectedIds([]);
		};

		const selectAllLoaded = () => {
			// TODO: id
			setSelectedIds(loadedItems.map(x => x.id));
		};

		const selectAllFiltered = () => {
			httpGet<any[]>(endpoint, {
				...filter,
				$select: ['id'],
				$count: -1
			})
				.then(result => {
					let ids = result.map(x => x.id);
					setSelectedIds(ids);
				});
		};

		return (
			<ComponentMenu component={(
				<Checkbox style={{ padding: '0px' }} checked={false} />
			)}>
				{selectedIds.length > 0 && <MenuItem onClick={() => cancelSelection()}>Zrušit výběr</MenuItem>}
				<MenuItem onClick={() => selectAllLoaded()}>
					<div style={{ flexGrow: 1, marginRight: '30px' }}>Označit viditelné</div>
					<Chip variant="outlined" size="small" label={loadedItems?.length} />
				</MenuItem>
				<MenuItem onClick={() => selectAllFiltered()}>
					<div style={{ flexGrow: 1, marginRight: '30px' }}>Označit vše filtrované</div>
					<Chip variant="outlined" size="small" label={totalCount} />
				</MenuItem>
			</ComponentMenu>

		);
	}

export default ListHeaderCheckbox;
