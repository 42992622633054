import { Component, Children } from 'react';

class ContentSwitch extends Component<{ condition: any }> {
	getChildren() {
		let cases: any[] = [],
			defaults: any[] = [];

		Children.forEach(this.props.children, (item: any) => {
			if (item) {
				switch (item.type.componentName) {
					case 'case':
						if (typeof this.props.condition === 'function') {
							if (this.props.condition(item.props.value)) {
								cases.push(item);
							}
						}
						else if (this.props.condition === item.props.value) {
							cases.push(item);
						}
						break;
					case 'default':
						defaults.push(item);
						break;
				}
			}
		});

		if (cases.length > 0) {
			return cases;
		}
		return defaults;
	}

	render() {
		const children = this.getChildren();
		return children.length === 0 ? null : children;
	}
}

class ContentSwitchCase extends Component<{ value: any, content?(): React.ReactNode }> {
	static componentName = 'case';

	render() {
		if (this.props.content) {
			return this.props.content();
		} else {
			return this.props.children;
		}
	}
}

class ContentSwitchDefault extends Component {
	static componentName = 'default';

	render() {
		return this.props.children;
	}
}

export default ContentSwitch;
export { ContentSwitchCase };
export { ContentSwitchDefault };
