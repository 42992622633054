import { Box, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { modelQueryUrl } from '../../api/http';
import { InboundQueueType } from '../../api/models';
import browserHistory from '../../browserHistory';
import RemoteChip from '../../shared/RemoteChip';
import RemoteItems from '../../shared/RemoteItems';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import { ExpectedInboundIncompleteStates } from '../../services/expectedInboundService';
import { ExpectedOutboundIncompleteStates } from '../../services/expectedOutboundService';

const OutboundQueuesPage: React.FC<{

}> = ({ }) => {
	return <List component="nav">
		<RemoteItems
			endpoint="/outbound-queue-types"
			divider={<Divider />}
			each={(item: InboundQueueType) => (
				<ListItem button key={item.id} onClick={() => browserHistory.push(`/outbound-queues/${item.id}`)}>
					<Box p={1}>
						<ListItemText>{item.name}</ListItemText>
						<ListItemSecondaryAction>
							<RemoteChip endpoint={modelQueryUrl('/expected-outbounds/count', {
								state: {
									in: ExpectedOutboundIncompleteStates
								},
								type: {
									id: { equals: item.id }
								}
							})} />
						</ListItemSecondaryAction>
					</Box>
				</ListItem>
			)}
		/>
	</List>;
}

export default withRouteTitle(OutboundQueuesPage, 'Fronty výdeje');
