import React, { useEffect, useState } from 'react';
import { ApplicationContext, IApplicationContext } from './contexts/application-context';
import { getIdentity, Identity$, refreshIdentityFromToken } from './providers/auth';
import Terminal from './terminal/Terminal';
import Desktop from './desktop/Desktop';
import { TERMINAL, TEST_ENVIRONMENT } from './providers/app';

const TestEnvironmentNotification: React.FC<{

}> = ({ }) => {
	return TEST_ENVIRONMENT && (
		<div style={{
			width: '100%',
			backgroundColor: 'black',
			boxSizing: 'border-box',
			padding: '10px',
			fontWeight: 'bold',
			fontSize: '12px',
			textTransform: 'uppercase',
			textAlign: 'center',
			color: '#fff'
		}}>Testovací prostředí</div>
	);
}

const App: React.FC<{

}> = ({ }) => {
	const [applicationState, setApplicationState] = useState({
		identity: getIdentity(),
		locale: null
	} as IApplicationContext);

	useEffect(() => {
		Identity$
			.subscribe(identity => setApplicationState(state => ({
				...state,
				identity
			})));

		refreshIdentityFromToken();
	}, []);

	if (applicationState == null) {
		return <div>Nahrávám</div>;
	} else if (TERMINAL) {
		return (
			<React.Fragment>
				<TestEnvironmentNotification />
				<ApplicationContext.Provider value={applicationState}>
					<Terminal></Terminal>
				</ApplicationContext.Provider>
			</React.Fragment>
		)
	} else {
		return (
			<React.Fragment>
				<TestEnvironmentNotification />
				<ApplicationContext.Provider value={applicationState}>
					<Desktop></Desktop>
				</ApplicationContext.Provider>
			</React.Fragment>
		)
	}
}

export default App;
