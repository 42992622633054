import { createContext } from 'react';
import { Subject } from 'rxjs';

export interface IApplicationContext {
	identity: any;
	locale: any;
}

export const ApplicationContext = createContext<IApplicationContext>({
	identity: null,
	locale: null
});

export const ApplicationContextChange$ = new Subject<IApplicationContext>();

export function setApplicationContext(applicationContext: IApplicationContext) {
	ApplicationContextChange$.next(applicationContext);
}
