import React from 'react';
import { Order, Item } from '../../api/models';
import { translate } from '../../providers/locale';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordList from './ModelRecordList';
import ModelRecordSideDetail from './ModelRecordSideDetail';

type ThisModel = Item;

const ProductListPage: React.FC<{}> = ({ }) => {
	return <ModelRecordList<ThisModel>
		endpoint="/items"
		title="Zboží"
		columns={[
			{ label: 'Název', get: x => x.name, width: 100 },
			{ label: 'Typ', get: x => x.type.name, width: 100 }
		]}
		sideDetailComponent={item => <ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					summaryList: [
						{ label: 'Název', get: x => x.name },
						{ label: 'Typ', get: x => x.type.name }
					]
				}, {
					label: 'Události',
					content: item => <ModelActivityTable modelName="Item" modelId={item.id} />
				}
			]} />}
	/>;
}

export default ProductListPage;
