import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React, { useEffect } from 'react';
import { useAsync } from 'react-async-hook';
import { match } from 'react-router-dom';
import { httpGet, httpPost, modelQueryUrl } from '../../api/http';
import { Activity, ExpectedOutbound } from '../../api/models';
import { setApplicationTitle } from '../../providers/app';
import { translate } from '../../providers/locale';
import { localeFormatDate, localeFormatDateTime } from '../../providers/modal/locale';
import { isExpectedOutboundFinished } from '../../services/expectedOutboundService';
import Preloader from '../../shared/Preloader';
import RemoteItems from '../../shared/RemoteItems';
import { SummaryList, SummaryListItem } from '../../shared/SummaryList';
import { SwitchBottomNavigation, SwitchBottomNavigationCase } from '../../shared/SwitchBottomNavigation';
import browserHistory from '../../browserHistory';

const ExpectedOutboundDetailPage: React.FC<{ match: match<any> }> = ({ match }) => {
	const { result: expectedOutbound } = useAsync(() => httpGet<ExpectedOutbound>(`/expected-outbounds/${match.params.id}`), []);

	useEffect(() => {
		setApplicationTitle(expectedOutbound?.documentNumber);
	}, [expectedOutbound]);

	const handleStart = async () => {
		let result = await httpPost(`/wt/expected-outbounds/${expectedOutbound.id}/start`);

		result && browserHistory.push(result.navigateTo);
	};

	return (
		<Preloader loading={expectedOutbound == null} content={() => (
			<SwitchBottomNavigation>
				<SwitchBottomNavigationCase label="Detail" icon="assignment">
					<Box p={1}>
						<SummaryList>
							<SummaryListItem label="Číslo dokladu">{expectedOutbound.documentNumber}</SummaryListItem>
							<SummaryListItem label="Stav">{translate(expectedOutbound.state)}</SummaryListItem>
							<SummaryListItem label="Termín dodání">{localeFormatDate(expectedOutbound.requiredDate)}</SummaryListItem>
						</SummaryList>
						<Box mt={2}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>SKU</TableCell>
										<TableCell>Paleta</TableCell>
										{/* <TableCell>Hotovo</TableCell> */}
										<TableCell>Zbývá</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{expectedOutbound.items
										.map(item => (
											<TableRow key={item.id}>
												<TableCell>{item.sku.name}</TableCell>
												<TableCell>{item.palette?.number}</TableCell>
												{/* <TableCell>{item.completeQuantity}</TableCell> */}
												<TableCell>{item.remainingQuantity}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</Box>
						{
							isExpectedOutboundFinished(expectedOutbound) == false && (
								<Button variant="contained" color="primary" fullWidth={true} onClick={handleStart}>Zpracovat doklad</Button>
							)
						}
					</Box>
				</SwitchBottomNavigationCase>

				<SwitchBottomNavigationCase label="Události" icon="list">
					<List>
						<RemoteItems
							endpoint={modelQueryUrl<Activity>('/activities', {
								modelName: { equals: 'ExpectedOutbound' },
								modelId: { equals: expectedOutbound.id }
							})}
							each={(item: Activity) => (
								<ListItem key={item.id}>
									<ListItemText primary={(
										<div>{localeFormatDateTime(item.createdAt)} | {item.identity.name}</div>
									)} secondary={item.message} />
								</ListItem>
							)} />
					</List>
				</SwitchBottomNavigationCase>
			</SwitchBottomNavigation>
		)}>
		</Preloader>
	);
}

export default ExpectedOutboundDetailPage;
