import { Button, DialogContent, DialogActions } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useEffect, useState, useContext } from 'react';
import { httpGet, httpPost, modelQueryUrl } from '../../api/http';
import { TextBox } from '../../controls/base';
import { DataState, DataStateContext } from '../../helpers/dataState';
import DateTime from '../../controls/DateTime';
import { CustomerBranchOffice, Deposit, Palette, PaletteState, PaletteLocation } from '../../api/models';
import { Select } from '../../controls/Select';
import { RefreshModel$ } from '../../providers/app';
import { openSnackbar, ModalContext } from '../../providers/modal';
import { useObservable } from 'react-use-observable';
import { translate } from '../../providers/locale';

type ThisModel = Palette;
const ModelName = 'Palette';

const PaletteForm: React.FC<{
	id: number
}> = ({ id }) => {
	const modal = useContext(ModalContext);

	const [dataState, setDataState] = useState(new DataState<Palette>({

	}));
	const [dataStateRecord] = useObservable(() => dataState.recordChange, [dataState]);

	useEffect(() => {
		if (id) {
			httpGet(`/palettes/${id}`)
				.then(data => {
					dataState.setRecord(data);
				}, error => {
					// TODO
				});
		}
	}, [id]);

	const handleSubmit = async () => {
		let result = await httpPost(`/palettes/${id}`, dataState.record, {
			targetDataState: dataState
		});

		if (result) {
			RefreshModel$.next({ name: ModelName, id: result.id });
			openSnackbar('success', 'Paleta uložena');
			modal().close();
		}
	};

	return dataStateRecord && (
		<DataStateContext.Provider value={dataState}>
			<DialogContent>
				<Grid container>
					<Grid item sm={4}>
						<Select<string>
							label="Stav"
							dataState={dataState}
							path="state"
							items={Object.keys(PaletteState)}
							getOptionLabel={val => translate(val)} />
					</Grid>
					<Grid item sm={4}>
						<Select<string>
							label="Umístění"
							dataState={dataState}
							path="location"
							items={Object.keys(PaletteLocation)}
							getOptionLabel={val => translate(val)} />
					</Grid>
					<Grid item sm={4}>
						{
							dataStateRecord.location == PaletteLocation.Dealer && (
								<Select<CustomerBranchOffice>
									label="Pobočka"
									path="customerBranchOffice"
									endpoint={term => modelQueryUrl(`/customers/branch-offices`, {
										number: { contains: term }
									})}
									getOptionLabel={x => x.name}></Select>
							)
						}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="contained" onClick={() => handleSubmit()}>Potvrdit</Button>
			</DialogActions>
		</DataStateContext.Provider>
	)
}

export default PaletteForm;
