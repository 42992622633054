import { Box, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { match, useLocation } from 'react-router-dom';
import { modelQueryUrl, httpPostBlocking } from '../../api/http';
import { Deposit, DepositState } from '../../api/models';
import { getLocationQuery } from '../../browserHistory';
import { withRouteTitle } from '../../helpers/withRouteTitle';
import { PrimaryButton } from '../../shared/presets';
import RemoteItems from '../../shared/RemoteItems';
import browserHistory from '../../browserHistory';

const DepositsInboundConfirmationPage: React.FC<{
	match: match<{ officeId: string }>
}> = ({ match }) => {
	const location = useLocation();
	const query = getLocationQuery<{ selectedDepositIds: number[] }>(location);

	const confirm = async () => {
		let result = await httpPostBlocking('/deposit-terminal-controller/confirm/inbound', {
			selectedDepositIds: query.selectedDepositIds,
			depositStates: [DepositState.InboundProgress]
		});

		result && browserHistory.push(result.navigateTo);
	};

	return (
		<Box px={2}>
			<List component="nav">
				<RemoteItems
					endpoint={modelQueryUrl('/deposit-terminal-controller/resolve-missing-deposits', {
						officeId: match.params.officeId,
						depositStates: [DepositState.InboundProgress],
						selectedDepositIds: query.selectedDepositIds
					})}
					noItemsText="Všechny položky byly vybrány"
					divider={<Divider />}
					each={(item: Deposit) => (
						<ListItem key={item.id}>
							<ListItemText primary={item.palette.number} secondary={item.documentNumber} />
						</ListItem>
					)}
				/>
				<PrimaryButton onClick={confirm}>Potvrdit</PrimaryButton>
			</List >
		</Box>
	);
}

export default withRouteTitle(DepositsInboundConfirmationPage, 'Vykládka uskladněnek');
