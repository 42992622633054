import browserHistory from '../../browserHistory';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import RemoteItems from '../../shared/RemoteItems';
import { Box, Divider, Typography } from '@material-ui/core';
import { DepositState } from '../../api/models';
import { modelQueryUrl } from '../../api/http';
import { translate } from '../../providers/locale';
import { withRouteTitle } from '../../helpers/withRouteTitle';

const DepositsOutboundPage: React.FC<{

}> = ({ }) => {
	return <List component="nav">
		<RemoteItems
			endpoint={modelQueryUrl('/deposits/dealer-deposit-group', {
				states: [DepositState.InboundProgress]
			})}
			divider={<Divider />}
			each={(item: any) => ( // TODO: Type
				<ListItem button key={item.id} onClick={() => browserHistory.push(`/deposits/inbound/${item.id}`)}>
					<Box p={1}>
						<ListItemText primary={item.name} secondary={item.address} />
						{item.states.map((stateItem: any) => ( // TODO: Type
							<Typography key={stateItem.state}>{translate(stateItem.state, {
								[DepositState.InboundProgress]: 'Vyložit'
							})}: {stateItem.count}x</Typography>
						))}
					</Box>
				</ListItem>
			)}
		/>
	</List>;
}

export default withRouteTitle(DepositsOutboundPage, 'Vykládka uskladněnek');
