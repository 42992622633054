import { Button, DialogActions, DialogContent, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useContext, useEffect, useState } from 'react';
import { useObservable } from 'react-use-observable';
import { httpGet, httpPost, modelQueryUrl } from '../../api/http';
import { CustomerBranchOffice, PaletteDeliveryPickup, PaletteLocation } from '../../api/models';
import { Select } from '../../controls/Select';
import { DataState, DataStateContext } from '../../helpers/dataState';
import { RefreshModel$ } from '../../providers/app';
import { translate } from '../../providers/locale';
import { ModalContext, openSnackbar } from '../../providers/modal';

type ThisModel = PaletteDeliveryPickup;
const ModelName = 'PaletteDeliveryPickup';

const PaletteDeliveryPickupForm: React.FC<{
	id?: number,
	paletteIds: number[]
}> = ({ id, paletteIds }) => {
	const modal = useContext(ModalContext);

	const [dataState, setDataState] = useState(new DataState<ThisModel>({
		paletteLocationDirection: PaletteLocation.Dealer,
		palettes: []
	}));
	const [dataStateRecord] = useObservable(() => dataState.recordChange, [dataState]);

	useEffect(() => {
		if (id) {
			httpGet(`/palettes/delivery-pickups/${id}`)
				.then(data => {
					dataState.setRecord(data);
				}, error => {
					// TODO
				});
		}
	}, [id]);

	useEffect(() => {
		if (paletteIds) {
			httpGet(modelQueryUrl(`/palettes`, {
				id: {
					in: paletteIds
				}
			})).then(palettes => dataState.set('palettes', palettes));
		}
	}, [paletteIds]);

	const handleSubmit = async () => {
		let result = await httpPost(`/palettes/delivery-pickups${id ? `/${id}` : ''}`, dataState.record, {
			targetDataState: dataState
		});

		if (result) {
			RefreshModel$.next({ name: ModelName, id: result.id });
			openSnackbar('success', 'Změna umístění palet vytvořena');
			modal().close();
		}
	};

	return dataStateRecord && (
		<DataStateContext.Provider value={dataState}>
			<DialogContent>

				<Grid container>
					<Grid item sm={6}>
						<Select<PaletteLocation>
							label="Cílove umístění"
							path="paletteLocationDirection"
							dataState={dataState}
							items={[PaletteLocation.Dealer, PaletteLocation.Warehouse]}
							getOptionLabel={x => translate(x)} />
					</Grid>
					<Grid item sm={6}>
						{
							dataStateRecord.paletteLocationDirection == PaletteLocation.Dealer && (
								<Select<CustomerBranchOffice>
									label="Pobočka"
									path="customerBranchOffice"
									endpoint={term => modelQueryUrl(`/customers/branch-offices`, {
										number: { contains: term }
									})}
									getOptionLabel={x => x.name}></Select>
							)
						}
					</Grid>
				</Grid>
				<Grid container>
					<Grid item sm={12}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Paleta</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{
									dataStateRecord.palettes.map(palette => (
										<TableRow key={palette.id}>
											<TableCell>{palette.number}</TableCell>
										</TableRow>
									))
								}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button color="primary" variant="contained" onClick={() => handleSubmit()}>Potvrdit</Button>
			</DialogActions>
		</DataStateContext.Provider>
	)
}

export default PaletteDeliveryPickupForm;
