import React, { useState, useEffect, useContext } from 'react';
import Table from '@material-ui/core/Table';
import { TableBody, TableRow, TableCell, TableCellProps } from '@material-ui/core';

export const SummaryList: React.FC<{

}> = ({ children }) => {
	return <Table>
		<TableBody>
			{children}
		</TableBody>
	</Table>
}

export const SummaryListItem: React.FC<{
	label: string;
	TableCellProps?: TableCellProps;
}> = ({ label, children, TableCellProps }) => {
	return <TableRow hover>
		<TableCell style={{ width: '50% '}}>{label}</TableCell>
		<TableCell style={{ width: '50% '}} {...TableCellProps}>{children}</TableCell>
	</TableRow>
}

