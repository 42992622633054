import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { httpGet, modelQueryUrl } from '../../api/http';
import { Sku, SkuLocation, SkuMovement } from '../../api/models';
import { RefreshModel$ } from '../../providers/app';
import { translate } from '../../providers/locale';
import ModelActivityTable from '../controls/ModelActivityTable';
import ModelRecordSideDetail from './ModelRecordSideDetail';
import RemoteItems from '../../shared/RemoteItems';

type ThisModel = Sku;
const ModelName = 'Sku';

const SkuDetail: React.FC<{
	id: number
}> = ({ id }) => {
	const [item, setItem] = useState<ThisModel>(null);

	useEffect(() => {
		refresh();

		const sub = RefreshModel$
			.subscribe(() => refresh());

		return () => sub.unsubscribe();
	}, [id]);

	const refresh = () => {
		httpGet(`/skus/${id}`)
			.then(setItem);
	};

	return item && (
		<ModelRecordSideDetail<ThisModel>
			item={item}
			tabs={[
				{
					label: 'Informace',
					summaryList: [
						{ label: 'Kód', get: x => x.masterCode },
						{ label: 'Název', get: x => x.name },
						{ label: 'Produkt', get: x => x.product?.name },
						{ label: 'Množství', get: x => x.quantity },
						{ label: 'Disponabilní množství', get: x => x.quantity },
						{ label: 'Ukladatel', get: x => x.owner?.name },
					]
				},
				{
					label: 'Lokace',
					content: item => (
						<Table>
						<TableHead>
							<TableRow>
								<TableCell>Lokace</TableCell>
								<TableCell>Počet</TableCell>
								<TableCell>Paleta</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<RemoteItems
								endpoint={modelQueryUrl<SkuLocation>('/skus/locations', {
									sku: {
										id: { equals: item.id }
									}
								})}
								each={(item: SkuLocation) => (
									<TableRow key={item.id}>
										<TableCell>
											{item.warehouseLocation.code}
										</TableCell>
										<TableCell>{item.quantity}</TableCell>
										<TableCell>
											{item.palette?.number}
										</TableCell>
									</TableRow>
								)} />
						</TableBody>
					</Table>
					)
				},
				{
					label: 'Pohyby',
					content: item => (
						<Table>
						<TableHead>
							<TableRow>
								<TableCell>Množství</TableCell>
								<TableCell>Směr</TableCell>
								<TableCell>Typ pohybu</TableCell>
								<TableCell>Zdrojová lokace</TableCell>
								<TableCell>Cílová lokace</TableCell>
								<TableCell>Očekávaný doklad</TableCell>
								<TableCell>Doklad</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<RemoteItems
								endpoint={modelQueryUrl<SkuMovement>('/skus/movements', {
									sku: {
										id: { equals: item.id }
									}
								})}
								each={(item: SkuMovement) => (
									<TableRow key={item.id}>
										<TableCell>{item.quantity}</TableCell>
										<TableCell>{translate(item.skuDirectionType)}</TableCell>
										<TableCell>{item.inventoryMove?.label}</TableCell>
										<TableCell>{item.sourceWarehouseLocation?.name}</TableCell>
										<TableCell>{item.destinationWarehouseLocation?.name}</TableCell>
										<TableCell>{item.expectedDocumentNumber}</TableCell>
										<TableCell>{item.documentNumber}</TableCell>
									</TableRow>
								)} />
						</TableBody>
					</Table>
					)
				},
				{
					label: 'Události',
					content: item => <ModelActivityTable modelName="Sku" modelId={item.id} />
				}
			]}
		/>
	);
}

export default SkuDetail;
