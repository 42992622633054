import { createBrowserHistory, Location } from 'history';
import { ApplicationTitle$, PageMenu$ } from './providers/app';
import qs from 'qs';

const browserHistory = createBrowserHistory({});

browserHistory
	.listen(() => {
		ApplicationTitle$.next(null);
		PageMenu$.next(null);
	});

export function getLocationQuery<T = any>(location: Location) {
	let query = location.search;

	if (query.startsWith('?')) {
		query = query.substr(1);
	}

	return qs.parse(query, {
		arrayLimit: 9999
	}) as T;
}

export default browserHistory;
