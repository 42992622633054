import { Theme, ThemeProvider, Snackbar, Dialog, DialogContent, CircularProgress, LinearProgress } from '@material-ui/core';
import React, { createContext } from "react";
import ReactDOM from "react-dom";
import { Subject } from 'rxjs';
import SimpleDialog, { SimpleDialogProps } from "../desktop/controls/SimpleDialog";
import AlertDialog from "./modal/AlertDialog";
import ConfirmDialog from './modal/ConfirmDialog';
import Alert, { Color } from '@material-ui/lab/Alert';
import PromptDialog from './modal/PromptDialog';
import SuccessActionMenu from '../shared/SuccessActionMenu';

export const ModalContext = createContext(null as (() => IModalHandler<any>));

export interface IModalHandler<T> {
	component: T;
	close$: Subject<any>;
	close(result?: any): void;
};

export function openModal<T extends JSX.Element>(component: T, theme?: Theme) {
	let el = document.createElement('div');

	document.body.appendChild(el);
	ReactDOM.render((
		<ThemeProvider theme={theme}>
			<ModalContext.Provider value={() => handler}>
				{component}
			</ModalContext.Provider>
		</ThemeProvider>
	), el);

	let handler: IModalHandler<T> = {
		component,
		close$: new Subject<any>(),
		close: (result?: any) => {
			handler.close$.next(result);
			ReactDOM.unmountComponentAtNode(el);
		}
	};

	return handler;
}

export function openSnackbar(severity: Color, message: string) {
	const handle = openModal((
		<Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={() => handle.close()}>
			<Alert onClose={() => handle.close()} severity={severity}>{message}</Alert>
		</Snackbar>
	));

	return handle;
}

export function openAlert(message: string) {
	return new Promise<boolean>((resolve, reject) => {
		let ref = openModal(<AlertDialog message={message} onCancel={() => done()}></AlertDialog>);

		const done = () => {
			resolve();
			ref.close();
		};
	});
}

export function openPrompt(message: string, required?: boolean) {
	return new Promise<any>((resolve, reject) => {
		let ref = openModal(<PromptDialog message={message} required={required} onCancel={() => done()} onConfirm={x => done(x)}></PromptDialog>);

		const done = (value?: any) => {
			resolve(value);
			ref.close();
		};
	});
}

export function openConfirm(message: string, confirmText?: string) {
	return new Promise<boolean>((resolve, reject) => {
		let ref = openModal(<ConfirmDialog message={message} confirmText={confirmText} onCancel={() => done(false)} onConfirm={() => done(true)}></ConfirmDialog>);

		const done = (value: boolean) => {
			resolve(value);
			ref.close();
		};
	});
}

export function openDialog2(options: SimpleDialogProps, component: React.ReactElement, theme: Theme) {
	return openModal((
		<SimpleDialog {...options}>
			{component}
		</SimpleDialog>
	), theme)
}

export function openDialog(title: string, component: React.ReactElement, theme: Theme) {
	return openModal((
		<SimpleDialog title={title}>
			{component}
		</SimpleDialog>
	), theme)
}

export function openFullHeightDialog(title: string, component: React.ReactElement, theme: Theme) {
	return openModal((
		<SimpleDialog title={title} fullHeight>
			{component}
		</SimpleDialog>
	), theme)
}

export function openSuccessActionMenu(opts: any, theme: Theme) {
	return openModal((
		<Dialog open={true} fullScreen>
			<DialogContent>
				<SuccessActionMenu {...opts} />
			</DialogContent>
		</Dialog>
	), theme)
}

export function openOverlayLoading(theme: Theme) {
	const modal = openModal((
		<Dialog
		open={true}
		fullScreen
		disableBackdropClick={true}
		disableEscapeKeyDown={true}
		BackdropProps={{
			style: {
				backgroundColor: 'rgba(255, 255, 255, 0.5)'
			}
		}}
		PaperProps={{
			style: {
				backgroundColor: 'transparent'
			}
		}}>
			<LinearProgress style={{ height: '4px' }} color="secondary" />
		</Dialog>
	), theme)

	return () => modal.close();
}
