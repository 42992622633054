import React from 'react';
import browserHistory from '../../browserHistory';
import TerminalMenu from '../TerminalMenu';
import { withRouteTitle } from '../../helpers/withRouteTitle';

const TerminalHomePage: React.FC<{  }> = ({  }) => {
	const handleMenuItemClick = (url: string) => {
		browserHistory.push(url);
	};

	return (
		<TerminalMenu handleMenuItemClick={handleMenuItemClick} />
	)
}

export default withRouteTitle(TerminalHomePage, 'Úvod');
