import { createStyles, Icon, InputAdornment, makeStyles, TableCell, Theme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';
import { IKeyValueRecord } from '../../api/interfaces';
import { useDebounce } from '@react-hook/debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListHeaderCell from '../controls/ListHeaderCell';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		filterLabel: {
			color: 'rgba(0, 0, 0, 0.87)',
			fontWeight: 500,
			lineHeight: '1.5rem',
			fontSize: '0.875rem'
		}
	}),
);

export interface IModelRecordListColumn<T extends any> {
	label: string;
	width?: number;
	filterType?: 'text' | 'number' | 'select' | 'date' | 'enum';
	filterPath?: string;
	filterRaw?: boolean;
	filterOptions?: (term: string) => Promise<any[]>;
	get(item: T): React.ReactNode;
	backgroundColor?(item: T): any;
}

const ModelRecordListColumn: React.FC<{ column: IModelRecordListColumn<any>, filterValue: any, onFilterChange: (value: any) => void }> = ({ column, filterValue, onFilterChange }) => {
	const classes = useStyles();
	const [debouncedValue, setDebouncedValue] = useDebounce<any>(filterValue || '', 300);
	const [value, setValue] = useState<any>(filterValue || '');
	const [selectValue, setSelectValue] = useState<any>('');
	const [options, setOptions] = useState([]);

	let filterType = column.filterType; // || 'text';

	// TODO: Pokud dojde k resetu a setValue se nastavi na '', debounceValue zustava apokud nekdo potom zada stejnou hodnotu, nedojde k aplikovani filtru

	useEffect(() => {
		if (filterType == 'select') {
			// TODO
			console.log('here', filterValue)
			setValue(null);
		} else if (filterType == 'enum') {
			console.log('here', filterValue)
			setValue(null);
		} else {
			setValue(filterValue);
		}
	}, [filterValue]);

	useEffect(() => {
		if (filterType == 'select') {
			column.filterOptions(debouncedValue)
				.then(options => setOptions(options));
		} else {
			onFilterChange(debouncedValue)
		}
	}, [debouncedValue]);

	useEffect(() => {
		if (filterType == 'enum') {
			column.filterOptions(null).then(setOptions);
		}
	}, [column]);

	useEffect(() => {
		setValue(selectValue);
		onFilterChange(selectValue?.id)
	}, [selectValue]);

	let content: React.ReactElement;

	if (filterType == 'text' || filterType == 'number') {
		content = (
			<TextField value={value} fullWidth={true} variant="standard" onChange={(event) => {
				setValue(event.target.value);
				setDebouncedValue(event.target.value);
			}} label={column.label}
				InputLabelProps={{
					classes: {
						marginDense: '',
						root: classes.filterLabel
					}
				}}
				InputProps={{
					endAdornment: value && (
						<InputAdornment position="end" onClick={() => {
							setValue('');
							setDebouncedValue('');
						}}>
							<Icon style={{ fontSize: 14 }}>backspace</Icon>
						</InputAdornment>
					)
				}} />
		);
	} else if (filterType == 'select' || filterType == 'enum') {
		content = (
			<Autocomplete
				size="small"
				options={options}
				getOptionLabel={(option) => {
					return option.name;
				}}
				value={value}
				onInputChange={(event: any, value: string) => {
					if (filterType == 'select') {
						setDebouncedValue(value)
					} else {

					}
				}}
				onChange={(event: any, value: any) => setSelectValue(value)}
				renderInput={(params) => (
					<TextField {...params} variant="standard" label={column.label} InputLabelProps={{
						classes: {
							marginDense: '',
							root: classes.filterLabel
						}
					}} />
				)}
			/>
		);
	} else {
		content = (
			<ListHeaderCell label={column.label}></ListHeaderCell>
		);
	}

	return (
		<TableCell key={column.label} style={{ width: column.width, paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}>
			{content}
		</TableCell>
	)
}

export default ModelRecordListColumn;
