import React, { useState, useEffect, useContext } from 'react';
import { DepositState, ExpectedInboundState } from '../../api/models';
import { translate } from '../../providers/locale';
import { green, purple, blue, yellow, grey } from '@material-ui/core/colors';
import { Color } from '@material-ui/lab/Alert';

export function getExpectedInboundStateColor(state: ExpectedInboundState, contrast = 900) {
	if (state == ExpectedInboundState.Completed) {
		return (green as any)[contrast];
	} else if (state == ExpectedInboundState.Created) {
		return (purple as any)[contrast]
	} else if (state == ExpectedInboundState.InProgress) {
		return (yellow as any)[contrast]
	} else if (state == ExpectedInboundState.Canceled) {
		return (grey as any)[contrast]
	}
}

const ExpectedInboundStateInline: React.FC<{
	state: ExpectedInboundState
}> = ({ state }) => {
	let color = getExpectedInboundStateColor(state);
	return <span style={{ color }}>{translate(state)}</span>;
}

export default ExpectedInboundStateInline;
